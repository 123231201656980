import { FontIcon, IStackStyles, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import React, { useContext } from "react";
import { IAXMenuStructureData } from "../Models/IDataFromAx";

import { getCurrentVariance, GuestCountType, isValid } from "../Models/IDayPlanData";
import { DataContext, IDataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import {
  headerSeparatorGrey,
  lightestGrey,
  mediumGrey,
  monthDayLabelStyle,
  orangeGrey,
  pureBlack,
  pureWhite,
  superTranslucidOrange,
  weekViewColumn,
} from "../StylingUtils";
import { MonthDayCost } from "./MonthDayCost";
import { MonthGuestCount } from "./MonthGuestCount";
import { MonthVariance } from "./MonthVariance";
import { MonthDayMargin } from "./MonthDayMargin";
import { MonthDayTransactionValue } from "./MonthDayTransactionValue";

const myStyle = (inCurrentMonth: boolean, forbiddenDayWithData: boolean, somethingIsWrong?: boolean) =>
  ({
    root: [
      {
        displayName: "MonthViewWeekRowDay",
        borderBottom: `1px solid ${headerSeparatorGrey}`,
        minHeight: 61,
        fontSize: 16,
      },

      inCurrentMonth
        ? forbiddenDayWithData || somethingIsWrong
          ? { backgroundColor: superTranslucidOrange, color: pureBlack }
          : { backgroundColor: pureWhite, color: pureBlack }
        : forbiddenDayWithData || somethingIsWrong
        ? { backgroundColor: orangeGrey, color: mediumGrey, fontWeight: "normal" }
        : { backgroundColor: lightestGrey, color: mediumGrey, fontWeight: "normal" },
    ],
  } as IStackStyles);

const dataStackStyle: IStackStyles = {
  root: {
    displayName: "DataStack",
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    // should display an horizontal scrollbar if content is too wide
    overflow: "auto",
  },
};

const dataStackColumn: IStackStyles = {
  root: {
    displayName: "DataStackColumn",
    paddingBottom: 10,
  },
};

interface IMonthDayData {
  isConfirmed: boolean;
  guestCount: number;
  hasNoGuestCountVar: boolean;
  guestCountVar: number;
  totalPlannedCost: number;
  totalTargetCost: number;
  avgPlannedCost: number;
  avgTargetCost: number;
  avgCostVar: number;
  currency: string;
  smthgWrong: boolean;
  // retail indicators
  plannedMargin: number;
  plannedAvgTransactionValue: number;
}

const computeDayData = (
  dataContextValue: IDataContext | null,
  date: Date,
  selectedStructure: IAXMenuStructureData | null
) => {
  if (dataContextValue && selectedStructure) {
    const menuStructureData = dataContextValue.getMenuStructureData(date, selectedStructure.menu_structure);

    if (isValid(menuStructureData)) {
      const currentDayMonthData: IMonthDayData = {
        isConfirmed: false,
        guestCount: 0,
        guestCountVar: 0,
        hasNoGuestCountVar: true,
        totalPlannedCost: 0,
        totalTargetCost: 0,
        avgPlannedCost: 0,
        avgTargetCost: 0,
        avgCostVar: 0,
        currency: "",
        smthgWrong: false,
        plannedMargin: 0,
        plannedAvgTransactionValue: 0,
      };

      currentDayMonthData.isConfirmed = Boolean(menuStructureData!.is_confirmed);

      currentDayMonthData.guestCount = menuStructureData!.guest_count_number;

      currentDayMonthData.guestCountVar = getCurrentVariance(menuStructureData!);
      currentDayMonthData.hasNoGuestCountVar = menuStructureData!.guest_count_type === GuestCountType.Estimated;

      if (selectedStructure.is_retail) {
        currentDayMonthData.plannedMargin = menuStructureData!.menu_planned_margin;
        currentDayMonthData.plannedAvgTransactionValue =
          menuStructureData!.menu_planned_guest_count > 0
            ? menuStructureData!.menu_planned_sales / menuStructureData!.menu_planned_guest_count
            : 0;
      } else {
        currentDayMonthData.totalPlannedCost = menuStructureData!.menu_planned_cost;
        currentDayMonthData.totalTargetCost = menuStructureData!.menu_target_cost;
        currentDayMonthData.currency = menuStructureData!.planned_cost_currency;

        if (currentDayMonthData.guestCount !== 0) {
          currentDayMonthData.avgPlannedCost = currentDayMonthData.totalPlannedCost / currentDayMonthData.guestCount;
          currentDayMonthData.avgTargetCost = currentDayMonthData.totalTargetCost / currentDayMonthData.guestCount;
        }

        if (currentDayMonthData.avgTargetCost !== 0) {
          currentDayMonthData.avgCostVar =
            (100 * (currentDayMonthData.avgPlannedCost - currentDayMonthData.avgTargetCost)) /
            currentDayMonthData.avgTargetCost;
        }

        currentDayMonthData.smthgWrong =
          currentDayMonthData.totalPlannedCost > currentDayMonthData.totalTargetCost ||
          currentDayMonthData.avgPlannedCost > currentDayMonthData.avgTargetCost;
      }

      return currentDayMonthData;
    }
  }

  return null;
};

interface IMonthViewWeekRowDay {
  currentMonth: Date;
  date: Date;
  selectedStructure: IAXMenuStructureData | null;
  inPeriod: boolean;
  isDayAllowed: boolean;
  handleRedirectToDailyView: (d: Date) => void;
}

export const MonthViewWeekRowDay: React.FC<IMonthViewWeekRowDay> = (props) => {
  const dataContextValue = useContext(DataContext);

  const dayMonthData = computeDayData(dataContextValue, props.date, props.selectedStructure);
  const inCurrentMonth = props.date.getUTCMonth() === props.currentMonth.getUTCMonth();

  return (
    <Stack
      tokens={{ childrenGap: 5, padding: 5 }}
      styles={mergeStyleSets(
        weekViewColumn,
        myStyle(
          inCurrentMonth,
          props.inPeriod && !props.isDayAllowed && Boolean(dayMonthData),
          dayMonthData?.smthgWrong
        )
      )}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <span style={inCurrentMonth ? { fontWeight: "bold" } : {}}>
          {props.date.getUTCDate().toString().padStart(2, "0")}
        </span>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 3 }}>
          {props.inPeriod && !props.isDayAllowed && (
            <TooltipHost content={DynLabel("DayForbiddenBySetup")}>
              <FontIcon iconName="DRM" className="CornerMonthIcon DefaultCursor" />
            </TooltipHost>
          )}
          {dayMonthData && dayMonthData.isConfirmed && (
            <TooltipHost content={DynLabel("MenuConfirmedTooltip")}>
              <FontIcon iconName="Product" className="CornerMonthIcon DefaultCursor" />
            </TooltipHost>
          )}
          {props.inPeriod ? (
            <TooltipHost content={DynLabel("GoToDailyView")}>
              <FontIcon
                iconName="Event"
                className="CornerMonthIcon PointerCursor"
                onClick={() => props.handleRedirectToDailyView(props.date)}
              />
            </TooltipHost>
          ) : (
            <TooltipHost content={DynLabel("NotInPeriodTooltip")}>
              <FontIcon iconName="RemoveEvent" className="CornerMonthIcon LighterMonthIcon DefaultCursor" />
            </TooltipHost>
          )}
        </Stack>
      </Stack>
      {props.inPeriod && dayMonthData && (
        <Stack horizontal tokens={{ childrenGap: 20 }} styles={dataStackStyle}>
          {/** First column: guest count, planned costs, daily cost variance */}
          <Stack tokens={{ childrenGap: 5 }} styles={dataStackColumn}>
            <MonthGuestCount
              label={DynLabel("MonthDailyGCLabel")}
              guestCount={dayMonthData.guestCount}
              labelStyle={monthDayLabelStyle}
            />
            {props.selectedStructure!.is_retail ? (
              <React.Fragment>
                <MonthDayMargin
                  plannedMargin={dayMonthData.plannedMargin}
                  targetMargin={props.selectedStructure!.target_margin}
                />
                <MonthDayTransactionValue
                  transactionValue={dayMonthData.plannedAvgTransactionValue}
                  currency={dayMonthData.currency}
                  noZero={true}
                  isWrong={false}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MonthDayCost
                  label={DynLabel("MonthDailyPlannedLabel")}
                  cost={dayMonthData.totalPlannedCost}
                  currency={dayMonthData.currency}
                  noZero={true}
                  isWrong={dayMonthData.totalPlannedCost > dayMonthData.totalTargetCost}
                />
                <MonthDayCost
                  label={DynLabel("MonthDailyAvgPlannedLabel")}
                  cost={dayMonthData.avgPlannedCost}
                  currency={dayMonthData.currency}
                  noZero={true}
                  isWrong={dayMonthData.avgPlannedCost > dayMonthData.avgTargetCost}
                />
                <MonthVariance
                  label={DynLabel("MonthCostVarianceLabel")}
                  variance={dayMonthData.avgCostVar}
                  isUndefined={dayMonthData.avgTargetCost === 0}
                />
              </React.Fragment>
            )}
          </Stack>
          {/** Second column: guest count variance, target costs*/}
          <Stack tokens={{ childrenGap: 5 }} styles={dataStackColumn}>
            <MonthVariance
              label={DynLabel("MonthGCVarianceLabel")}
              variance={dayMonthData.guestCountVar}
              isUndefined={dayMonthData.hasNoGuestCountVar}
            />
            {!props.selectedStructure!.is_retail && (
              <React.Fragment>
                <MonthDayCost
                  label={DynLabel("MonthDailyTargetLabel")}
                  cost={dayMonthData.totalTargetCost}
                  currency={dayMonthData.currency}
                  noZero={true}
                  isWrong={false}
                />
                <MonthDayCost
                  label={DynLabel("MonthDailyAvgTargetLabel")}
                  cost={dayMonthData.avgTargetCost}
                  currency={dayMonthData.currency}
                  noZero={true}
                  isWrong={false}
                />
              </React.Fragment>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
