import { IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { commonViewColumn, dayHeaderColumnTitle } from "../StylingUtils";

interface IDayMenuHeaderColumnTitle {
  headerTitle: string;
  styling?: IStackStyles;
}

export const DayMenuHeaderColumnTitle: React.FC<IDayMenuHeaderColumnTitle> = (props) => {
  return (
    <Stack horizontal styles={mergeStyleSets(commonViewColumn, dayHeaderColumnTitle, props.styling)}>
      {props.headerTitle}
    </Stack>
  );
};
