import React, { useEffect, useState } from 'react';

import { IContextFromAX, IContextFromAXWithDate } from '../Models/IContextFromAx';
import { getISODate, getJsDateFromAXJson, getWeeksLastDay } from '../Utils';
import { DynControlInstance, DynDefaultCallFunction, DynObserve } from './DynService';
import { yesNoToBool } from '../StringUtils';

interface IPeriodContextProps {
  children: any;
  firstDay: Date;
  udpateCurrentDate: (d: Date) => void;
}

interface IPeriodContext {
  getMenuCycleName: () => string;
  getMenuRetail: () => boolean; //90342
  getWarehouseId: () => string;
  getPeriodMinDate: () => Date;
  getPeriodMaxDate: () => Date;
  getPortionFactorEditable: () => boolean;
  getSalesMixEditable: () => boolean;
  getPlannedQuantityEditable: () => boolean;
  getReferenceDate: () => Date;
  getInPeriodBoolForDates: (values: Array<Date>) => Array<boolean>;
  setServiceLocation: (serviceLocation: string) => void;
  getSelectedServiceLocation: () => string;
  getServiceLocation: () => string;
  serviceLocationFlag: () => boolean;
  serviceStationFlag: () => boolean;
  getServiceLocationList: () => Array<string>;
  getMenuPartName: () => string;
}

export const PeriodContext = React.createContext<IPeriodContext | null>(null);

export const PeriodContextProvider = (props: IPeriodContextProps) => {
  const [contextFromAX, setContextFromAX] = useState({
    ref_date: props.firstDay,
    menu_cycle_name: "",
    warehouse_id: "",
    isMenuRetail: false, //90342
    isPortionFactorEditable: false,
    isSalesMixEditable: false,
    isPlannedQtyEditable: false,
    from_date: props.firstDay,
    to_date: getWeeksLastDay(props.firstDay),
    useServiceLocation: false,
    useServiceStation: false,
    menuDayParts: ""
  } as IContextFromAXWithDate);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState("");

  useEffect(() => {
    // Observe context (menu cyle name, warehouse id, dates from-to)
    DynObserve(DynControlInstance().Context, function (contextObj: IContextFromAX | null) {

      console.log(`>>>>> context callback called:`, contextObj);
      if (contextObj !== null) {
        const fromDate = getJsDateFromAXJson(contextObj.from_date);
        const toDate = getJsDateFromAXJson(contextObj.to_date);
        const refDate = getJsDateFromAXJson(contextObj.ref_date);

        if (fromDate && toDate && refDate) {
          setContextFromAX({
            menu_cycle_name: contextObj.menu_cycle_name,
            warehouse_id: contextObj.warehouse_id,
            from_date: fromDate,
            to_date: toDate,
            ref_date: refDate,
            isMenuRetail: Boolean(contextObj.is_retail),
            isPortionFactorEditable: contextObj.isPortionFactorEditable,
            isSalesMixEditable: contextObj.isSalesMixEditable,
            isPlannedQtyEditable: contextObj.isPlannedQtyEditable,
            service_location_id: contextObj.service_location_id,
            useServiceLocation: yesNoToBool(contextObj["use service location"]),
            useServiceStation: yesNoToBool(contextObj["use service station"]),
            ServiceLocation: contextObj.ServiceLocation,
            menuDayParts: contextObj.menuDayParts
          });
          props.udpateCurrentDate(refDate);
          console.log("++++++++++++++++++++++++++++++++ isMenuRetail" + contextFromAX.isMenuRetail);
        } else {
          throw new Error("Some dates from the D365 context are not valid...");
        }
      }
    });
  }, []); // give warning of missing dependencies but it's ok

  const myContextValue: IPeriodContext = {
    getMenuCycleName: () => {
      return contextFromAX.menu_cycle_name;
    },
    getWarehouseId: () => {
      return contextFromAX.warehouse_id;
    },
    getPortionFactorEditable: () => {
      return contextFromAX.isPortionFactorEditable;
    },

    getMenuRetail: () => {
      return contextFromAX.isMenuRetail;
    },

    getSalesMixEditable: () => {
      return contextFromAX.isSalesMixEditable;
    },
    getPlannedQuantityEditable: () => {
      return contextFromAX.isPlannedQtyEditable;
    },
    getPeriodMinDate: () => {
      return contextFromAX.from_date;
    },
    getPeriodMaxDate: () => {
      return contextFromAX.to_date;
    },
    getReferenceDate: () => {
      return contextFromAX.ref_date;
    },
    getInPeriodBoolForDates: (values: Array<Date>) => {
      return values.map(value => {
        return value >= contextFromAX.from_date && value <= contextFromAX.to_date;
      });
    },
    setServiceLocation: (serviceLocation: string) => {
      setSelectedServiceLocation(serviceLocation);
    },
    getSelectedServiceLocation: () => {
      return selectedServiceLocation;
    },
    getServiceLocation: () => {
      return contextFromAX.service_location_id;
    },
    serviceLocationFlag: () => {
      return contextFromAX.useServiceLocation;
    },
    serviceStationFlag: () => {
      return contextFromAX.useServiceStation;
    },
    getServiceLocationList: () => {
      return contextFromAX.ServiceLocation?.split(";");
    },
    getMenuPartName: (): string => {
      return contextFromAX.menuDayParts;
    }
  };

  return <PeriodContext.Provider value={myContextValue}>{props.children}</PeriodContext.Provider>;
};
