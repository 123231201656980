import { Checkbox, ICheckboxStyles, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { DataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import { BudgetPill } from "../SharedComponents/BudgetPill";
import { checkboxStyle, commonViewColumn, dayHeaderColumnTitle, mediumGrey, pureWhite } from "../StylingUtils";
import { range } from "../Utils";
import { DayMenuHeaderColumnTitle } from "./DayMenuHeaderColumnTitle";
import { DayViewContext } from "./DayView";
import { columnStyles, DayDisplay, indexes, nbDayColumns, orderedIndexes, shouldBeDisplay } from "./DayViewHelper";

const backgroundCheckbox: ICheckboxStyles = {
  checkbox: {
    // backgroundColor: pureWhite,
    borderColor: mediumGrey,
    // "is-checked": {
    //   backgroundColor: "#2b3a58"
    // }
  },
};

const myStyle: IStackStyles = {
  root: {
    displayName: "DayMenuHeaderColumns",
    width: "100%",
    height: "auto",
  },
};

interface IDayMenuHeaderColumns {
  menuStructureName: string;
  doesMenuStructureExist: boolean;
  isMenuRetail: boolean;
  plannedCost?: number;
  targetCost?: number;
  currency?: string;
  styling?: IStackStyles;
  allMenuStructures?: string[];
}

export const DayMenuHeaderColumns: React.FC<IDayMenuHeaderColumns> = (props) => {
  const dataContextValue = useContext(DataContext);
  const dayViewContextValue = useContext(DayViewContext);

  const allMenuStructureRecipeIds: Array<number> = [];
  if (dataContextValue && props.allMenuStructures && props.allMenuStructures.length > 0) {
    props.allMenuStructures.forEach((menuStructure) => {
      allMenuStructureRecipeIds.push(...dataContextValue.getRecipeIds(dayViewContextValue!.currentDay, menuStructure));
    });
  } else if (dataContextValue && dayViewContextValue) {
    allMenuStructureRecipeIds.push(...dataContextValue.getRecipeIds(dayViewContextValue.currentDay, props.menuStructureName));
  }


  const handleGlobalCheck = (checked: boolean | undefined) => {
    if (dayViewContextValue) {
      checked
        ? dayViewContextValue.dispatchSelectionEvent({ type: "add", ids: [...allMenuStructureRecipeIds] })
        : dayViewContextValue.dispatchSelectionEvent({ type: "remove", ids: [...allMenuStructureRecipeIds] });
    }
  };

  // We prepare every header and stock them in headerColumnComponents array
  // According to the display type provided by DayViewHelper, header will be rendered or not
  const headerColumnComponents = new Array(nbDayColumns);

  headerColumnComponents[indexes.idxRecipe] = (
    <Stack
      horizontal
      styles={mergeStyleSets(commonViewColumn, dayHeaderColumnTitle, columnStyles[indexes.idxRecipe])}
      style={{ justifyContent: "start" }}
      key={`${props.menuStructureName}-RecipeColumn`}>
      {(allMenuStructureRecipeIds.length > 0 || (props.allMenuStructures && props.allMenuStructures.length > 0)) && (
        <Checkbox
          styles={mergeStyleSets(checkboxStyle, backgroundCheckbox)}
          checked={allMenuStructureRecipeIds.every((id) => {
            return dayViewContextValue?.selectedElements.includes(id);
          })}
          onChange={(ev, checked) => {
            handleGlobalCheck(checked);
          }}
        />
      )}
      <div style={{ width: "8px" }}></div>
      {DynLabel("RecipesColumnTitle")}
    </Stack>
  );

  headerColumnComponents[indexes.idxPortionFactor] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("PortionFactorColumnTitle")}
      styling={columnStyles[indexes.idxPortionFactor]}
      key={`${props.menuStructureName}-PFColumn`}
    />
  );

  headerColumnComponents[indexes.idxSalesMix] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("SalesMix")}
      styling={columnStyles[indexes.idxSalesMix]}
      key={`${props.menuStructureName}-SMColumn`}
    />
  );

  headerColumnComponents[indexes.idxPlannedQty] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("PlannedQuantityColumnTitle")}
      styling={columnStyles[indexes.idxPlannedQty]}
      key={`${props.menuStructureName}-PQColumn`}
    />
  );

  headerColumnComponents[indexes.idxPlannedCost] = (
    <Stack
      horizontal
      styles={mergeStyleSets(commonViewColumn, dayHeaderColumnTitle, columnStyles[indexes.idxPlannedCost])}
      key={`${props.menuStructureName}-PCColumn`}>
      {props.doesMenuStructureExist && (
        <BudgetPill budget={props.targetCost!} actualCost={props.plannedCost} currency={props.currency!} />
      )}
    </Stack>
  );

  headerColumnComponents[indexes.idxPlannedSales] = (
    <Stack
      horizontal
      styles={mergeStyleSets(commonViewColumn, dayHeaderColumnTitle, columnStyles[indexes.idxPlannedSales])}
      key={`${props.menuStructureName}-PSColumn`}>
      {props.doesMenuStructureExist && (
        <BudgetPill budget={props.targetCost!} actualCost={props.plannedCost} currency={props.currency!} />
      )}
    </Stack>
  );

  headerColumnComponents[indexes.idxPrice] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("Price")}
      styling={columnStyles[indexes.idxPrice]}
      key={`${props.menuStructureName}-PriceColumn`}
    />
  );

  headerColumnComponents[indexes.idxRecipeTargetCost] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("RecipeTargetCost")}
      styling={columnStyles[indexes.idxRecipeTargetCost]}
      key={`${props.menuStructureName}-RTCColumn`}
    />
  );

  headerColumnComponents[indexes.idxProductionOrder] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("ProductionOrderColumnTitle")}
      styling={columnStyles[indexes.idxProductionOrder]}
      key={`${props.menuStructureName}-POColumn`}
    />
  );

  headerColumnComponents[indexes.idxTime] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("TimeColumnTitle")}
      styling={columnStyles[indexes.idxTime]}
      key={`${props.menuStructureName}-TimeColumn`}
    />
  );

  headerColumnComponents[indexes.idxDifficulty] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("DifficultyColumnTitle")}
      styling={columnStyles[indexes.idxDifficulty]}
      key={`${props.menuStructureName}-DiffColumn`}
    />
  );

  headerColumnComponents[indexes.idxAllergens] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("AllergensColumnTitle")}
      styling={mergeStyleSets(columnStyles[indexes.idxAllergens], {
        root: { justifyContent: "flex-start" },
      } as IStackStyles)}
      key={`${props.menuStructureName}-AllColumn`}
    />
  );

  headerColumnComponents[indexes.idxBatch] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("BatchColumnTitle")}
      styling={columnStyles[indexes.idxBatch]}
      key={`${props.menuStructureName}-BatchColumn`}
    />
  );

  headerColumnComponents[indexes.idxPlannedMargin] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("PlannedMargin")}
      styling={columnStyles[indexes.idxPlannedMargin]}
      key={`${props.menuStructureName}-PMColumn`}
    />
  );

  headerColumnComponents[indexes.idxTargetMargin] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("TargetMargin")}
      styling={columnStyles[indexes.idxTargetMargin]}
      key={`${props.menuStructureName}-TMColumn`}
    />
  );

  headerColumnComponents[indexes.idxActualSellingPrice] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("ActualSellingPrice")}
      styling={columnStyles[indexes.idxActualSellingPrice]}
      key={`${props.menuStructureName}-ASPColumn`}
    />
  );

  headerColumnComponents[indexes.idxPlannedSellingPrice] = (
    <DayMenuHeaderColumnTitle
      headerTitle={DynLabel("PlannedSellingPrice")}
      styling={columnStyles[indexes.idxActualSellingPrice]}
      key={`${props.menuStructureName}-PSPColumn`}
    />
  );

  return (
    <Stack horizontal verticalFill verticalAlign="end" styles={mergeStyleSets(myStyle, props.styling)}>
      {range(0, nbDayColumns - 1).map((i) => {
        const currentIdx = orderedIndexes[i];

        switch (shouldBeDisplay[currentIdx]) {
          case DayDisplay.Both:
            return headerColumnComponents[currentIdx];

          case DayDisplay.Retail:
            return props.isMenuRetail ? headerColumnComponents[currentIdx] : null;

          case DayDisplay.Standard:
            return props.isMenuRetail ? null : headerColumnComponents[currentIdx];

          case DayDisplay.None:
            return null;

          default:
            return null;
        }
      })}
    </Stack>
  );
};
