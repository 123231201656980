import { FontIcon, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { monthDayLabelStyle } from "../StylingUtils";

const getArrowFromNumber = (val: number) => {
  if (val > 0) return "Up";
  if (val < 0) return "Down";

  return "Forward";
};

const getSignFromNumber = (val: number) => {
  if (val > 0) return "+";

  return "";
};

interface IMonthVariance {
  label: string;
  variance: number;
  isUndefined: boolean;
  styling?: IStackStyles;
}

export const MonthVariance: React.FC<IMonthVariance> = (props) => {
  return (
    <Stack tokens={{ childrenGap: 1 }} styles={props.styling}>
      <span style={monthDayLabelStyle}>{props.label}</span>
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <FontIcon iconName={getArrowFromNumber(props.variance)} />
        <span>{props.isUndefined ? "--" : `${getSignFromNumber(props.variance)}${Math.round(props.variance)}%`}</span>
      </Stack>
    </Stack>
  );
};
