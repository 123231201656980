import { useId } from "@uifabric/react-hooks";
import { IStackStyles, ITooltipProps, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import React, { useContext } from "react";
import { PeriodContext } from "../Services/PeriodContextService";
import { DynLabel } from "../Services/DynService";
import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { pillCommon, pureBlack, pureWhite, right, wrong } from "../StylingUtils";
import { IAvaMenuPlannerTooltip, AvaMenuPlannerTooltip, tooltipCalloutProps } from "./AvaMenuPlannerTooltip";
import { DataContext, IDataContext } from "../Services/DataService";
import { isPropertySignature } from "typescript";

const myStyle = {
  root: {
    cursor: "default",
    minHeight: "19px",
    flexGrow: 0,
    flexShrink: 0,
  },
};
const myStyleLeft = (props: IBudgetPill) => ({
  root: [
    {
      borderTopLeftRadius: "2px",
      borderBottomLeftRadius: "2px",
      color: pureWhite,
    },
    props.actualCost && props.actualCost > props.budget! ? { backgroundColor: wrong } : { backgroundColor: right },
  ],
});

const myStyleLeftRetail = (props: IBudgetPill) => ({
  root: [
    {
      borderTopLeftRadius: "2px",
      borderBottomLeftRadius: "2px",
      color: pureWhite,
    },
    props.actualCost && props.actualCost > props.budget! ? { backgroundColor: right } : { backgroundColor: wrong },
  ],
});

const myStyleRight: IStackStyles = {
  root: {
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
    color: pureBlack,
    backgroundColor: pureWhite,
  },
};

const defaultTooltipProps = (): IAvaMenuPlannerTooltip => ({
  title: DynLabel("BudgetPillInfoTitle"),
  text: DynLabel("BudgetPillInfo"),
  RetailTitle: DynLabel("RetailBudgetPillInfoTitle"),
  RetailText: DynLabel("RetailBudgetPillInfo"),
});

const newBudgetPillTooltip = (tooltipProps?: IAvaMenuPlannerTooltip): ITooltipProps => {
  return {
    onRenderContent: () => <AvaMenuPlannerTooltip {...(tooltipProps || defaultTooltipProps())} />,
  };
};

interface IBudgetPill {
  currency: string;
  budget: number;
  actualCost?: number;
  styling?: IStackStyles;
  tooltipProps?: IAvaMenuPlannerTooltip;
}

export const BudgetPill: React.FC<IBudgetPill> = (props) => {
  const currentPeriodContext = useContext(PeriodContext);
  const tooltipId = useId("BudgetPillTooltipId");
  const budgetPillTooltip = newBudgetPillTooltip(props.tooltipProps);
  const currentDataContext = useContext(DataContext);

  const isMenuRetailArr = currentDataContext && currentDataContext.getMenuStructures().map((menu) => menu.is_retail); //PBI 90342 Retail
  const isMenuRetail = isMenuRetailArr && Boolean(Number(isMenuRetailArr[0])); //PBI 90342 Retail

  return (
    <TooltipHost id={tooltipId} calloutProps={tooltipCalloutProps} tooltipProps={budgetPillTooltip}>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="stretch"
        styles={mergeStyleSets(pillCommon, props.styling)}
        aria-describedby={tooltipId}>
        {props.actualCost !== undefined && props.actualCost >= 0 && <Stack
          verticalAlign="center"
          tokens={{ padding: "2px 4px" }}
          styles={
            isMenuRetail
              ? mergeStyleSets(myStyle, myStyleLeftRetail(props))
              : mergeStyleSets(myStyle, myStyleLeft(props))
          }>
          {props.actualCost ? formatCurrencyNumber(props.currency, props.actualCost) : " -- "}
        </Stack>}
        <Stack verticalAlign="center" tokens={{ padding: "2px 4px" }} styles={mergeStyleSets(myStyle, myStyleRight)}>
          {formatCurrencyNumber(props.currency, props.budget)}
        </Stack>
      </Stack>
    </TooltipHost>
  );
};
