import { DynError, DynLabel } from './DynService';

export function validatePortionFactor(portionFactor: any) {
  const portionFactorValue = Number(portionFactor);
  if (!isNaN(portionFactorValue) && portionFactorValue > 0) return true;
  DynError(DynLabel("InvalidPortionFactor"));
  return false;
}

export function validateSalesMix(salesMix: any) {
  const salesMixValue = Number(salesMix);
  if (!isNaN(salesMixValue) && salesMixValue > 0) return true;
  DynError(DynLabel("InvalidSalesMix"));
  return false;
}

export function validatePlannedQuantity(plannedQuantity: any) {
  const plannedQuantityValue = Number(plannedQuantity);
  if (!isNaN(plannedQuantityValue) && plannedQuantityValue > 0) return true;
  DynError(DynLabel("InvalidPlannedQuantity"));
  return false;
}

export function validateGuestCount(guestCount: any) {
  const guestCountValue = Number(guestCount);
  if (!isNaN(guestCountValue) && guestCountValue > 0) return true;
  DynError(DynLabel("InvalidGuestCount"));
  return false;
}
