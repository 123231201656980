import { useCallback, useState } from 'react';

export const useCollapseState = () => {
  let [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return { isCollapsed, handleCollapse };
};
