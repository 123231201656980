import { IStackStyles, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { HeaderDay } from "../SharedComponents/HeaderDay";
import { mediumGrey, pureWhite, strongBlue, weekViewSidebar } from "../StylingUtils";
import { getWeekNumber } from "../Utils";
import { WeekViewContext } from "./WeekView";

const myStyle: IStackStyles = {
  root: {
    displayName: "WeekViewHeader",
    width: "100%",
    backgroundColor: strongBlue,
    color: pureWhite,
    borderTop: `1px solid ${mediumGrey}`,
  },
};

interface IWeekViewHeader {
  handleRedirectToDailyView: (d: Date) => void;
}

export const WeekViewHeader: React.FC<IWeekViewHeader> = (props) => {
  const weekViewContextValue = useContext(WeekViewContext);

  return (
    weekViewContextValue && (
      <Stack horizontal horizontalAlign="stretch" styles={myStyle}>
        <Stack
          horizontal
          horizontalAlign="start"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          styles={weekViewSidebar}>
          <span>{`W${getWeekNumber(weekViewContextValue.weekDates[0])}`}</span>
        </Stack>
        {weekViewContextValue.weekDates.map((d, i) => (
          <HeaderDay
            key={d.valueOf()}
            date={d}
            type={"week"}
            inPeriod={weekViewContextValue.inPeriodBools[i]}
            handleRedirectToDailyView={props.handleRedirectToDailyView}
          />
        ))}
      </Stack>
    )
  );
};
