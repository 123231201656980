import { IStackStyles, ITooltipProps, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import React from "react";
import { useId } from "@uifabric/react-hooks";

import { pillCommon, pureBlack, pureWhite, right, wrong } from "../StylingUtils";
import { IAvaMenuPlannerTooltip, AvaMenuPlannerTooltip, tooltipCalloutProps } from "./AvaMenuPlannerTooltip";

const myStyle = {
  root: {
    cursor: "default",
    minHeight: "19px",
    flexGrow: 0,
    flexShrink: 0,
  },
};

const myStyleLeft = (props: IPill) => ({
  root: [
    {
      borderTopLeftRadius: "2px",
      borderBottomLeftRadius: "2px",
      color: pureWhite,
    },
    props.comparisonValue && props.isWrong ? { backgroundColor: wrong } : { backgroundColor: right },
  ],
});

const myStyleRight: IStackStyles = {
  root: {
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
    color: pureBlack,
    backgroundColor: pureWhite,
  },
};

const newPillTooltip = (tooltipProps: IAvaMenuPlannerTooltip): ITooltipProps => {
  return {
    onRenderContent: () => <AvaMenuPlannerTooltip {...tooltipProps} />,
  };
};

interface IPill {
  referenceValue: string;
  comparisonValue?: string;
  isWrong: boolean;
  emptyValue?: string;
  tooltipProps: IAvaMenuPlannerTooltip;
  styling?: IStackStyles;
}

export const Pill: React.FC<IPill> = (props) => {
  const tooltipId = useId("PillTooltipId");
  const tooltipProps = newPillTooltip(props.tooltipProps);

  return (
    <TooltipHost id={tooltipId} calloutProps={tooltipCalloutProps} tooltipProps={tooltipProps}>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="stretch"
        styles={mergeStyleSets(pillCommon, props.styling)}
        aria-describedby={tooltipId}>
        <Stack
          verticalAlign="center"
          tokens={{ padding: "2px 4px" }}
          styles={mergeStyleSets(myStyle, myStyleLeft(props))}>
          {props.comparisonValue || props.emptyValue || " -- "}
        </Stack>
        <Stack verticalAlign="center" tokens={{ padding: "2px 4px" }} styles={mergeStyleSets(myStyle, myStyleRight)}>
          {props.referenceValue}
        </Stack>
      </Stack>
    </TooltipHost>
  );
};
