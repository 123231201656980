import { IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { IAvaMenuPlannerTooltip } from "../SharedComponents/AvaMenuPlannerTooltip";
import { BudgetPill } from "../SharedComponents/BudgetPill";

interface IMonthWeekCost {
  label: string;
  actualCost: number;
  targetCost: number;
  currency: string;
  styling?: IStackStyles;
  labelStyle?: React.CSSProperties;
  budgetPillTooltipProps?: IAvaMenuPlannerTooltip;
}

export const MonthWeekCost: React.FC<IMonthWeekCost> = (props) => {
  return (
    <Stack horizontalAlign="start" tokens={{ childrenGap: 1 }}>
      <span style={props.labelStyle}>{props.label}</span>
      <BudgetPill
        budget={props.targetCost}
        actualCost={props.actualCost}
        currency={props.currency}
        tooltipProps={props.budgetPillTooltipProps}
      />
    </Stack>
  );
};
