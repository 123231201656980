import { Icon, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { getNextGuestCountType, GuestCountType, IDayPlanData, isTimeZoneAligned, isValid } from "../Models/IDayPlanData";
import { DataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import { updateGuestCount } from "../Services/UpdateService";
import { validateGuestCount } from "../Services/ValidationService";
import { EditableField } from "../SharedComponents/EditableField";
import { GuestCountPill } from "../SharedComponents/GuestCountPill";
import { MenuStructureActionsButton } from "../SharedComponents/MenuStructureActionsButton";
import { WarningIcon } from "../SharedComponents/WarningIcon";
import { imitateAXStrFmt } from "../StringUtils";
import {
  commonViewColumn,
  darkBlue,
  dayViewSidebar,
  headerBackgroundConfirmed,
  headerForbiddenDay,
  pureWhite,
  serviceStationHeaderStyle,
} from "../StylingUtils";
import { DayMenuHeaderColumns } from "./DayMenuHeaderColumns";
import { DayViewContext } from "./DayView";
import { getTimeFromTimeOfDay } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    displayName: "DayMenuHeader",
    height: "auto",
    backgroundColor: darkBlue,
    color: pureWhite,
    width: "100%",
  },
};

interface IDayMenuHeader {
  menuStructureName: string;
  isDayAllowed: boolean;
  isStructRetail: boolean;
  headerData?: IDayPlanData;
  currentDay: Date;
  isCollapsed: boolean;
  onCollaspeClick: () => void;
  menuNameDisplayOverride?: string;
  budgetOverride?: number;
  plannedCostOverride?: number;
  guestCountOverride?: number;
  servingTimePassed?: boolean;
  isServiceStation?: boolean;
}

export const DayMenuHeader: React.FC<IDayMenuHeader> = (props) => {
  let dayViewContextValue = useContext(DayViewContext);
  let dataContextValue = useContext(DataContext);
  const dataValid = isValid(props.headerData);
  const dataConfirmed = dataValid && Boolean(props.headerData!.is_confirmed);

  let headerColor: IStackStyles | undefined = undefined;
  if (dataValid && !props.isDayAllowed) {
    headerColor = headerForbiddenDay;
  } else if (dataConfirmed) {
    headerColor = headerBackgroundConfirmed;
  }

  if (props.isServiceStation) {
    headerColor = headerColor ? mergeStyleSets(headerColor, serviceStationHeaderStyle) : serviceStationHeaderStyle;
  }

  const allMenuStructures = props.menuNameDisplayOverride ? dataContextValue!.getMenuStructures() : undefined;

  return (
    <Stack horizontal horizontalAlign="stretch" verticalFill styles={myStyle}>
      <Stack
        horizontal
        horizontalAlign="start"
        tokens={{ childrenGap: 10 }}
        styles={mergeStyleSets(dayViewSidebar, headerColor)}>
        <Icon
          style={{ cursor: "pointer", marginTop: "3px" }}
          iconName={props.isCollapsed ? "ChevronRight" : "ChevronDown"}
          onClick={() => {
            props.onCollaspeClick();
          }}
        />

        <Stack
          verticalAlign={dataValid ? "center" : "start"}
          wrap
          tokens={{ childrenGap: 10 }}
          styles={mergeStyleSets(commonViewColumn, { inner: { width: "100%" } } as IStackStyles)}>
          <span className="MayOverflow">{props.menuNameDisplayOverride ?? props.menuStructureName}</span>
          {props.menuNameDisplayOverride === undefined && <span>{getTimeFromTimeOfDay(props.headerData?.ServingTimeForm)}</span>}
          {dataValid && (
            <Stack horizontal wrap horizontalAlign="space-between" verticalAlign="center">
              <EditableField
                isDisabled={props.menuNameDisplayOverride ? true : !props.isDayAllowed} // add || props.guestCountNumberOverride !== undefined
                valueToEdit={props.headerData!.guest_count_number}
                component={
                  <GuestCountPill
                    count={props.guestCountOverride ?? props.headerData!.guest_count_number}
                    type={props.headerData!.guest_count_type}
                    isEditable={props.menuNameDisplayOverride ? false : (props.isDayAllowed && !props.servingTimePassed)}
                  />
                }
                updateValue={(newGCValue, changeType) => {
                  updateGuestCount(
                    dataContextValue!,
                    props.headerData!.recid,
                    newGCValue,
                    props.headerData!.guest_count_type,
                    changeType
                  );
                }}
                displayEditIcon={false}
                validateValue={validateGuestCount}
                id={"guestCount" + props.headerData!.recid}
                withCheckbox={props.headerData!.guest_count_type !== GuestCountType.Actual}
                checkboxLabel={imitateAXStrFmt(
                  DynLabel("GCUpdateLabel"),
                  // NB: checkbox and label are not displayed if type is Actual
                  getNextGuestCountType(props.headerData!.guest_count_type).toString()
                )}
              />
              <Stack horizontal verticalAlign="center" styles={{ root: { height: "100%" } }}>
                <WarningIcon warnings={props.headerData!.warnings} cssClasses={"WarningMenuStruct"} />
                <MenuStructureActionsButton
                  menuId={props.headerData!.recid}
                  isMenuConfirmed={dataConfirmed}
                  isMenuRetail={props.isStructRetail}
                  isSalesMixConfirmed={Boolean(props.headerData!.is_sales_mix_confirmed)}
                  isDayAllowed={props.isDayAllowed}
                  periodMin={props.currentDay}
                  periodMax={props.currentDay}
                  userManager={dayViewContextValue!.isUserManager}
                  disabled={!isTimeZoneAligned(props.headerData)}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {props.isDayAllowed || dataValid ? (
        <DayMenuHeaderColumns
          menuStructureName={props.menuStructureName}
          doesMenuStructureExist={dataValid}
          isMenuRetail={props.isStructRetail}
          plannedCost={props.plannedCostOverride ?? props.headerData?.menu_planned_cost}
          targetCost={props.budgetOverride ?? props.headerData?.menu_target_cost}
          currency={props.headerData?.planned_cost_currency}
          styling={headerColor}
          allMenuStructures={allMenuStructures?.map((s) => s.menu_structure)}
        />
      ) : (
        <Stack tokens={{ padding: 5 }} verticalAlign="center" style={{ width: "100%", fontSize: 14 }}>
          {DynLabel("DayForbiddenBySetup")}
        </Stack>
      )}
    </Stack>
  );
};
