import { Icon, IStackStyles, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useReducer, useState } from "react";

import { useCollapseState } from "../Hooks/ICanCollapse";
import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { DataContext } from "../Services/DataService";
import { checkboxStyle, mediumGrey, pureBlack, pureWhite, weekViewColumn, weekViewSidebar } from "../StylingUtils";
import { WeekSubMenuRecipes } from "./WeekSubMenuRecipes";
import { WeekViewContext } from "./WeekView";
import { getISODate } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    color: pureBlack,
    backgroundColor: pureWhite,
    borderBottom: `1px solid ${mediumGrey}`,
  },
};

interface IWeekSubMenu {
  parentStructure: IAXMenuStructureData;
  subMenuName: string;
  displayTechnicalName: boolean;
  serviceStation?: string;
}

export const WeekSubMenu: React.FC<IWeekSubMenu> = (props) => {
  const { isCollapsed, handleCollapse } = useCollapseState();
  const [servingTime, setServingTime] = useState<number>();
  const [serviceLocation, setServiceLocation] = useState<string>();

  const dataContextValue = useContext(DataContext);
  const weekViewContextValue = useContext(WeekViewContext);

  const weekRecipesReducer = () => {
    if (weekViewContextValue && dataContextValue) {
      return weekViewContextValue.weekDates.map((d) =>
        dataContextValue.getDayPlanLineData(d, props.parentStructure.menu_structure, props.subMenuName)
      );
    }
    return [];
  };

  const [weekRecipes, setWeekRecipes] = useReducer(weekRecipesReducer, weekRecipesReducer());

  useEffect(() => {
    setWeekRecipes();
    // Get the serving time from the menu structure data
    if (dataContextValue && weekViewContextValue && weekViewContextValue.weekDates.length > 0) {
      const menuStructureData = dataContextValue.getMenuStructureData(weekViewContextValue?.weekDates[1], props.parentStructure.menu_structure);
      setServingTime(menuStructureData?.ServingTimeForm);
      setServiceLocation(menuStructureData?.serviceLocation);
    }
  }, [weekViewContextValue, dataContextValue]);

  return (
    dataContextValue &&
    weekViewContextValue && (
      <Stack horizontal styles={myStyle}>
        <Stack
          horizontal
          horizontalAlign="start"
          verticalAlign="start"
          tokens={{ childrenGap: 10 }}
          styles={weekViewSidebar}>
          <Icon
            style={{ cursor: "pointer" }}
            iconName={isCollapsed ? "ChevronRight" : "ChevronDown"}
            onClick={handleCollapse}
          />
          <span className="MayOverflow">{props.subMenuName}</span>
        </Stack>
        {!isCollapsed &&
          weekViewContextValue.weekDates.map((d, i) => (
            <WeekSubMenuRecipes
              key={`${d.valueOf()}-WeekSubMenu`}
              inPeriod={weekViewContextValue.inPeriodBools[i]}
              recipesData={weekRecipes[i]}
              displayTechnicalName={props.displayTechnicalName}
              parentStructure={props.parentStructure}
              subMenuName={props.subMenuName}
              menuDate={d}
              isDayAllowed={Boolean(props.parentStructure.setupDays[d.getUTCDay()])}
              startTime={(servingTime ?? 0) * 1000}
              parentChecked={weekViewContextValue.selectedDays.includes(`${props.parentStructure.menu_structure}:${getISODate(d)}`)}
              serviceLocation={serviceLocation}
              serviceStation={props.serviceStation}
            />
          ))}
        {isCollapsed &&
          weekViewContextValue.weekDates.map((d) => (
            <Stack horizontal styles={weekViewColumn} key={`CollapsedSubMenu-${d}`} />
          ))}
      </Stack>
    )
  );
};
