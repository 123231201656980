import { Dropdown, Icon, IIconStyles, IStackStyles, Stack } from "@fluentui/react";
import React, { useState } from "react";

import { DynLabel } from "../Services/DynService";
import { getDayName, getMonthAbbr, getMonthName } from "../Services/GlobalizeService";
import { primaryBlue, pureWhite } from "../StylingUtils";
import { getMonthFirstDay, getNextDate, getWeeksLastDay } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    displayName: "DateSelector",
    minHeight: "48px",
    backgroundColor: primaryBlue,
    color: pureWhite,
  },
};

const datesStyle: IStackStyles = {
  root: {
    displayName: "Dates",
    flexGrow: 1,
    textAlign: "center",
    fontWeight: 600,
    fontSize: "18px",
  },
};

const chevronStyle: IIconStyles = {
  root: {
    fontWeight: 600,
    cursor: "pointer",
  },
};

interface IDateSelector {
  startDay: Date;
  selectedView: number;
  periodMin: Date;
  periodMax: Date;
  onDateChange: (newDate: Date) => void;
}

interface IComputedInfo {
  dateLabel: string;
  nextDateInPast: Date | undefined;
  nextDateInFuture: Date | undefined;
}

const DateSelector: React.FC<IDateSelector> = (props) => {
  const computeComponentValues = (): IComputedInfo => {
    let dateLabel: string = "";
    let nextDateInPast: Date | undefined = undefined;
    let nextDateInFuture: Date | undefined = undefined;
    let potentialPrevious;
    let potentialNext;

    switch (props.selectedView) {
      case 1: // day view
        dateLabel = `${getDayName(props.startDay.getUTCDay())} ${props.startDay.getUTCDate()} ${getMonthName(
          props.startDay.getUTCMonth()
        )}`;

        potentialPrevious = getNextDate(props.startDay, "day", -1);
        if (potentialPrevious.setUTCHours(0, 0, 0, 0) >= props.periodMin.setUTCHours(0, 0, 0, 0)) {
          nextDateInPast = potentialPrevious;
        }

        potentialNext = getNextDate(props.startDay, "day", 1);
        if (potentialNext.setUTCHours(0, 0, 0, 0) <= props.periodMax.setUTCHours(0, 0, 0, 0)) {
          nextDateInFuture = potentialNext;
        }
        break;

      case 2: // week view
        const lastDay = getWeeksLastDay(props.startDay);

        dateLabel = `${props.startDay.getUTCDate()} ${getMonthAbbr(
          props.startDay.getUTCMonth()
        )} - ${lastDay.getUTCDate()} ${getMonthAbbr(lastDay.getUTCMonth())}`;

        // can go in past if at least the last day of the previous week is in the period
        // potentialPrevious is the previous monday
        potentialPrevious = getNextDate(props.startDay, "week", -1);
        const lastDayOfPreviousWeek = getWeeksLastDay(potentialPrevious);
        if (lastDayOfPreviousWeek.setUTCHours(0, 0, 0, 0) >= props.periodMin.setUTCHours(0, 0, 0, 0)) {
          nextDateInPast = potentialPrevious;
        }

        //can go in future if at least the first day of the next week is in the period
        potentialNext = getNextDate(props.startDay, "week", 1);
        if (potentialNext.setUTCHours(0, 0, 0, 0) <= props.periodMax.setUTCHours(0, 0, 0, 0)) {
          nextDateInFuture = potentialNext;
        }

        break;
      case 3: //
        dateLabel = `${getMonthName(props.startDay.getUTCMonth())} ${props.startDay.getUTCFullYear()}`;
        // can go in past if at least the last day of the previous month is in the period
        // potentialPrevious will be the last day of previous month
        potentialPrevious = getNextDate(getMonthFirstDay(props.startDay), "day", -1);
        if (potentialPrevious.setUTCHours(0, 0, 0, 0) >= props.periodMin.setUTCHours(0, 0, 0, 0)) {
          nextDateInPast = potentialPrevious;
        }

        //can go in future if at least the first day of the next month is in the period
        // potentialNext will be the first day of next month
        potentialNext = getNextDate(props.startDay, "month", 1);
        if (potentialNext.setUTCHours(0, 0, 0, 0) <= props.periodMax.setUTCHours(0, 0, 0, 0)) {
          nextDateInFuture = potentialNext;
        }
        break;
    }

    return {
      dateLabel: dateLabel,
      nextDateInPast: nextDateInPast,
      nextDateInFuture: nextDateInFuture,
    };
  };

  let [isProductionView, setIsProductionView] = useState(true);
  const componentValues = computeComponentValues();

  return (
    <Stack horizontal verticalAlign="center" styles={myStyle}>
      <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 5 }} styles={datesStyle}>
        {componentValues.nextDateInPast && (
          <Icon
            iconName="ChevronLeft"
            styles={chevronStyle}
            onClick={() => {
              if (componentValues.nextDateInPast) {
                // on week view, if nextDateInPast valid but out of period bounds, we should change for periodMin
                // otherwise, click to Day tab will send user on an invalid date
                props.selectedView === 2 && componentValues.nextDateInPast < props.periodMin
                  ? props.onDateChange(props.periodMin)
                  : props.onDateChange(componentValues.nextDateInPast);
              }
            }}
          />
        )}
        &nbsp;
        <span>{componentValues.dateLabel}</span>&nbsp;
        {componentValues.nextDateInFuture && (
          <Icon
            iconName="ChevronRight"
            styles={chevronStyle}
            onClick={() => {
              if (componentValues.nextDateInFuture) props.onDateChange(componentValues.nextDateInFuture);
            }}
          />
        )}
      </Stack>
      {/* <div
        style={{
          flexGrow: 0,
          minWidth: "150px",
          maxWidth: "150px",
          margin: "0px 20px"
        }}> */}
      {/* Hiding view dropdown selector for now - but it may be implemented at some point */}
      {false && (
        <Dropdown
          selectedKey={isProductionView ? "1" : "2"}
          options={[
            { key: "1", text: DynLabel("ProductionView") },
            { key: "2", text: DynLabel("NutritionView") },
          ]}
          onChange={(e, o) => setIsProductionView((o!.key as string) === "1" ? true : false)}
        />
      )}
      {/* </div> */}
    </Stack>
  );
};

export default DateSelector;
