import { Icon, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { useCollapseState } from "../Hooks/ICanCollapse";
import { IDayPlanData } from "../Models/IDayPlanData";
import { DataContext } from "../Services/DataService";
import { dayViewSidebar, mediumGrey, pureBlack, pureWhite } from "../StylingUtils";
import { DayMenuDetail } from "./DayMenuDetail";

interface IDaySubMenu {
  subMenu: string;
  parentStructureName: string;
  isParentRetail: boolean;
  parentStructureData?: IDayPlanData;
  currentDay: Date;
  displayTechnicalName: boolean;
  isDayAllowed: boolean;
}

const myStyle: IStackStyles = {
  root: {
    color: pureBlack,
    backgroundColor: pureWhite,
    borderBottom: `1px solid ${mediumGrey}`,
    width: "100%",
  },
};

export const DaySubMenu: React.FC<IDaySubMenu> = (props) => {
  const { isCollapsed, handleCollapse } = useCollapseState();

  return (
    <DataContext.Consumer>
      {(DataContext) =>
        DataContext && (
          <Stack horizontal styles={myStyle}>
            <Stack
              horizontal
              horizontalAlign="start"
              verticalAlign="start"
              tokens={{ childrenGap: 10 }}
              styles={dayViewSidebar}>
              <Icon
                style={{ cursor: "pointer" }}
                iconName={isCollapsed ? "ChevronRight" : "ChevronDown"}
                onClick={handleCollapse}
              />
              <span className="MayOverflow">{props.subMenu}</span>
            </Stack>
            {!isCollapsed && (
              <DayMenuDetail
                key={`MealDetail-${props.currentDay}`}
                currentDatePlanData={DataContext.getDayPlanLineData(
                  props.currentDay,
                  props.parentStructureName,
                  props.subMenu
                )}
                currentDate={props.currentDay}
                parentStructureData={props.parentStructureData}
                isMenuRetail={props.isParentRetail}
                structureName={props.parentStructureName}
                submenuName={props.subMenu}
                displayTechnicalName={props.displayTechnicalName}
                isDayAllowed={props.isDayAllowed}
                servingTime={props.parentStructureData?.ServingTimeForm ?? 0}
              />
            )}
          </Stack>
        )
      }
    </DataContext.Consumer>
  );
};
