import { FontWeights, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { weekViewColumn } from "../StylingUtils";

const myStyle: IStackStyles = {
  root: {
    fontSize: 18,
    fontWeight: FontWeights.regular,
  },
};

interface IMonthViewHeaderDay {
  day: string;
}

export const MonthViewHeaderDay: React.FC<IMonthViewHeaderDay> = (props) => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      styles={mergeStyleSets(weekViewColumn, myStyle)}
      tokens={{ padding: 12 }}>
      <span>{props.day}</span>
    </Stack>
  );
};
