import { useId } from "@uifabric/react-hooks";
import { DirectionalHint, FontIcon, Icon, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import React from "react";

import { GuestCountType } from "../Models/IDayPlanData";
import { DynLabel } from "../Services/DynService";
import { babyBlue, darkBlue, pillCommon, primaryBlue, pureBlack, pureWhite } from "../StylingUtils";

interface IPlateCountPill {
  count: number;
  type: GuestCountType;
  isEditable: boolean;
}

const myStyle = {
  root: {
    backgroundColor: pureWhite,
    color: pureBlack,
    borderRadius: "2px",
  },
};

const editStyle = (props: IPlateCountPill) => ({
  root: [
    {
      height: "100%",
      borderTopRightRadius: "2px",
      borderBottomRightRadius: "2px",
    },
    props.type === GuestCountType.ToProduce
      ? { backgroundColor: primaryBlue }
      : props.type === GuestCountType.Estimated
      ? { backgroundColor: babyBlue }
      : { backgroundColor: darkBlue, border: `1px solid ${pureWhite}` },
  ],
});

const getTooltipStringFromType = (type: GuestCountType) => {
  switch (type) {
    case GuestCountType.Estimated:
      return DynLabel("GCTooltipEstimated");
    case GuestCountType.ToProduce:
      return DynLabel("GCTooltipToProduce");
    case GuestCountType.Actual:
      return DynLabel("GCTooltipActual");
    default:
      return "Unknown guest count type";
  }
};

export const GuestCountPill: React.FC<IPlateCountPill> = (props) => {
  const tooltipId = useId("tooltip");

  return (
    <Stack
      horizontal
      styles={mergeStyleSets(pillCommon, myStyle)}
      style={{ cursor: "default" }}
      horizontalAlign="center"
      verticalAlign="stretch"
      tokens={{ childrenGap: 2 }}>
      <Stack horizontal horizontalAlign="stretch" verticalAlign="center" tokens={{ padding: 2 }}>
        <Icon iconName="EatDrink" />
        &nbsp;{props.count > 0 ? props.count : "--"}
      </Stack>
      <TooltipHost
        id={tooltipId}
        content={getTooltipStringFromType(props.type)}
        directionalHint={DirectionalHint.bottomCenter}>
        <Stack
          verticalAlign="center"
          tokens={{ padding: 4 }}
          styles={editStyle(props)}
          style={props.isEditable ? { cursor: "pointer" } : { cursor: "default" }}>
          <FontIcon className="GuestCountEditIcon" iconName={props.isEditable ? "Edit" : "Uneditable2"} />
        </Stack>
      </TooltipHost>
    </Stack>
  );
};
