import { IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { IDayPlanData, isTimeZoneAligned, isValid } from "../Models/IDayPlanData";
import { IDayPlanLineData } from "../Models/IDayPlanLineData";
import { DataContext } from "../Services/DataService";
import { CreateMenuAddRecipeButton } from "../SharedComponents/CreateMenuAddRecipeButton";
import { alignLeftForHorizontalStack, emptyDayRowElementStyle } from "../StylingUtils";
import { range } from "../Utils";
import { DayMenuRecipe } from "./DayMenuRecipe";
import { columnStyles, DayDisplay, indexes, nbDayColumns, orderedIndexes, shouldBeDisplay } from "./DayViewHelper";

interface IDayMenuDetail {
  currentDate: Date;
  currentDatePlanData: Array<IDayPlanLineData>;
  parentStructureData?: IDayPlanData;
  structureName: string;
  submenuName: string;
  serviceLocation?: string;
  serviceStation?: string;
  displayTechnicalName: boolean;
  isDayAllowed: boolean;
  isMenuRetail: boolean;
  servingTime: number;
}

const getEmptyElement = (myStyle: IStackStyles, myKey: string) => {
  return <Stack key={myKey} styles={mergeStyleSets(emptyDayRowElementStyle, myStyle)} />;
};

export const DayMenuDetail: React.FC<IDayMenuDetail> = (props) => {
  const dataContextValue = useContext(DataContext);

  const parentValid = isValid(props.parentStructureData);
  const parentConfirmed = parentValid && Boolean(props.parentStructureData!.is_confirmed);

  const servingTimePassed = new Date() > new Date(props.currentDate.getTime() + props.servingTime);

  const serviceStation = props.currentDatePlanData?.length > 0 && props.currentDatePlanData[0].ServiceStation;
  const serviceLocation = props.parentStructureData?.serviceLocation;
  const buttonForRecipeColumns = (
    <Stack
      horizontal
      styles={mergeStyleSets(emptyDayRowElementStyle, columnStyles[orderedIndexes[0]], alignLeftForHorizontalStack)}
      key={`CreateMenuAddRecipeButton-${props.structureName}-${props.submenuName}`}>
      <CreateMenuAddRecipeButton
        menuDate={props.currentDate}
        parentStructure={props.parentStructureData}
        structureName={props.structureName}
        subMenuName={props.submenuName}
        serviceLocation={serviceLocation || ""}
        serviceStation={serviceStation || ""}
        disabled={parentConfirmed || new Date() > new Date(props.currentDate.getTime() + props.servingTime) || !isTimeZoneAligned(props.parentStructureData)}//{false}// TODO: Hard coded value to enable button //{parentConfirmed} {new Date() > new Date(props.serviceTime)}
        styling={{ root: { padding: "0px 6px" } }}
      />
    </Stack>
  );

  return (
    dataContextValue && (
      <Stack grow>
        {props.isDayAllowed && (
          <Stack horizontal style={{ width: "100%" }}>
            {range(0, nbDayColumns - 1).map((i) => {
              const currentIdx = orderedIndexes[i];

              const currentEl =
                currentIdx === indexes.idxRecipe
                  ? buttonForRecipeColumns
                  : getEmptyElement(columnStyles[currentIdx], `${props.structureName}-${props.submenuName}-${i}`);

              switch (shouldBeDisplay[currentIdx]) {
                case DayDisplay.Both:
                  return currentEl;

                case DayDisplay.Retail:
                  return props.isMenuRetail ? currentEl : null;

                case DayDisplay.Standard:
                  return props.isMenuRetail ? null : currentEl;

                case DayDisplay.None:
                  return null;

                default:
                  return null;
              }
            })}
          </Stack>
        )}

        {props.parentStructureData &&
          props.currentDatePlanData.map((recipe: IDayPlanLineData, i: number) => {
            const moreRecipeData = dataContextValue.getDayViewRecipeData(recipe.recipe_bom_recid);
            return (
              <DayMenuRecipe
                key={recipe.recid}
                {...recipe}
                {...moreRecipeData}
                displayTechnicalName={props.displayTechnicalName}
                currentDate={props.currentDate}
                isMenuConfirmed={parentConfirmed}
                isDayAllowed={props.isDayAllowed}
                isMenuRetail={props.isMenuRetail}
                isSalesMixConfirmed={Boolean(props.parentStructureData!.is_sales_mix_confirmed)}
                dayPlanGuestCountType={props.parentStructureData!.guest_count_type}
                isRecipeProduced={recipe.production_id !== ""}
                servingTimePassed={servingTimePassed}
                disabled={!isTimeZoneAligned(props.parentStructureData)}
              />
            );
          })}
      </Stack>
    )
  );
};
