import { KeyedCollection } from '../KeyedCollection';
import { IDayPlanLineData } from '../Models/IDayPlanLineData';
import { IDayPlanLineIdxData } from '../Models/IDayPlanLineIdxData';
import { getJsDateFromAXJson } from '../Utils';
import { IAXMenuPlanData, IAXMenuStructureData } from './IDataFromAx';
import { IDayPlanData } from './IDayPlanData';
import { IRecipeData } from './IRecipeData';

export interface IMenuPlannerDataModel {
  // GetMenuPlans: (date: Date) => Array<IAXMenuPlanData>;
  GetMenuStructureData: (dateOfData: Date, structureName: string, serviceLocation?: string) => IDayPlanData | undefined;
  GetMenuStructureDataById: (menuStructureId: number) => IDayPlanData | undefined;
  GetDayRecipesData: (dateOfData: Date, structureName: string, subMenuName?: string) => Array<IDayPlanLineData>;
  GetAllRecipeIds: (dateOfData: Date, structureName: string) => Array<number>;
  GetRecipeIdIndexData: (dayPlanLineRecid: number) => IDayPlanLineIdxData | null;
  GetBOMRecipeData: (recipeBomRecid: number) => IRecipeData | undefined;
}

export class MenuPlannerDataModel implements IMenuPlannerDataModel {
  // KeyedCollection are preferred to Map objects here
  // It forces keys to be string but they're deemed to be more efficient than Map for the retrieval of values
  // If we were doing frequent additions/deletions to the collections, this choice may be different

  private _menuStructInitialData: KeyedCollection<IDayPlanData> = new KeyedCollection<IDayPlanData>();
  private _menuStructKeyById: KeyedCollection<string> = new KeyedCollection<string>();

  private _subMenuNamesByStructure: KeyedCollection<Array<string>> = new KeyedCollection<Array<string>>();
  private _subMenuData: KeyedCollection<Array<number>> = new KeyedCollection<Array<number>>();

  private _dayPlanLineInitialData: KeyedCollection<IDayPlanLineData> = new KeyedCollection<IDayPlanLineData>();
  private _dayPlanLineMenuStructIdx = new Map<number, IDayPlanLineIdxData>();

  private _recipeDataByBomRecId: KeyedCollection<IRecipeData> = new KeyedCollection<IRecipeData>();

  _constructDayMenuStructureDataKey = (dateTime: string, menuStructureName: string) => {
    return `${dateTime}${menuStructureName}`;
  };

  _constructSubMenuDataKey = (dateTime: string, menuStructureName: string, subMenuName: string) => {
    return `${dateTime}${menuStructureName}${subMenuName}`;
  };

  constructor(
    menuLayout: Array<IAXMenuStructureData>,
    menuPlansData: Array<IAXMenuPlanData>,
    recipesData: Array<IRecipeData>
  ) {
    // Using the layout to construct submenu by structure, ensuring all submenus are here (even the empty one)
    menuLayout.forEach((structure) => {
      const arrayOfSubMenuNames = structure.submenus.map((submenu) => {
        return submenu.submenu;
      });
      this._subMenuNamesByStructure.AddOrReplace(structure.menu_structure, arrayOfSubMenuNames);
    });

    // Construct menu plans data model
    menuPlansData.forEach((menuPlan) => {
      const menuPlanDate = getJsDateFromAXJson(menuPlan.date);
      menuPlanDate!.setUTCHours(0, 0, 0, 0); //ensuring that we do not take hours into account
      const myDateKey = menuPlanDate!.getTime().toString();

      menuPlan.menu_structures.forEach((menuStructure) => {
        const dayMenuStructureKey = this._constructDayMenuStructureDataKey(myDateKey, menuStructure.menu_structure);

        this._menuStructInitialData.AddOrReplace(dayMenuStructureKey, menuStructure.dayplan_data);

        // dayplan_data can be null, and recid 0 means no day plan
        const dayPlanRecid = menuStructure.dayplan_data?.recid || 0;
        if (dayPlanRecid) this._menuStructKeyById.AddOrReplace(dayPlanRecid.toString(), dayMenuStructureKey);

        // Now taking care of submenus of this structure
        menuStructure.submenus.forEach((submenu) => {
          const mySubMenuDataKey = this._constructSubMenuDataKey(
            myDateKey,
            menuStructure.menu_structure,
            submenu.submenu
          );

          if (!this._subMenuData.ContainsKey(mySubMenuDataKey)) this._subMenuData.AddOrReplace(mySubMenuDataKey, []);

          const currentIds = this._subMenuData.GetValue(mySubMenuDataKey);

          submenu.dayplan_line_data.forEach((dayplanlinedata) => {
            //Storing recipe ids of submenus in intermediate collection
            currentIds.push(dayplanlinedata.recid);

            //Storing day plan line data
            this._dayPlanLineInitialData.AddOrReplace(dayplanlinedata.recid.toString(), dayplanlinedata);

            // index the day plan line recid
            const idxEntry: IDayPlanLineIdxData = {
              dayPlanDate: menuPlanDate,
              dayPlan: menuStructure.dayplan_data,
              dayPlanLine: dayplanlinedata,
            };
            this._dayPlanLineMenuStructIdx.set(dayplanlinedata.recid, idxEntry);
          });
        });
      });
    });

    // Construct recipe data model
    recipesData.forEach((bomRecipe) => {
      this._recipeDataByBomRecId.AddOrReplace(bomRecipe.recipe_bom_recid.toString(), bomRecipe);
    });
  }

  public GetMenuStructureData(dateOfData: Date, structureName: string, serviceLocation?: string): IDayPlanData | undefined {
    const menuStructKey = this._constructDayMenuStructureDataKey(
      dateOfData.setUTCHours(0, 0, 0, 0).toString(),
      structureName
    );

    if (this._menuStructInitialData.ContainsKey(menuStructKey)) {
      const data = this._menuStructInitialData.GetValue(menuStructKey);
      if (serviceLocation && data) {
        return data.serviceLocation === serviceLocation ? data : undefined;
      }
      return data;
    }

    return undefined;
  }

  public GetMenuStructureDataById(menuStructureId: number): IDayPlanData {
    const key = this._menuStructKeyById.GetValue(menuStructureId.toString());
    return this._menuStructInitialData.GetValue(key);
  }

  public GetDayRecipesData(dateOfData: Date, structureName: string, subMenuName?: string): IDayPlanLineData[] {
    if (subMenuName !== undefined) {
      const subMenuKey = this._constructSubMenuDataKey(
        dateOfData.setUTCHours(0, 0, 0, 0).toString(),
        structureName,
        subMenuName
      );

      if (this._subMenuData.ContainsKey(subMenuKey)) {
        const lineIds = this._subMenuData.GetValue(subMenuKey);

        return lineIds.map((lineId) => {
          const dayPlanLineKey = lineId.toString();
          return this._dayPlanLineInitialData.GetValue(dayPlanLineKey);
        });
      }
    } else {
      return this._subMenuNamesByStructure
        .GetValue(structureName)
        .flatMap((submenu) => this.GetDayRecipesData(dateOfData, structureName, submenu));
    }

    return [];
  }

  public GetAllRecipeIds(dateOfData: Date, structureName: string) {
    const dateKey = dateOfData.setUTCHours(0, 0, 0, 0).toString();

    const subMenuKeys: Array<string> = this._subMenuNamesByStructure.GetValue(structureName).map((submenuName) => {
      return this._constructSubMenuDataKey(dateKey, structureName, submenuName);
    });

    const result = subMenuKeys.flatMap((subKey) => {
      if (this._subMenuData.ContainsKey(subKey)) {
        return this._subMenuData.GetValue(subKey);
      }
      return [];
    });

    return result;
  }

  public GetRecipeIdIndexData(dayPlanLineRecid: number) {
    return this._dayPlanLineMenuStructIdx.get(dayPlanLineRecid) ?? null;
  }

  public GetBOMRecipeData(recipeBomRecid: number) {
    return this._recipeDataByBomRecId.GetValue(recipeBomRecid.toString());
  }
}
