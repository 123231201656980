import { Icon, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { DataContext } from "../Services/DataService";
import { darkBlue, pureWhite, serviceStationHeaderStyle, weekViewSidebar } from "../StylingUtils";
import { WeekMenuStructureHeaderDay } from "./WeekMenuStructureHeaderDay";
import { WeekViewContext } from "./WeekView";
import { getISODate } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    displayName: "WeekMealHeader",
    height: "auto",
    backgroundColor: darkBlue,
    color: pureWhite,
    selectors: {
      ":hover": {
        backgroundColor: "#2b3a58",
      },
    },
  },
};

interface IWeekMenuStructureHeader {
  menuStructureName: string;
  setupDays: Array<number>;
  isMenuRetail: boolean;
  isCollapsed: boolean;
  onCollaspeClick: () => void;
  overrideData?: any[];
  menuNameDisplayOverride?: string;
  isServiceStation?: boolean;
  // onCheckboxChange: (state: boolean) => void;
}

export const WeekMenuStructureHeader: React.FC<IWeekMenuStructureHeader> = (props) => {
  const dataContextValue = useContext(DataContext);
  const weekViewContextValue = useContext(WeekViewContext);

  let headerColor = myStyle;
  if (props.isServiceStation) {
    headerColor = mergeStyleSets(myStyle, serviceStationHeaderStyle);
  }

  return (
    dataContextValue &&
    weekViewContextValue && (
      <Stack horizontal horizontalAlign="stretch" verticalFill styles={headerColor}>
        <Stack
          horizontal
          horizontalAlign="start"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          styles={weekViewSidebar}>
          <Icon
            style={{ cursor: "pointer" }}
            iconName={props.isCollapsed ? "ChevronRight" : "ChevronDown"}
            onClick={() => {
              props.onCollaspeClick();
            }}
          />
          <span className="MayOverflow">{props.menuNameDisplayOverride ?? props.menuStructureName}</span>
        </Stack>
        {weekViewContextValue.weekDates.map((d, i) => (
          <WeekMenuStructureHeaderDay
            isServiceStation={props.isServiceStation ?? false}
            key={`${d.valueOf()}-structureHeaderDay`}
            headerData={props.overrideData && props.overrideData[d.getTime()] ? props.overrideData[d.getTime()] : dataContextValue.getMenuStructureData(d, props.menuStructureName)}
            inPeriod={weekViewContextValue.inPeriodBools[i]}
            isDayAllowed={Boolean(props.setupDays[d.getUTCDay()])}
            isMenuRetail={props.isMenuRetail}
            isRollUp={props.menuNameDisplayOverride !== undefined}
            onCheckboxChange={(state) => { weekViewContextValue.updateSelected(`${props.menuNameDisplayOverride ?? props.menuStructureName}:${getISODate(d)}`, state) }}
          />
        ))}
      </Stack>
    )
  );
};
