import { FontWeights, ICheckboxStyles, IIconStyles, IStackStyles, mergeStyleSets } from "@fluentui/react";

/************************************************/
/*               Styling Utils                  */
/************************************************/

export const lightestGrey: string = "#f7f7f7";
export const mediumGrey: string = "#bbbbbb";
export const recipeGrey: string = "#f0f0f0";
export const darkestGrey: string = "#333333";
export const darkerGrey: string = "#4c4c4c";
export const headerSeparatorGrey: string = "#cdcdcd";

export const pureWhite: string = "#ffffff";
export const pureBlack: string = "#000000";

export const primaryBlue: string = "#006ec4";
export const strongBlue: string = "#005aa3";
export const coolBlue: string = "#0077db";
export const darkBlue: string = "#001337";
export const spanishBlue: string = "#0070BB";
export const babyBlue: string = "#89CFF0";
export const resolutionBlue: string = "#002387";

export const lightRed: string = "#ffcccb";
export const darkerRed: string = "#ff999a";
export const translucidOrange: string = "#d0534087";
export const superTranslucidOrange: string = "#d0534008";
export const orangeGrey: string = "#e4d7d14d";

export const right: string = "#8ac618";
export const wrong: string = "#d83b01";

const commonSidebar: IStackStyles = {
  root: {
    displayName: "Sidebar",
    padding: 10,
    borderRight: `1px solid ${mediumGrey}`,
    fontSize: 16,
    fontWeight: FontWeights.bold,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 161,
    minWidth: 161,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    selectors: {
      ":hover": {
        flexGrow: 1,
        whiteSpace: "normal",
        maxWidth: "fit-content",
      },
    },
  },
};

export const monthViewSidebar: IStackStyles = mergeStyleSets(commonSidebar, {
  root: {
    displayName: "MonthViewSideBar",
    borderBottom: `1px solid ${headerSeparatorGrey}`,
    padding: 5,
  },
});

export const serviceStationHeaderStyle: IStackStyles = {
  root: {
    backgroundColor: "#2b3a58",
  }
};


export const weekViewSidebar: IStackStyles = mergeStyleSets(commonSidebar, {
  root: {
    displayName: "WeekViewSideBar",
  },
});
export const dayViewSidebar: IStackStyles = mergeStyleSets(commonSidebar, {
  root: {
    displayName: "DayViewSideBar",
  },
});

export const commonViewColumn: IStackStyles = {
  root: {
    displayName: "Column",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    borderRight: `1px solid ${mediumGrey}`,
    selectors: {
      ":last-child": {
        borderRight: "none",
      },
    },
  },
};

export const weekViewColumn: IStackStyles = mergeStyleSets(commonViewColumn, {
  root: {
    displayName: "WeekViewColumn",
    minWidth: 125,
  },
});

export const pillCommon: IStackStyles = {
  root: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: 1.33,
    minHeight: "19px",
    flexGrow: 0,
  },
};

export const recipePillCommon: IStackStyles = {
  root: {
    minHeight: "20px",
    borderRadius: "2px",
    fontSize: "10px",
    backgroundColor: pureWhite,
    border: `1px solid rgba(0, 0, 0, 0.1)`,
  },
};

export const pillIcon: IIconStyles = {
  root: {
    padding: "2px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
    fontSize: "12px",
    backgroundColor: pureWhite,
  },
};

export const checkboxStyle: ICheckboxStyles = {
  checkbox: {
    width: "14px",
    height: "14px",
    margin: 2,
  },
};

export const dayRowElementStyle: IStackStyles = {
  root: {
    displayName: "dayRowElementStyle",
    padding: 10,
    backgroundColor: lightestGrey,
    borderRight: `1px solid ${mediumGrey}`,
    borderBottom: `2px solid ${pureWhite}`,
    justifyContent: "center",
    alignItems: "center",
  },
};

export const emptyDayRowElementStyle: IStackStyles = mergeStyleSets(dayRowElementStyle, {
  root: {
    displayName: "emptyDayRowElementStyle",
    padding: 0,
    backgroundColor: pureWhite,
    borderBottom: "none",
  },
});

export const almostFixedWidth = (widthValue: string): IStackStyles => {
  return {
    root: {
      flexGrow: 0,
      flexBasis: widthValue,
      maxWidth: widthValue,
    },
  };
};

export const takingRemainingSpace = (baseWidth: string): IStackStyles => {
  return {
    root: {
      flexGrow: 1,
      flexBasis: baseWidth,
    },
  };
};

export const headerBackgroundConfirmed: IStackStyles = {
  root: {
    backgroundColor: resolutionBlue,
  },
};

export const headerForbiddenDay: IStackStyles = {
  root: {
    backgroundColor: translucidOrange,
  },
};

export const dayHeaderColumnTitle: IStackStyles = {
  root: {
    displayName: "DayHeaderColumnTitle",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    textAlign: "center",
    padding: 10,
    fontSize: 16,
    selectors: {
      ":last-child": {
        borderRight: `1px solid ${mediumGrey}`,
      },
    },
  },
};

export const monthDayLabelStyle: React.CSSProperties = {
  fontWeight: "normal",
  fontSize: 10,
  color: mediumGrey,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const halfSizeItem: IStackStyles = {
  root: {
    flex: "1 1 auto",
    maxWidth: "50%",
  },
};

export const overflowEllipsisButHoverDont: IStackStyles = {
  root: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    selectors: {
      ":hover": {
        whiteSpace: "normal",
        textOverflow: "clip",
      },
    },
  },
};

export const alignLeftForHorizontalStack = { root: { justifyContent: "flex-start" } } as IStackStyles;

export const footerDayStyle: IStackStyles = {
  root: {
    displayName: "FooterDay",
    padding: "10px 5px",
  },
};
