import {
  DirectionalHint,
  FontIcon,
  ICalloutProps,
  IStackStyles,
  ITooltipHostStyles,
  mergeStyleSets,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import React from "react";

import { ISelectionTotalsData } from "../Models/ISelectionTotalsData";
import { DynLabel } from "../Services/DynService";
import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { mediumGrey, pillCommon, pureBlack, pureWhite, right, wrong } from "../StylingUtils";

interface ISelectionTotalsPill {
  totals: ISelectionTotalsData;
}

const myStyle: IStackStyles = {
  root: {
    backgroundColor: pureWhite,
    color: pureBlack,
    borderRadius: "2px",
    cursor: "default",
  },
};

const tooltipHostAsFlexbox: ITooltipHostStyles = {
  root: {
    displayName: "TooltipHostFlex",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};

const tooltipCalloutProps: ICalloutProps = {
  directionalHint: DirectionalHint.bottomCenter,
  isBeakVisible: false,
  gapSpace: 2,
};

const costStyle = (props: ISelectionTotalsPill) => ({
  root: [
    props.totals.sumPlannedCost <= props.totals.sumTargetCost
      ? {
          color: right,
        }
      : props.totals.sumPlannedCost > props.totals.sumTargetCost
      ? {
          color: wrong,
        }
      : {},
  ],
});

const positionStyle = (position: number) =>
  ({
    root: [
      position < 0
        ? {
            borderTopLeftRadius: "2px",
            borderBottomLeftRadius: "2px",
            paddingLeft: "4px",
            borderRight: `1px solid ${mediumGrey}`,
          }
        : position > 0
        ? {
            borderTopRightRadius: "2px",
            borderBottomRightRadius: "2px",
            paddingRight: "4px",
          }
        : {},
    ],
  } as IStackStyles);

export const SelectionTotalsPill: React.FC<ISelectionTotalsPill> = (props) => {
  return (
    <Stack
      horizontal
      styles={mergeStyleSets(pillCommon, myStyle)}
      horizontalAlign="center"
      verticalAlign="stretch"
      tokens={{ childrenGap: 2 }}>
      <Stack horizontal verticalAlign="center" tokens={{ padding: 4 }} styles={positionStyle(-1)}>
        <FontIcon iconName="CheckboxComposite" />
        &nbsp;{props.totals.selectedRecipeCount || "--"}
      </Stack>
      <TooltipHost
        content={DynLabel("FooterSumsTooltip")}
        calloutProps={tooltipCalloutProps}
        styles={tooltipHostAsFlexbox}>
        <Stack horizontal verticalAlign="center" styles={mergeStyleSets(costStyle(props), positionStyle(0))}>
          &Sigma;&nbsp;
          {props.totals.selectedRecipeCount > 0
            ? formatCurrencyNumber(props.totals.currency, props.totals.sumPlannedCost)
            : "--"}
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ padding: 2 }} styles={mergeStyleSets(positionStyle(1))}>
          {` | ${
            props.totals.selectedRecipeCount > 0
              ? formatCurrencyNumber(props.totals.currency, props.totals.sumTargetCost)
              : "--"
          }`}
        </Stack>
      </TooltipHost>
    </Stack>
  );
};

export default SelectionTotalsPill;
