import { FontIcon, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { DynLabel } from "../Services/DynService";

interface IMonthWeekMargin {
  plannedMargin: number;
  label?: string;
  styling?: IStackStyles;
  labelStyle?: React.CSSProperties;
}

export const MonthWeekMargin: React.FC<IMonthWeekMargin> = (props) => {
  return (
    <Stack horizontalAlign="start" verticalAlign="start" tokens={{ childrenGap: 1 }} styles={props.styling}>
      <span style={props.labelStyle}>{props.label || DynLabel("PlannedMargin")}</span>
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <FontIcon iconName="CalculatorPercentage" />
        <span>{props.plannedMargin === 0 ? "--" : props.plannedMargin.toFixed(2)}</span>
      </Stack>
    </Stack>
  );
};
