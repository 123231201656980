import { Checkbox, ICheckboxStyles, IStackStyles, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GuestCountType } from "../Models/IDayPlanData";
import { PeriodContext } from "../Services/PeriodContextService";
import { DataContext } from "../Services/DataService";
import { updatePlannedQuantity, updatePortionFactor, updateSalesMix } from "../Services/UpdateService";
import { validatePlannedQuantity, validatePortionFactor, validateSalesMix } from "../Services/ValidationService";
import { EditableField } from "../SharedComponents/EditableField";
import { RecipeActionsButton } from "../SharedComponents/RecipeActionsButton";
import { RecipeBudgetPill } from "../SharedComponents/RecipeBudgetPill";
import { RecipePill } from "../SharedComponents/RecipePill";
import { RecipeTitle } from "../SharedComponents/RecipeTitle";
import { WarningIcon } from "../SharedComponents/WarningIcon";
import { coolBlue, pureWhite, recipeGrey } from "../StylingUtils";
import { WeekSelectionContext, WeekViewContext } from "./WeekView";

const myStyle = (selected: boolean): IStackStyles => {
  return {
    root: [
      {
        displayName: "WeekRecipe",
        backgroundColor: recipeGrey,
        minHeight: "44px",
        height: "auto",
        margin: "2px",
        padding: "2px",
      },
      selected ? { border: `1px solid ${coolBlue}` } : { border: `1px solid ${pureWhite}` },
    ],
  };
};

const checkboxStyle: ICheckboxStyles = {
  checkbox: {
    width: "14px",
    height: "14px",
    margin: 2,
  },
};

const recipePillsStackStyle: IStackStyles = {
  root: {
    displayName: "RecipePills",
    width: "auto",
  },
};

interface IMenuRecipe {
  recipeId: number;
  recipeName: string;
  portionFactor: number;
  plannedCost: number;
  targetCost: number;
  plannedQuantity: number;
  currency: string;
  isMenuConfirmed: boolean;
  isDayAllowed: boolean;
  isMenuRetail: boolean;
  salesMix: number;
  isSalesMixConfirmed: boolean;
  recipeWarnings?: Array<string>;
  dayPlanGuestCountType: GuestCountType;
  isRecipeProduced: boolean;
  parentChecked: boolean;
  disabled?: boolean;
}

export const WeekRecipe: React.FC<IMenuRecipe> = (props) => {
  const weekViewContextValue = useContext(WeekViewContext);
  const dataContextValue = useContext(DataContext);
  const selectionCtxtValue = useContext(WeekSelectionContext);
  const currentPeriodContext = useContext(PeriodContext);
  const [isPlannedQtyModified, setPlannedQtyModified] = useState(false);
  const [isSalesMixModified, setSalesMixModified] = useState(false);
  const [isPortionFactorModified, setPortionFactorModified] = useState(false);

  const checkboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cb = checkboxRef.current?.querySelector("input");
    const cbChecked = cb?.checked;
    if(cbChecked !== props.parentChecked) {
      cb?.click();
    }
  }, [props.parentChecked]);

  const handleRecipeSelection = (checked: boolean | undefined) => {
    if (selectionCtxtValue) {
      checked
        ? selectionCtxtValue.dispatchSelectionEvent({ type: "add", ids: [props.recipeId] })
        : selectionCtxtValue.dispatchSelectionEvent({ type: "remove", ids: [props.recipeId] });
    }
  };

  // Using first in-period day of displayed week
  const preFillSwapDateMin =
    weekViewContextValue!.weekDates[
    weekViewContextValue!.inPeriodBools.findIndex((val) => {
      return val === true;
    })
    ];

  // Using last in-period day of displayed week
  const preFillSwapDateMax = weekViewContextValue!.weekDates[weekViewContextValue!.inPeriodBools.lastIndexOf(true)];

  return (
    weekViewContextValue &&
    selectionCtxtValue && (
      <Stack
        verticalFill
        styles={myStyle(selectionCtxtValue.selectedElements.includes(props.recipeId))}
        tokens={{ childrenGap: "5px" }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: "2px" }}>
          <Stack.Item align="start">
            <Checkbox
            ref={checkboxRef}
              styles={checkboxStyle}
              onChange={(ev?, checked?: boolean) => {
                handleRecipeSelection(checked);
              }}
            />
          </Stack.Item>
          <Stack.Item align="stretch" grow styles={{ root: { overflow: "hidden" } }}>
            <Stack horizontalAlign="start">
              <RecipeTitle recipeId={props.recipeId} recipeName={props.recipeName} displayStyle={"week"} />
            </Stack>
          </Stack.Item>
          <Stack.Item align="end" disableShrink styles={{ root: { fontSize: 12 } }}>
            <WarningIcon warnings={props.recipeWarnings} cssClasses={"WarningRecipe"} />
            <RecipeActionsButton
              key={`${props.recipeId}-MultiActionsButton`}
              recipeId={props.recipeId}
              isMenuConfirmed={props.isMenuConfirmed}
              isDayAllowed={props.isDayAllowed}
              periodMin={preFillSwapDateMin}
              periodMax={preFillSwapDateMax}
              dayPlanGuestCountType={props.dayPlanGuestCountType}
              isRecipeProduced={props.isRecipeProduced}
              servingTimePassed={false} // not applicable at the week level
              disabled={props.disabled}
            />
          </Stack.Item>
        </Stack>

        <Stack.Item>
          <Stack
            horizontal
            horizontalAlign="start"
            verticalAlign="center"
            wrap
            tokens={{ childrenGap: "2px" }}
            styles={recipePillsStackStyle}>
            <EditableField
              key={`${props.recipeId}-EditablePF`}
              isDisabled={
                props.isMenuRetail
                  ? !currentPeriodContext!.getSalesMixEditable() ||
                  !props.isDayAllowed ||
                  (props.isMenuRetail &&
                    (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
                    !weekViewContextValue.isUserManager)
                  : !currentPeriodContext!.getPortionFactorEditable() ||
                  !props.isDayAllowed ||
                  (props.isMenuRetail &&
                    (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
                    !weekViewContextValue.isUserManager)
                // TODO: Hard coded value to enable button // {new Date() > new Date(props.serviceTime)}
              }
              valueToEdit={props.isMenuRetail ? props.salesMix : props.portionFactor}
              component={
                <RecipePill
                  value={Math.round(props.isMenuRetail ? props.salesMix : props.portionFactor)}
                  iconName={props.isMenuRetail ? "PieSingleSolid" : "PieSingle"}
                  isPercentage
                />
              }
              updateValue={(value) => {
                if (props.isMenuRetail) {
                  setSalesMixModified(true);
                  updateSalesMix(
                    dataContextValue!,
                    props.recipeId,
                    value,
                    (_) => {
                      setSalesMixModified(false);
                    },
                    (_) => {
                      setSalesMixModified(false);
                    }
                  );
                } else {
                  setPortionFactorModified(true);
                  updatePortionFactor(
                    dataContextValue!,
                    props.recipeId,
                    value,
                    (_) => {
                      setPortionFactorModified(false);
                    },
                    (_) => {
                      setPortionFactorModified(false);
                    }
                  );
                }
              }}
              displayEditIcon={true}
              validateValue={props.isMenuRetail ? validateSalesMix : validatePortionFactor}
              id={"PF" + props.recipeId}
              editIconClassname="EditIconRecipe"
              modifiedState={props.isMenuRetail ? isSalesMixModified : isPortionFactorModified}
            />

            <EditableField
              key={`${props.recipeId}-EditablePQ`}
              isDisabled={
                props.isMenuRetail
                  ? !props.isDayAllowed ||
                  (props.isMenuRetail &&
                    (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
                    !weekViewContextValue.isUserManager)
                  : !currentPeriodContext!.getPlannedQuantityEditable() ||
                  !props.isDayAllowed ||
                  (props.isMenuRetail &&
                    (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
                    !weekViewContextValue.isUserManager)
              }
              valueToEdit={props.plannedQuantity}
              component={
                <RecipePill value={props.plannedQuantity} iconName="EmojiTabFoodPlants" isPercentage={false} />
              }
              updateValue={(value) => {
                setPlannedQtyModified(true);
                updatePlannedQuantity(
                  dataContextValue!,
                  props.recipeId,
                  value,
                  (_) => {
                    setPlannedQtyModified(false);
                  },
                  (_) => {
                    setPlannedQtyModified(false);
                  }
                );
              }}
              displayEditIcon={true}
              modifiedState={isPlannedQtyModified}
              validateValue={validatePlannedQuantity}
              id={"plannedQty" + props.recipeId}
              editIconClassname="EditIconRecipe"
            />

            {(!props.isMenuRetail || props.isMenuRetail) && (
              <RecipeBudgetPill
                key={`${props.recipeId}-BudgetPill`}
                cost={props.plannedCost}
                currency={props.currency}
                styling={{ root: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" } }}
              />
            )}
          </Stack>
        </Stack.Item>
      </Stack>
    )
  );
};
