import { IStackStyles } from "@fluentui/react";
import React from "react";

import { DynLabel } from "../Services/DynService";
import { IAvaMenuPlannerTooltip } from "./AvaMenuPlannerTooltip";
import { Pill } from "./Pill";

interface IMarginPill {
  plannedMargin: number;
  targetMargin: number;
  isWrong: boolean;
  styling?: IStackStyles;
}

export const MarginPill: React.FC<IMarginPill> = (props) => {
  const tooltipProps: IAvaMenuPlannerTooltip = {
    title: DynLabel("PlannedMarginPillInfoTitle"),
    text: DynLabel("PlannedMarginPillInfo"),
  };

  return (
    <Pill
      referenceValue={`${props.targetMargin.toFixed(2)}%`}
      comparisonValue={`${props.plannedMargin.toFixed(2)}%`}
      isWrong={props.isWrong}
      emptyValue={"0%"}
      tooltipProps={tooltipProps}
      styling={props.styling}
    />
  );
};
