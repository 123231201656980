import { Checkbox, DirectionalHint, FontIcon, ICheckboxStyles, IStackStyles, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import { getNextGuestCountType, GuestCountType, IDayPlanData, isTimeZoneAligned, isValid } from "../Models/IDayPlanData";
import { DataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import { updateGuestCount } from "../Services/UpdateService";
import { validateGuestCount } from "../Services/ValidationService";
import { BudgetPill } from "../SharedComponents/BudgetPill";
import { EditableField } from "../SharedComponents/EditableField";
import { GuestCountPill } from "../SharedComponents/GuestCountPill";
import { MenuStructureActionsButton } from "../SharedComponents/MenuStructureActionsButton";
import { WarningIcon } from "../SharedComponents/WarningIcon";
import { imitateAXStrFmt } from "../StringUtils";
import { checkboxStyle, headerBackgroundConfirmed, headerForbiddenDay, mediumGrey, pureWhite, weekViewColumn } from "../StylingUtils";
import { WeekViewContext } from "./WeekView";
import { getISODate } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    displayName: "WeekMenuStructureHeaderDay",
    padding: 5,
    height: "auto",
  },
};

const backgroundCheckbox: ICheckboxStyles = {
  checkbox: {
    // backgroundColor: pureWhite,
    borderColor: mediumGrey,
  },
};

interface IWeekMenuStructureHeaderDay {
  headerData?: IDayPlanData;
  inPeriod: boolean;
  isDayAllowed: boolean;
  isMenuRetail: boolean;
  isRollUp: boolean;
  isServiceStation: boolean;
  onCheckboxChange: (state: boolean) => void;
}

export const WeekMenuStructureHeaderDay: React.FC<IWeekMenuStructureHeaderDay> = (props) => {
  let weekViewContextValue = useContext(WeekViewContext);
  let dataContextValue = useContext(DataContext);
  const checkboxRef = useRef<HTMLDivElement>(null);

  // Using first in-period day of displayed week
  const preFillConfirmDateMin =
    weekViewContextValue!.weekDates[
    weekViewContextValue!.inPeriodBools.findIndex((val) => {
      return val === true;
    })
    ];

  // Using last in-period day of displayed week
  const preFillConfirmDateMax = weekViewContextValue!.weekDates[weekViewContextValue!.inPeriodBools.lastIndexOf(true)];

  const dataValid = isValid(props.headerData);
  const dataConfirmed = dataValid && Boolean(props.headerData!.is_confirmed);

  let headerColor = null;
  if (dataValid && !props.isDayAllowed) {
    headerColor = headerForbiddenDay;
  } else if (dataConfirmed) {
    headerColor = headerBackgroundConfirmed;
  }

  useEffect(() => {
    if (props.headerData && props.headerData.dayplan_date) {
      const date = new Date(parseInt(props.headerData.dayplan_date.slice(6)));
      console.log("date", props.headerData.dayplan_date.slice(6));
      const dayPartKey = `${props.headerData.menu_structure}:${getISODate(date)}`;
      const cb = checkboxRef.current?.querySelector("input");
      const selected = weekViewContextValue?.selectedDays.includes(dayPartKey)
      if (cb && cb.checked !== selected) {
        cb.click();
      }
    }
  }, [weekViewContextValue]);

  return (
    <Stack
      horizontal
      verticalFill
      horizontalAlign="start"
      verticalAlign="center"
      tokens={{ childrenGap: 3 }}
      styles={mergeStyleSets(weekViewColumn, myStyle, headerColor)}>
      <Stack.Item grow styles={{ root: { height: "100%" } }}>
        <Stack
          horizontal
          horizontalAlign="space-evenly"
          verticalAlign="center"
          wrap
          tokens={{ childrenGap: 3 }}
          styles={{ root: { height: "100%" } }}>
          {props.inPeriod && dataValid && (
            <Checkbox styles={mergeStyleSets(backgroundCheckbox, checkboxStyle)}
              ref={checkboxRef}
              onChange={(_ev, checked) => {
                // setCheckboxState(checked!);
                props.onCheckboxChange(checked!);
              }}
            // defaultChecked={checkboxState}
            />
          )}
          {props.inPeriod && dataValid && (
            <EditableField
              key={`${props.headerData!.recid}-EditableGuestCountPill`}
              isDisabled={!props.isDayAllowed || props.isRollUp} // TODO: Hard coded value to enable button // {new Date() > new Date(props.
              valueToEdit={props.headerData!.guest_count_number}
              component={
                <GuestCountPill
                  count={props.headerData!.guest_count_number}
                  type={props.headerData!.guest_count_type}
                  isEditable={props.isDayAllowed && !props.isRollUp && isTimeZoneAligned(props.headerData)}
                />
              }
              updateValue={(newGCValue, changeType) => {
                updateGuestCount(
                  dataContextValue!,
                  props.headerData!.recid,
                  newGCValue,
                  props.headerData!.guest_count_type,
                  changeType
                );
              }}
              displayEditIcon={false}
              validateValue={validateGuestCount}
              id={"guestCount" + props.headerData!.recid}
              withCheckbox={props.headerData!.guest_count_type !== GuestCountType.Actual}
              checkboxLabel={imitateAXStrFmt(
                DynLabel("GCUpdateLabel"),
                getNextGuestCountType(props.headerData!.guest_count_type)?.toString() ?? ""
              )}
            />
          )}

          {props.inPeriod &&
            dataValid &&
            (props.isMenuRetail ? (
              <BudgetPill
                key={`${props.headerData!.recid}-BudgetPill`}
                budget={props.headerData!.Target_Day_Avg_Trans_Total}
                actualCost={props.headerData!.Planned_Day_Avg_Trans_Total}
                currency={props.headerData!.planned_cost_currency}
              />
            ) : (
              <BudgetPill
                key={`${props.headerData!.recid}-BudgetPill`}
                budget={props.isServiceStation ? props.headerData!.menu_planned_cost : props.headerData!.menu_target_cost}
                actualCost={props.isServiceStation ? -1 : props.headerData!.menu_planned_cost}
                currency={props.headerData!.planned_cost_currency}
              />
            ))}
          {props.inPeriod && !props.isDayAllowed && !dataValid && (
            <Stack horizontalAlign="center" style={{ width: "100%" }}>
              <TooltipHost content={DynLabel("DayForbiddenBySetup")} directionalHint={DirectionalHint.bottomCenter}>
                <FontIcon iconName="DRM" className="CornerMonthIcon DefaultCursor" />
              </TooltipHost>
            </Stack>
          )}
        </Stack>
      </Stack.Item>
      {dataValid && (
        <Stack.Item verticalFill align="end" disableShrink>
          <Stack
            horizontal
            verticalFill
            horizontalAlign="end"
            verticalAlign="center"
            styles={{ root: { fontSize: 16 } }}>
            <WarningIcon warnings={props.headerData!.warnings} cssClasses={"WarningMenuStruct"} />
            <MenuStructureActionsButton
              key={`${props.headerData!.recid}-MultiActionsButton`}
              menuId={props.headerData!.recid}
              isMenuConfirmed={dataConfirmed}
              isMenuRetail={props.isMenuRetail}
              isSalesMixConfirmed={Boolean(props.headerData!.is_sales_mix_confirmed)}
              isDayAllowed={props.isDayAllowed}
              periodMin={preFillConfirmDateMin}
              periodMax={preFillConfirmDateMax}
              userManager={weekViewContextValue!.isUserManager}
              disabled={!isTimeZoneAligned(props.headerData)}
            />
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};
