import { IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { lightestGrey } from "../StylingUtils";

interface IDayView {
  difficulty: number;
}

const difficultyStep: IStackStyles = {
  root: {
    border: "1px solid grey",
    width: "20px",
    height: "20px",
    marginRight: "5%",
    boxShadow: "inset 0 0 0 1px " + lightestGrey,
    padding: "2%",
  },
};

export const DayViewDifficulty: React.FC<IDayView> = (props) => {
  return (
    <Stack horizontal verticalAlign="center">
      <Stack
        styles={difficultyStep}
        style={{
          backgroundColor: props.difficulty >= 1 ? " grey" : "transparent",
        }}></Stack>
      <Stack
        styles={difficultyStep}
        style={{
          backgroundColor: props.difficulty >= 2 ? " grey" : "transparent",
        }}></Stack>
      <Stack
        styles={difficultyStep}
        style={{
          backgroundColor: props.difficulty === 3 ? " grey" : "transparent",
        }}></Stack>
    </Stack>
  );
};
