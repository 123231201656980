import { FontIcon, Stack } from "@fluentui/react";
import React from "react";

import { recipePillCommon } from "../StylingUtils";

interface IRecipePill {
  iconName?: string;
  isPercentage: boolean;
  value: number;
}

export const RecipePill: React.FC<IRecipePill> = (props) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: "1px", padding: "1px 2px" }}
      styles={recipePillCommon}
      style={{ cursor: "default" }}>
      {props.iconName && (
        <FontIcon className="PillIcon" iconName={props.iconName} /* FontIcon cannot be styled with CSS-in-JS*/ />
      )}
      <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {props.value}
        {props.isPercentage ? "%" : ""}
      </div>
    </Stack>
  );
};
