import { Checkbox, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext, useState } from "react";
import { PeriodContext } from "../Services/PeriodContextService";
import { getEnumFromDifficultyString } from "../Models/Difficulty";
import { GuestCountType } from "../Models/IDayPlanData";
import { IDayPlanLineData } from "../Models/IDayPlanLineData";
import { DataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { updatePlannedQuantity, updatePortionFactor, updateSalesMix } from "../Services/UpdateService";
import { validatePlannedQuantity, validatePortionFactor, validateSalesMix } from "../Services/ValidationService";
import { EditableField } from "../SharedComponents/EditableField";
import { RecipeActionsButton } from "../SharedComponents/RecipeActionsButton";
import { RecipeTitle } from "../SharedComponents/RecipeTitle";
import { WarningIcon } from "../SharedComponents/WarningIcon";
import {
  alignLeftForHorizontalStack,
  checkboxStyle,
  dayRowElementStyle,
  overflowEllipsisButHoverDont,
} from "../StylingUtils";
import { range } from "../Utils";
import { DayViewContext } from "./DayView";
import { DayViewDifficulty } from "./DayViewDifficulty";
import { columnStyles, DayDisplay, indexes, nbDayColumns, orderedIndexes, shouldBeDisplay } from "./DayViewHelper";

export interface IDayMenuRecipe extends IDayPlanLineData {
  technical_name: string;
  price: number;
  time: number;
  difficulty: string;
  allergens: string;
  displayTechnicalName: boolean;
  currentDate: Date;
  isMenuConfirmed: boolean;
  isDayAllowed: boolean;
  isMenuRetail: boolean;
  isSalesMixConfirmed: boolean;
  dayPlanGuestCountType: GuestCountType;
  isRecipeProduced: boolean;
  servingTimePassed: boolean;
  disabled?: boolean;
}

export const DayMenuRecipe: React.FC<IDayMenuRecipe> = (props) => {
  const dataContextValue = useContext(DataContext);
  const dayViewContextValue = useContext(DayViewContext);
  const currentPeriodContext = useContext(PeriodContext);
  const [isPlannedQtyModified, setPlannedQtyModified] = useState(false);
  const [isSalesMixModified, setSalesMixModified] = useState(false);
  const [isPortionFactorModified, setPortionFactorModified] = useState(false);

  const handleRecipeSelection = (checked: boolean | undefined) => {
    if (dayViewContextValue) {
      checked
        ? dayViewContextValue.dispatchSelectionEvent({ type: "add", ids: [props.recid] })
        : dayViewContextValue.dispatchSelectionEvent({ type: "remove", ids: [props.recid] });
    }
  };

  // We prepare every components and stock them in contentComponents array
  // According to the display type provided by DayViewHelper, component will be rendered or not
  const contentComponents = new Array(nbDayColumns);

  contentComponents[indexes.idxRecipe] = (
    <Stack
      horizontal
      tokens={{ childrenGap: 6 }}
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxRecipe], alignLeftForHorizontalStack)}
      key={`${props.recid}-Recipe`}>
      <Checkbox
        styles={checkboxStyle}
        checked={dayViewContextValue?.selectedElements.includes(props.recid)}
        onChange={(ev?, checked?: boolean) => {
          handleRecipeSelection(checked);
        }}
      />
      <RecipeActionsButton
        recipeId={props.recid}
        isMenuConfirmed={props.isMenuConfirmed}
        isDayAllowed={props.isDayAllowed}
        periodMin={props.currentDate}
        periodMax={props.currentDate}
        dayPlanGuestCountType={props.dayPlanGuestCountType}
        isRecipeProduced={props.isRecipeProduced}
        servingTimePassed={props.servingTimePassed}
        disabled={props.disabled}
      />
      <div style={{ overflow: "hidden" }}>
        <RecipeTitle
          recipeId={props.recid}
          recipeName={props.displayTechnicalName ? props.technical_name : props.recipe_name}
          displayStyle={"day"}
        />
      </div>
      <div style={{ fontSize: 14, paddingTop: 4, flexGrow: 1, textAlign: "end" }}>
        <WarningIcon warnings={props.warnings} cssClasses={"WarningRecipe"} />
      </div>
    </Stack>
  );

  contentComponents[indexes.idxPortionFactor] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPortionFactor])}
      key={`${props.recid}-PF`}>
      <EditableField
        isDisabled={!currentPeriodContext?.getPortionFactorEditable() || !props.isDayAllowed}
        valueToEdit={props.portion_factor}
        component={<span>{Math.round(props.portion_factor)}%</span>}
        updateValue={(value) => {
          setPortionFactorModified(true);
          updatePortionFactor(
            dataContextValue!,
            props.recid,
            value,
            (_) => {
              setPortionFactorModified(false);
            },
            (_) => {
              setPortionFactorModified(false);
            }
          );
        }}
        displayEditIcon={true}
        modifiedState={isPortionFactorModified}
        validateValue={validatePortionFactor}
        id={"portionFactor" + props.recid}
      />
    </Stack>
  );

  contentComponents[indexes.idxSalesMix] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxSalesMix])} key={`${props.recid}-SM`}>
      <EditableField
        isDisabled={
          !currentPeriodContext?.getSalesMixEditable() ||
          !props.isDayAllowed ||
          (props.isMenuRetail &&
            (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
            !dayViewContextValue?.isUserManager)
        }
        valueToEdit={props.sales_mix}
        component={<span>{Math.round(props.sales_mix)}%</span>}
        updateValue={(value) => {
          setSalesMixModified(true);
          updateSalesMix(
            dataContextValue!,
            props.recid,
            value,
            (_) => {
              setSalesMixModified(false);
            },
            (_) => {
              setSalesMixModified(false);
            }
          );
        }}
        displayEditIcon={true}
        modifiedState={isSalesMixModified}
        validateValue={validateSalesMix}
        id={"salesMix" + props.recid}
      />
    </Stack>
  );

  contentComponents[indexes.idxPlannedQty] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPlannedQty])} key={`${props.recid}-PQ`}>
      <EditableField
        isDisabled={
          !currentPeriodContext?.getPlannedQuantityEditable() ||
          !props.isDayAllowed ||
          (props.isMenuRetail &&
            (props.isMenuConfirmed || props.isSalesMixConfirmed) &&
            !dayViewContextValue?.isUserManager)
        }
        valueToEdit={props.planned_quantity}
        component={<span>{Math.round(props.planned_quantity)}</span>}
        updateValue={(value) => {
          setPlannedQtyModified(true);
          updatePlannedQuantity(
            dataContextValue!,
            props.recid,
            value,
            (_) => {
              setPlannedQtyModified(false);
            },
            (_) => {
              setPlannedQtyModified(false);
            }
          );
        }}
        displayEditIcon={true}
        modifiedState={isPlannedQtyModified}
        validateValue={validatePlannedQuantity}
        id={"plannedQuantity" + props.recid}
      />
    </Stack>
  );

  contentComponents[indexes.idxPlannedCost] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPlannedCost])} key={`${props.recid}-PC`}>
      {formatCurrencyNumber(props.currency, props.planned_cost)}
    </Stack>
  );
  contentComponents[indexes.idxPlannedSales] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPlannedSales])} key={`${props.recid}-PS`}>
      {formatCurrencyNumber(props.currency, props.planned_sales_amount)}
    </Stack>
  );

  contentComponents[indexes.idxPrice] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPrice])} key={`${props.recid}-Price`}>
      {formatCurrencyNumber(props.currency, props.price)}
    </Stack>
  );

  contentComponents[indexes.idxRecipeTargetCost] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxRecipeTargetCost])}
      key={`${props.recid}-RTC`}>
      {formatCurrencyNumber(props.currency, props.recipe_target_cost)}
    </Stack>
  );

  contentComponents[indexes.idxProductionOrder] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxProductionOrder])}
      key={`${props.recid}-PO`}>
      {props.production_id}
    </Stack>
  );

  contentComponents[indexes.idxTime] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxTime])} key={`${props.recid}-Time`}>
      {props.time ? `${props.time} ${DynLabel("Minutes")}` : "--"}
    </Stack>
  );

  contentComponents[indexes.idxDifficulty] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxDifficulty])} key={`${props.recid}-Diff`}>
      <DayViewDifficulty difficulty={getEnumFromDifficultyString(props.difficulty)} />
    </Stack>
  );

  contentComponents[indexes.idxAllergens] = (
    <Stack
      horizontal
      styles={mergeStyleSets(
        dayRowElementStyle,
        overflowEllipsisButHoverDont,
        columnStyles[indexes.idxAllergens],
        alignLeftForHorizontalStack
      )}
      key={`${props.recid}-All`}>
      <span className="MayOverflow">{props.allergens}</span>
    </Stack>
  );

  contentComponents[indexes.idxBatch] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxBatch])} key={`${props.recid}-Batch`}>
      {props.batch ? DynLabel("Yes") : DynLabel("No")}
    </Stack>
  );

  contentComponents[indexes.idxPlannedMargin] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPlannedMargin])}
      key={`${props.recid}-PM`}>
      {`${props.planned_margin.toFixed(2)}%`}
    </Stack>
  );

  contentComponents[indexes.idxTargetMargin] = (
    <Stack styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxTargetMargin])} key={`${props.recid}-TM`}>
      {`${NaN}%`}
    </Stack>
  );

  contentComponents[indexes.idxActualSellingPrice] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxActualSellingPrice])}
      key={`${props.recid}-ASP`}>
      {NaN}
    </Stack>
  );

  contentComponents[indexes.idxPlannedSellingPrice] = (
    <Stack
      styles={mergeStyleSets(dayRowElementStyle, columnStyles[indexes.idxPlannedSellingPrice])}
      key={`${props.recid}-PSP`}>
      {formatCurrencyNumber(props.currency, props.planned_selling_price)}
    </Stack>
  );

  return (
    <Stack horizontal style={{ width: "100%" }}>
      {range(0, nbDayColumns - 1).map((i) => {
        const currentIdx = orderedIndexes[i];

        switch (shouldBeDisplay[currentIdx]) {
          case DayDisplay.Both:
            return contentComponents[currentIdx];

          case DayDisplay.Retail:
            return props.isMenuRetail ? contentComponents[currentIdx] : null;

          case DayDisplay.Standard:
            return props.isMenuRetail ? null : contentComponents[currentIdx];

          case DayDisplay.None:
            return null;

          default:
            return null;
        }
      })}
    </Stack>
  );
};
