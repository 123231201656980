import { IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { IDayPlanLineIdxData } from "../Models/IDayPlanLineIdxData";
import { ISelectionTotalsData } from "../Models/ISelectionTotalsData";
import { DataContext } from "../Services/DataService";
import { darkBlue, footerDayStyle, mediumGrey, pureWhite, weekViewColumn, weekViewSidebar } from "../StylingUtils";
import { WeekMenuStructureFooterDay } from "./WeekMenuStructureFooterDay";
import { WeekSelectionContext, WeekViewContext } from "./WeekView";

const myStyle: IStackStyles = {
  root: {
    displayName: "WeekMealFooter",
    height: "auto",
    backgroundColor: darkBlue,
    color: pureWhite,
    borderBottom: `1px solid ${mediumGrey}`,
    overflow: "hidden",
  },
};

interface IWeekMenuStructureFooter {
  menuStructure: IAXMenuStructureData;
}

export const WeekMenuStructureFooter: React.FC<IWeekMenuStructureFooter> = (props) => {
  const dataContextValue = useContext(DataContext);
  const weekViewContextValue = useContext(WeekViewContext);
  const selectionCtxtValue = useContext(WeekSelectionContext);

  const aggregateDayPlanLines = (d: Date) => {
    const h = d.setUTCHours(0, 0, 0, 0);
    let currency = "";
    let selectedRecipeCount = 0;
    let sumPlannedCost = 0.0;
    let sumTargetCost = 0.0;

    selectionCtxtValue!.selectedElements
      .map((selection) => dataContextValue!.getRecipeIdIndexData(selection))
      // trying to merge the two filter() calls gives "Type 'boolean | undefined'
      // is not assignable to type 'boolean'.". Fix it and you get a cookie.
      .filter((idxData): idxData is IDayPlanLineIdxData => idxData !== null)
      .filter(
        (idxData) =>
          idxData.dayPlanDate &&
          idxData.dayPlan.menu_structure === props.menuStructure.menu_structure &&
          idxData.dayPlanDate.setUTCHours(0, 0, 0, 0) === h
      )
      .forEach((idxData: IDayPlanLineIdxData) => {
        if (currency === "") currency = idxData.dayPlanLine.currency;

        selectedRecipeCount++;
        sumPlannedCost += idxData.dayPlanLine.planned_cost;
        // Planned cost data from server already take guest count number into account
        // but recipe target costs do not, so multiply to ensure comparison
        sumTargetCost += idxData.dayPlanLine.recipe_target_cost * idxData.dayPlan.guest_count_number;
      });

    return {
      selectedRecipeCount: selectedRecipeCount,
      sumPlannedCost: sumPlannedCost,
      sumTargetCost: sumTargetCost,
      currency: currency,
    } as ISelectionTotalsData;
  };

  return (
    dataContextValue &&
    weekViewContextValue && (
      <Stack horizontal horizontalAlign="stretch" verticalFill styles={myStyle}>
        <Stack
          horizontal
          horizontalAlign="start"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          styles={weekViewSidebar}></Stack>
        {weekViewContextValue.weekDates.map((d, i) => {
          return Boolean(props.menuStructure.setupDays[d.getUTCDay()]) && weekViewContextValue.inPeriodBools[i] ? (
            // day is allowed by setup and in period - let's show footer
            <WeekMenuStructureFooterDay key={`${d.valueOf()}-structureFooterDay`} totals={aggregateDayPlanLines(d)} />
          ) : (
            // else return empty div to fill space
            <Stack key={`${d.valueOf()}-structureFooterDay`} styles={mergeStyleSets(weekViewColumn, footerDayStyle)} />
          );
        })}
      </Stack>
    )
  );
};
