import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { renderApp } from './Services/PlannerReactServices';
import * as serviceWorker from './serviceWorker';

//import React from "react";
//import ReactDOM from "react-dom";
//import App from "./App";
(window as any).PlannerReactServices = {
  renderApp
};
 const sdxFoodCoreLabels = document.createElement("script");
 sdxFoodCoreLabels.src = "/resources/labels/SDXFoodCore.js";
 document.body.appendChild(sdxFoodCoreLabels);
//ReactDOM.render(
//    <App readonly={false} start_on_monday={true} />,
//    document.getElementById("AVAMenuPlannerControlContent"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
