import React, { Dispatch, Reducer, useReducer } from 'react';

import { IDataContext } from '../Services/DataService';
import { DynUpdateRecipeSelection } from '../Services/DynService';

export interface ISelectionContext {
  selectedElements: Array<number>;
  dispatchSelectionEvent: React.Dispatch<ISelectionReducerAction>;
}

export interface ISelectionReducerAction {
  type: "add" | "remove" | "set";
  ids: Array<number>;
}

export const selectionReducer: Reducer<Array<number>, ISelectionReducerAction> = (currentSelection, action) => {
  switch (action.type) {
    case "add":
      return currentSelection.concat(action.ids);
    case "remove":
      return currentSelection.filter((selected) => {
        return !action.ids.includes(selected);
      });
    case "set":
      return action.ids;
    default:
      throw new Error(`Unknown action ${action.type} in selectedRecipesReducer`);
  }
};

export const useRecipeSelectionReducer = (
  selectionContext: "day" | "week",
  initialState: Array<number>
): [Array<number>, Dispatch<ISelectionReducerAction>, (currentData: IDataContext) => void] => {
  const [selection, dispatch] = useReducer(selectionReducer, initialState);

  const wrappedDispatch = (action: ISelectionReducerAction) => {
    DynUpdateRecipeSelection(selectionContext, action);
    return dispatch(action);
  };

  const checkSelectionAgainstData = (currentData: IDataContext) => {
    // Selected ids may not exist anymore in data, verify
    const idsInData = selection.filter((selectedId) => {
      return currentData.getRecipeIdIndexData(selectedId) !== null;
    });

    if (idsInData.length < selection.length) {
      wrappedDispatch({ type: "set", ids: idsInData });
    }
  };

  return [selection, wrappedDispatch, checkSelectionAgainstData];
};
