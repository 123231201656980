import { FontIcon, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { monthDayLabelStyle, wrong } from "../StylingUtils";

const costStyle = (isWrong: boolean) =>
  ({
    root: [isWrong ? { color: wrong } : {}],
  } as IStackStyles);

interface IMonthDayCost {
  currency: string;
  label: string;
  cost: number;
  noZero: boolean;
  isWrong: boolean;
  styling?: IStackStyles;
}

export const MonthDayCost: React.FC<IMonthDayCost> = (props) => {
  return (
    <Stack tokens={{ childrenGap: 1 }} styles={mergeStyleSets(costStyle(props.isWrong), props.styling)}>
      <span style={monthDayLabelStyle}>{props.label}</span>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <FontIcon iconName="Money" />
        <span>{props.noZero && props.cost === 0 ? "--" : formatCurrencyNumber(props.currency, props.cost)}</span>
      </Stack>
    </Stack>
  );
};
