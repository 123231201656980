import { Stack } from "@fluentui/react";
import React, { useContext, useEffect, useReducer, useState } from "react";

import { ISelectionContext, useRecipeSelectionReducer } from "../Hooks/SelectionReducer";
import { DataContext } from "../Services/DataService";
import { PeriodContext } from "../Services/PeriodContextService";
import { getISODate, getWeekFromFirstDay } from "../Utils";
import { WeekMenuStructure } from "./WeekMenuStructure";
import { WeekViewHeader } from "./WeekViewHeader";
import { WeekMenuStructureHeader } from "./WeekMenuStructureHeader";
import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { useCollapseState } from "../Hooks/ICanCollapse";
import { IDayPlanData } from "../Models/IDayPlanData";

interface IWeekView {
  firstDay: Date;
  displayTechnicalName: boolean;
  handleRedirectToDailyView: (d: Date) => void;
  isUserManager: boolean;
}

interface IWeekViewContext {
  weekDates: Array<Date>;
  inPeriodBools: Array<boolean>;
  isUserManager: boolean;
  selectedDays: Array<string>;
  updateSelected: (key: string, checked: boolean) => void;
}

export const WeekViewContext = React.createContext<IWeekViewContext | null>(null);
export const WeekSelectionContext = React.createContext<ISelectionContext | null>(null);

const WeekView: React.FC<IWeekView> = (props) => {
  const { isCollapsed, handleCollapse } = useCollapseState();
  const currentPeriodContext = useContext(PeriodContext);
  const currentDataContext = useContext(DataContext);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  /**
   * 
   * @param key a string containing the menustructure name and the date
   * @param checked 
   */
  const updateSelected = (key: string, checked: boolean) => {
    const menuPartName = currentPeriodContext?.getMenuPartName() ?? "";
    if (checked) {
      // When the selected days change, check if the static header was selected. if so, select all menu structures for that day
      if (key.includes(menuPartName)) {
        const keysToAdd = [key];
        console.log("static header selected");

        const datePart = key.split(":")[1];
        keysToAdd.push(...menuStructures.map((structure) =>
          (`${structure.menu_structure}:${datePart}`)
        ));
        console.log("keysToAdd", keysToAdd);
        setSelectedDays([...selectedDays, ...keysToAdd]);
      } else {
        setSelectedDays([...selectedDays, key]);
      }
    } else {
      const keysToRemove = [key];
      if (key.includes(menuPartName)) {
        // If a static header was unchecked, uncheck all menu structures for that day
        const datePart = key.split(":")[1];

        keysToRemove.push(...selectedDays.filter((key) => key.endsWith(datePart)));
        console.log("keysToRemove", keysToRemove);
      }
      setSelectedDays(selectedDays.filter((key) => !keysToRemove.includes(key)));
    }
  };

  const getWeekContext = (): IWeekViewContext => {
    if (currentPeriodContext) {
      return {
        isUserManager: props.isUserManager,
        weekDates: getWeekFromFirstDay(props.firstDay),
        inPeriodBools: currentPeriodContext.getInPeriodBoolForDates(getWeekFromFirstDay(props.firstDay)),
        selectedDays,
        updateSelected
      };
    }


    return {
      isUserManager: true,
      weekDates: [],
      inPeriodBools: [],
      selectedDays,
      updateSelected
    };
  };

  let [weekCtxt, setWeekCtxt] = useReducer(getWeekContext, getWeekContext());

  useEffect(() => {
    console.log('selectedDays', selectedDays);
    setWeekCtxt();
  }, [currentPeriodContext, selectedDays]);

  const [selectedRecipes, dispatch, checkSelection] = useRecipeSelectionReducer("week", []);

  // Clean selection user navigates to another week
  useEffect(() => {
    dispatch({ type: "set", ids: [] });
  }, [props.firstDay]); // gives warning about missing dependencies but behavior is what we want as it is

  const [menuStructures, setMenuStructures] = useState<IAXMenuStructureData[]>([]);
  const [overrides, setOverrides] = useState<any[]>([]);
  const [dayPlans, setDayPlans] = useState<any[]>([]);

  useEffect(() => {
    if (currentDataContext !== null) {
      checkSelection(currentDataContext);
      setMenuStructures(currentDataContext.getMenuStructures());
    }
  }, [currentDataContext]);

  useEffect(() => {
    const overrideData: any[] = [];
    const dayPlanData: any[] = [];
    weekCtxt.weekDates.forEach((d) => {
      const dayOverride = {
        menu_planned_cost: 0,
        menu_target_cost: 0,
        guest_count_number: 0,
        planned_cost_currency: "",
        guest_count_type: "",
        UserTimeZone: "",
        CostCenterTimeZone: ""
      };
      menuStructures.forEach((structure) => {
        const dayData = currentDataContext?.getMenuStructureData(d, structure.menu_structure);

        if (dayData) {
          dayPlanData.push({menu_structure: dayData.menu_structure, DisplayOrderSequence: dayData.DisplayOrderSequence });
          dayOverride.guest_count_type = dayData.guest_count_type;
          dayOverride.planned_cost_currency = dayData.planned_cost_currency;
          dayOverride.menu_planned_cost += dayData.menu_planned_cost;
          dayOverride.menu_target_cost += dayData.menu_target_cost;
          dayOverride.guest_count_number += dayData.guest_count_number;
          dayOverride.UserTimeZone = dayData.UserTimeZone;
          dayOverride.CostCenterTimeZone = dayData.CostCenterTimeZone;
        }
      });
      overrideData[d.getTime()] = dayOverride;
    });
    
    dayPlanData.sort((a, b) => a.DisplayOrderSequence - b.DisplayOrderSequence);
    setDayPlans(dayPlanData);
    setOverrides(overrideData);
  }, [menuStructures]);
  /**
   *
   * Week view is rendered with a "row" philosophy
   *
   * To facilitate redesign and for a better management of column widths,
   * it might be interesting to think about a "column-first" rendering.
   *
   * This might imply to rethink data organization as well.
   *
   */

  return (
    currentDataContext &&
    currentPeriodContext && (
      <Stack>
        <WeekViewContext.Provider value={weekCtxt}>
          <WeekViewHeader handleRedirectToDailyView={props.handleRedirectToDailyView} />
          <Stack tokens={{ childrenGap: 1 }}>
            {/* Unlike WeekViewContext, we keep a new object, as using a state would trigger a second and unwanted WeekView render */}
            {/* Call of dispatch triggered a first one, and with new object here, every one will know that selection changed */}
            <WeekSelectionContext.Provider
              value={{
                selectedElements: selectedRecipes,
                dispatchSelectionEvent: dispatch,
              }}>
              {menuStructures.length > 0 && <WeekMenuStructureHeader
                menuStructureName={menuStructures[0].menu_structure}
                setupDays={menuStructures[0].setupDays}
                isMenuRetail={Boolean(menuStructures.some(s => s.is_retail))}
                isCollapsed={isCollapsed}
                onCollaspeClick={handleCollapse}
                menuNameDisplayOverride={currentPeriodContext.getMenuPartName()}
                overrideData={overrides}
              // onCheckboxChange={(state) => {setCheckboxState(state)}}
              />}
              {dayPlans.map((structure) => (
                <WeekMenuStructure
                  key={`WeekMenuStruct-${structure.menu_structure}`}
                  displayTechnicalName={props.displayTechnicalName}
                  menuStructure={menuStructures.find(ms => ms.menu_structure === structure.menu_structure)!}
                  parentCollapsed={isCollapsed}
                // parentChecked={checkboxState}
                />
              ))}
            </WeekSelectionContext.Provider>
          </Stack>
        </WeekViewContext.Provider>
      </Stack>
    )
  );
};

export default WeekView;
