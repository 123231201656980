import React, { useContext, useEffect, useState } from "react";

import { IAXMenuStructureData, IDataFromAX } from "../Models/IDataFromAx";
import { GuestCountType, IDayPlanData } from "../Models/IDayPlanData";
import { IDayPlanLineData } from "../Models/IDayPlanLineData";
import { IDayPlanLineIdxData } from "../Models/IDayPlanLineIdxData";
import { IDayviewRecipeData as IDayViewRecipeData } from "../Models/IRecipeData";
import { IMenuPlannerDataModel, MenuPlannerDataModel } from "../Models/MenuPlannerDataModel";
import { formatStringArrayAsString } from "../StringUtils";
import { DynControlInstance, DynObserve, DynUpdateDayPlan, DynUpdateDayPlanLine } from "./DynService";
import { PeriodContext } from "./PeriodContextService";

interface IDataContextProps {
  children: any;
  controlInstance: any;
}

interface IDataProviderState {
  menuStructures: Array<IAXMenuStructureData>;
  menuPlansAndBOMData: IMenuPlannerDataModel;
}

export interface IDataContext {
  getMenuStructureData: (dateOfData: Date, structureName: string) => IDayPlanData | undefined;
  getDayPlanLineData: (dateOfData: Date, structureName: string, subMenuName?: string) => Array<IDayPlanLineData>;
  getRecipeIds: (dateOfData: Date, structureName: string) => Array<number>;
  getMenuStructure: (menuStructureName: string) => IAXMenuStructureData | null;
  getMenuStructures: () => Array<IAXMenuStructureData>;
  getDayViewRecipeData: (recipeBomRecId: number) => IDayViewRecipeData;
  getTechnicalName: (recipeBomRecId: number) => string;
  getRecipeIdIndexData: (dayPlanLineRecid: number) => IDayPlanLineIdxData | null;
  updatePortionFactor: (recipeId: number, portionFactor: number) => Promise<IDayPlanLineData>;
  updateSalesMix: (recipeId: number, salesMix: number) => Promise<IDayPlanLineData>;
  updatePlannedQuantity: (recipeId: number, plannedQuantity: number) => Promise<IDayPlanLineData>;
  updateGuestCount: (
    menuStructureId: number,
    guestCountNumber: number,
    guestCountType: GuestCountType
  ) => Promise<string>;
}

const _getMenuStructures = (currentData: IDataProviderState): Array<IAXMenuStructureData> => {
  return currentData.menuStructures ? currentData.menuStructures : [];
};

const _getDayViewRecipeData = (currentData: IDataProviderState, recipeBomRecid: number) => {
  const result = {
    technical_name: "",
    allergens: "",
    difficulty: "",
    time: 0,
    price: 0,
  } as IDayViewRecipeData;

  const recipeData = currentData.menuPlansAndBOMData.GetBOMRecipeData(recipeBomRecid);
  if (recipeData) {
    result.technical_name = recipeData.technical_name;
    result.allergens = formatStringArrayAsString(
      recipeData.allergen_attribute_data.map((allergen) => allergen.attribute_name)
    );
    result.difficulty = recipeData.difficulty;
    result.time = recipeData.time;
    result.price = recipeData.price;
  }

  return result;
};

const _getTechnicalName = (currentData: IDataProviderState, recipeBomRecid: number) => {
  const recipeData = currentData.menuPlansAndBOMData.GetBOMRecipeData(recipeBomRecid);
  if (recipeData) {
    return recipeData.technical_name;
  }

  return "";
};

const _getMenuStructData = (
  currentData: IDataProviderState,
  dateOfData: Date,
  structureName: string,
  serviceLocation?: string
): IDayPlanData | undefined => {
  return currentData.menuPlansAndBOMData.GetMenuStructureData(dateOfData, structureName, serviceLocation);
};

const _getMenuPlanLineData = (
  currentData: IDataProviderState,
  dateOfData: Date,
  structureName: string,
  subMenuName?: string
): Array<IDayPlanLineData> => {
  return currentData.menuPlansAndBOMData.GetDayRecipesData(dateOfData, structureName, subMenuName);
};

// Retrieve all recipe ids for a menu structure on a specific day
const _getAllRecipeIdsFor = (
  currentData: IDataProviderState,
  dateOfData: Date,
  structureName: string
): Array<number> => {
  return currentData.menuPlansAndBOMData.GetAllRecipeIds(dateOfData, structureName);
};

const _updatePortionFactor = (
  currentData: IDataProviderState,
  recipeId: number,
  newValue: number
): Promise<IDayPlanLineData> => {
  const indexedData = currentData.menuPlansAndBOMData.GetRecipeIdIndexData(recipeId);
  if (indexedData != null) {
    // Clone data and wait for D365 confirmation to replace it in model
    const dayPlanLineModified = { ...indexedData.dayPlanLine };
    dayPlanLineModified.portion_factor = newValue;

    return new Promise((resolve, reject) => {
      DynUpdateDayPlanLine(dayPlanLineModified, function (updateDone: string | undefined) {
        if (updateDone === "true") {
          indexedData.dayPlanLine = dayPlanLineModified;
          resolve(dayPlanLineModified);
        } else {
          reject(indexedData.dayPlanLine);
        }
      });
    });
  } else {
    throw new Error("Trying to update portion factor but cannot find recipe in indexed data");
  }
};

const _updateSalesMix = (
  currentData: IDataProviderState,
  recipeId: number,
  newValue: number
): Promise<IDayPlanLineData> => {
  const indexedData = currentData.menuPlansAndBOMData.GetRecipeIdIndexData(recipeId);
  if (indexedData != null) {
    // Clone data and wait for D365 confirmation to replace it in model
    const dayPlanLineModified = { ...indexedData.dayPlanLine };
    dayPlanLineModified.sales_mix = newValue;

    return new Promise((resolve, reject) => {
      DynUpdateDayPlanLine(dayPlanLineModified, function (updateDone: string | undefined) {
        if (updateDone === "true") {
          indexedData.dayPlanLine = dayPlanLineModified;
          resolve(dayPlanLineModified);
        } else {
          reject(indexedData.dayPlanLine);
        }
      });
    });
  } else {
    throw new Error("Trying to update sales mix but cannot find recipe in indexed data");
  }
};

const _updatePlannedQuantity = (
  currentData: IDataProviderState,
  recipeId: number,
  newValue: number
): Promise<IDayPlanLineData> => {
  const indexedData = currentData.menuPlansAndBOMData.GetRecipeIdIndexData(recipeId);
  if (indexedData != null) {
    // Clone data and wait for D365 confirmation to replace it in model
    const dayPlanLineModified = { ...indexedData.dayPlanLine };
    dayPlanLineModified.planned_quantity = newValue;

    return new Promise((resolve, reject) => {
      DynUpdateDayPlanLine(dayPlanLineModified, function (updateDone: string | undefined) {
        if (updateDone === "true") {
          indexedData.dayPlanLine = dayPlanLineModified;
          resolve(dayPlanLineModified);
        } else {
          reject(indexedData.dayPlanLine);
        }
      });
    });
  } else {
    throw new Error("Trying to update planned quantity but cannot find recipe in indexed data");
  }
};

const _updateGuestCount = (
  currentData: IDataProviderState,
  menuStructureId: number,
  newValue: number,
  newType: GuestCountType
): Promise<string> => {
  const initialData = currentData.menuPlansAndBOMData.GetMenuStructureDataById(menuStructureId);
  if (initialData !== undefined) {
    // Clone data
    const dayPlanModified: IDayPlanData = { ...initialData };
    dayPlanModified.guest_count_number = newValue;
    dayPlanModified.guest_count_type = newType;

    return new Promise((resolve, reject) => {
      DynUpdateDayPlan(dayPlanModified, function (promptOpened: string | undefined) {
        if (promptOpened === "true") {
          // the user was prompted how they want to perform the update, but they can
          // still cancel
          resolve(promptOpened);
        } else {
          reject(promptOpened);
        }
      });
    });
  } else {
    throw new Error("Trying to update guest count but cannot find recipe in indexed data");
  }
};

export const DataContext = React.createContext<IDataContext | null>(null);

export const DataProvider = (props: IDataContextProps) => {
  const periodContext = useContext(PeriodContext);
  const [dataFromAX, setDataFromAX] = useState({
    menuStructures: [],
    menuPlansAndBOMData: new MenuPlannerDataModel([], [], []),
    recipesData: [],
  } as IDataProviderState);

  useEffect(() => {
    //Observe data (menu structures & menu plans)
    DynObserve(DynControlInstance().Data, function (dataObj: IDataFromAX | null) {
      console.log(`>>>>> data callback called`);
      console.log(dataObj);
      if (dataObj && dataObj.menu_structure_data.length > 0) {
        const myDataModel = new MenuPlannerDataModel(
          dataObj.menu_structure_data,
          dataObj.menu_plan_data,
          dataObj.recipe_data
        );

        setDataFromAX({
          menuStructures: dataObj.menu_structure_data,
          menuPlansAndBOMData: myDataModel,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // give warning of missing dependency, but it is kind of on purpose

  const myContextValue: IDataContext = {
    getMenuStructures: () => {
      return _getMenuStructures(dataFromAX);
    },
    getMenuStructure: (menuStructureName: string) => {
      return _getMenuStructures(dataFromAX).find((struct) => struct.menu_structure === menuStructureName) || null;
    },
    getMenuStructureData: (dateOfData: Date, structureName: string) => {
      return _getMenuStructData(dataFromAX, dateOfData, structureName, periodContext?.getSelectedServiceLocation());
    },
    getDayPlanLineData: (dateOfData: Date, structureName: string, subMenuName?: string) => {
      return _getMenuPlanLineData(dataFromAX, dateOfData, structureName, subMenuName);
    },
    getDayViewRecipeData: (recipeBomRecid: number) => {
      return _getDayViewRecipeData(dataFromAX, recipeBomRecid);
    },
    getTechnicalName: (recipeBomRecid: number) => {
      return _getTechnicalName(dataFromAX, recipeBomRecid);
    },
    getRecipeIds: (dateOfData: Date, structureName: string) => {
      return _getAllRecipeIdsFor(dataFromAX, dateOfData, structureName);
    },
    getRecipeIdIndexData: (dayPlanLineRecid: number) => {
      return dataFromAX.menuPlansAndBOMData.GetRecipeIdIndexData(dayPlanLineRecid);
    },
    updatePortionFactor: (recipeId: number, portionFactor: number) => {
      return _updatePortionFactor(dataFromAX, recipeId, portionFactor);
    },
    updateSalesMix: (recipeId: number, salesMix: number) => {
      return _updateSalesMix(dataFromAX, recipeId, salesMix);
    },
    updatePlannedQuantity: (recipeId: number, plannedQuantity: number) => {
      return _updatePlannedQuantity(dataFromAX, recipeId, plannedQuantity);
    },
    updateGuestCount: (menuStructureId, guestCountNumber: number, guestCountType: GuestCountType) => {
      return _updateGuestCount(dataFromAX, menuStructureId, guestCountNumber, guestCountType);
    },
  };

  return <DataContext.Provider value={myContextValue}>{props.children}</DataContext.Provider>;
};
