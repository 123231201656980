import { Stack } from "@fluentui/react";
import React, { useContext, useState } from "react";

import { DataContext } from "../Services/DataService";
import { getWeeksWithDates } from "../Utils";
import { MonthViewHeader } from "./MonthViewHeader";
import { MonthViewWeekRow } from "./MonthViewWeekRow";

interface IMonthView {
  currentMonth: Date;
  weekStartsOn: number;
  handleRedirectToDailyView: (d: Date) => void;
}

/**
 *
 * Month view is rendered with a "row" philosophy
 *
 * To facilitate redesign and for a better management of column widths,
 * it might be interesting to think about a "column-first" rendering.
 *
 * This might imply to rethink data organization as well.
 *
 */

export const MonthView: React.FC<IMonthView> = (props) => {
  const dataContextValue = useContext(DataContext);

  const currentStructures = dataContextValue ? dataContextValue.getMenuStructures() : [];
  const [selectedStructure, setSelectedStructure] = useState(
    currentStructures.length > 0 ? currentStructures[0] : null
  );

  const selectedStructureName = selectedStructure?.menu_structure || "";
  const setSelectedStructureName = (selectedName: string | number | undefined) => {
    const selectedStruct = currentStructures.find((struct) => struct.menu_structure === selectedName);
    setSelectedStructure(selectedStruct || null);
  };

  const monthCalendar = getWeeksWithDates(props.currentMonth, props.weekStartsOn);

  const rows: React.ReactNode[] = [];
  monthCalendar.forEach((weekDates, weekNumber) =>
    rows.push(
      <MonthViewWeekRow
        key={`MonthViewWeekRow-${weekNumber}`}
        dates={weekDates}
        weekNumber={weekNumber}
        currentMonth={props.currentMonth}
        selectedStructure={selectedStructure}
        handleRedirectToDailyView={props.handleRedirectToDailyView}
      />
    )
  );

  return (
    <Stack>
      <MonthViewHeader
        weekStartsOn={props.weekStartsOn}
        currentMonth={props.currentMonth}
        menuStructuresNames={currentStructures.map((data) => data.menu_structure)}
        selected={selectedStructureName}
        setSelected={setSelectedStructureName}
      />
      {rows}
    </Stack>
  );
};
