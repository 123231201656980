import { FontIcon, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { DynLabel } from "../Services/DynService";
import { formatCurrencyNumber } from "../Services/GlobalizeService";

interface IMonthWeekProfit {
  weeklyPlannedGrossProfit: number;
  currency: string;
  label?: string;
  styling?: IStackStyles;
  labelStyle?: React.CSSProperties;
}

export const MonthWeekProfit: React.FC<IMonthWeekProfit> = (props) => {
  return (
    <Stack horizontalAlign="start" verticalAlign="start" tokens={{ childrenGap: 1 }} styles={props.styling}>
      <span style={props.labelStyle}>{props.label || DynLabel("PlannedGrossProfit")}</span>
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <FontIcon iconName="Financial" />
        <span>{formatCurrencyNumber(props.currency, props.weeklyPlannedGrossProfit)}</span>
      </Stack>
    </Stack>
  );
};
