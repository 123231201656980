import { IStackStyles, Stack } from "@fluentui/react";
import React, { useContext } from "react";
import { IAXMenuStructureData } from "../Models/IDataFromAx";

import { isValid } from "../Models/IDayPlanData";
import { DataContext, IDataContext } from "../Services/DataService";
import { DynLabel } from "../Services/DynService";
import { PeriodContext } from "../Services/PeriodContextService";
import { mediumGrey, monthViewSidebar, pureBlack, pureWhite } from "../StylingUtils";
import { cloneUTCDate } from "../Utils";
import { MonthGuestCount } from "./MonthGuestCount";
import { MonthViewWeekRowDay } from "./MonthViewWeekRowDay";
import { MonthWeekCost } from "./MonthWeekCost";
import { MonthWeekMargin } from "./MonthWeekMargin";
import { MonthWeekProfit } from "./MonthWeekProfit";

const myStyle: IStackStyles = {
  root: {
    displayName: "MonthViewHeader",
    backgroundColor: pureWhite,
    color: pureBlack,
  },
};

const weekDataStackStyle: IStackStyles = {
  root: {
    height: "100%",
  },
};

const sidebarLabelStyle: React.CSSProperties = {
  fontWeight: "normal",
  fontSize: 11,
  color: mediumGrey,
};

interface IMonthWeekData {
  weeklyGuestCount: number;
  totalPlannedCost: number;
  totalTargetCost: number;
  averagePlannedCost: number;
  averageTargetCost: number;
  currency: string;
  setupDays: Array<number>;
  isRetail: boolean;
  // retail indicators
  weeklyPlannedMargin: number;
  weeklyPlannedGrossProfit: number;
}

const computeWeekData = (
  dataContextValue: IDataContext | null,
  dates: Array<Date>,
  menuStructure: IAXMenuStructureData | null
) => {
  if (dataContextValue && menuStructure) {
    let weeklyCount = 0;
    let totalPlanned = 0;
    let totalTarget = 0;
    let avgPlanned = 0;
    let avgTarget = 0;
    let currency = "";
    let weeklyPlannedSalesAmount = 0;
    let weeklyPlannedGrossProfit = 0;

    dates
      .map((date) => {
        const menuStructureData = dataContextValue.getMenuStructureData(date, menuStructure.menu_structure);

        // Discard menu days with 0 guest because they're not really valid
        if (isValid(menuStructureData) && menuStructureData!.guest_count_number !== 0) {
          if (currency.length === 0) currency = menuStructureData!.planned_cost_currency;
          return {
            count: menuStructureData!.guest_count_number,
            planned: menuStructureData!.menu_planned_cost,
            target: menuStructureData!.menu_target_cost,
            plannedMarginAmount: menuStructureData!.menu_planned_margin_amount,
            plannedSalesAmount: menuStructureData!.menu_planned_sales,
          };
        }
        return { count: 0, planned: 0, target: 0, plannedMarginAmount: 0, plannedSalesAmount: 0 };
      })
      .forEach((dayValues) => {
        weeklyCount += dayValues.count;
        totalPlanned += dayValues.planned;
        totalTarget += dayValues.target;
        weeklyPlannedGrossProfit += dayValues.plannedMarginAmount;
        weeklyPlannedSalesAmount += dayValues.plannedSalesAmount;
      });

    if (weeklyCount > 0) {
      avgPlanned = totalPlanned / weeklyCount;
      avgTarget = totalTarget / weeklyCount;
    }

    const weeklyPlannedMargin =
      weeklyPlannedSalesAmount !== 0 ? (weeklyPlannedGrossProfit / weeklyPlannedSalesAmount) * 100 : 0;

    return {
      weeklyGuestCount: weeklyCount,
      totalPlannedCost: totalPlanned,
      totalTargetCost: totalTarget,
      averagePlannedCost: avgPlanned,
      averageTargetCost: avgTarget,
      currency: currency,
      setupDays: menuStructure.setupDays,
      isRetail: Boolean(menuStructure.is_retail),
      weeklyPlannedMargin,
      weeklyPlannedGrossProfit,
    } as IMonthWeekData;
  }

  return null;
};

interface IMonthViewWeekRow {
  currentMonth: Date;
  weekNumber: number;
  dates: Date[];
  selectedStructure: IAXMenuStructureData | null;
  handleRedirectToDailyView: (d: Date) => void;
}

const WeekMonthData: React.FC<IMonthWeekData> = (props) => {
  return (
    <Stack horizontalAlign="start" verticalAlign="space-evenly" tokens={{ childrenGap: 5 }} styles={weekDataStackStyle}>
      <MonthGuestCount
        label={DynLabel("MonthWeeklyGCLabel")}
        guestCount={props.weeklyGuestCount}
        labelStyle={sidebarLabelStyle}
      />
      {props.isRetail ? (
        <React.Fragment>
          <MonthWeekProfit
            weeklyPlannedGrossProfit={props.weeklyPlannedGrossProfit}
            currency={props.currency}
            labelStyle={sidebarLabelStyle}
          />
          <MonthWeekMargin plannedMargin={props.weeklyPlannedMargin} labelStyle={sidebarLabelStyle} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MonthWeekCost
            label={DynLabel("MonthWeeklyTotalLabel")}
            actualCost={props.totalPlannedCost}
            targetCost={props.totalTargetCost}
            currency={props.currency}
            labelStyle={sidebarLabelStyle}
          />
          <MonthWeekCost
            label={DynLabel("MonthWeeklyAvgLabel")}
            actualCost={props.averagePlannedCost}
            targetCost={props.averageTargetCost}
            currency={props.currency}
            labelStyle={sidebarLabelStyle}
            budgetPillTooltipProps={{
              text: DynLabel("AverageCostBudgetPillInfo"),
              title: DynLabel("AverageCostBudgetPillInfoTitle"),
            }}
          />
        </React.Fragment>
      )}
    </Stack>
  );
};

export const MonthViewWeekRow: React.FC<IMonthViewWeekRow> = (props) => {
  const dataContextValue = useContext(DataContext);
  const currentPeriodContext = useContext(PeriodContext);

  const inPeriodBools = currentPeriodContext ? currentPeriodContext.getInPeriodBoolForDates(props.dates) : [];
  const weekInPeriod = inPeriodBools.length > 0 && inPeriodBools.includes(true);
  const weekMonthData = computeWeekData(dataContextValue, props.dates, props.selectedStructure);

  return (
    <Stack horizontal horizontalAlign="stretch" styles={myStyle}>
      <Stack
        horizontalAlign="start"
        verticalAlign="start"
        tokens={{ childrenGap: 5, padding: 5 }}
        styles={monthViewSidebar}>
        <span>W{props.weekNumber}</span>
        {weekMonthData && weekInPeriod && <WeekMonthData {...weekMonthData} />}
      </Stack>
      {props.dates.map((d, i) => (
        <MonthViewWeekRowDay
          key={`${d.getMonth()}-${d.getUTCDate()}`}
          date={cloneUTCDate(d)}
          currentMonth={props.currentMonth}
          selectedStructure={props.selectedStructure}
          inPeriod={inPeriodBools[i]}
          isDayAllowed={Boolean(weekMonthData?.setupDays[d.getUTCDay()])}
          handleRedirectToDailyView={props.handleRedirectToDailyView}
        />
      ))}
    </Stack>
  );
};
