import { IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { recipePillCommon } from "../StylingUtils";

interface IRecipeBudgetPill {
  cost: number;
  currency: string;
  styling?: IStackStyles;
}

export const RecipeBudgetPill: React.FC<IRecipeBudgetPill> = (props) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: "1px", padding: "1px 2px" }}
      styles={mergeStyleSets(recipePillCommon, props.styling)}
      style={{ cursor: "default" }}>
      <span style={{ display: "inline", maxWidth: "100%", overflow: "hidden" }}>
        {`${formatCurrencyNumber(props.currency, props.cost)}`}
      </span>
    </Stack>
  );
};
