export interface IKeyedCollection<T> {
  AddOrReplace(key: string, value: T): void;
  Remove(key: string): T;
  ContainsKey(key: string): boolean;
  GetValue(key: string): T;
  Clear(): void;
}

export class KeyedCollection<T> implements IKeyedCollection<T> {
  private items: { [index: string]: T } = {};

  public AddOrReplace(key: string, value: T) {
    this.items[key] = value;
  }

  public Remove(key: string): T {
    var val = this.items[key];
    delete this.items[key];
    return val;
  }

  public ContainsKey(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  public GetValue(key: string): T {
    return this.items[key];
  }

  public Clear() {
    this.items = {};
  }
}
