import { IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React, { useContext } from "react";

import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { isTimeZoneAligned, isValid } from "../Models/IDayPlanData";
import { IDayPlanLineData } from "../Models/IDayPlanLineData";
import { DataContext } from "../Services/DataService";
import { CreateMenuAddRecipeButton } from "../SharedComponents/CreateMenuAddRecipeButton";
import { weekViewColumn } from "../StylingUtils";
import { WeekRecipe } from "./WeekRecipe";

const myStyle: IStackStyles = {
  root: {
    padding: 1,
  },
};

interface IWeekSubMenuRecipes {
  menuDate: Date;
  parentStructure: IAXMenuStructureData;
  subMenuName: string;
  inPeriod: boolean;
  recipesData: Array<IDayPlanLineData>;
  displayTechnicalName: boolean;
  isDayAllowed: boolean;
  startTime: number;
  parentChecked: boolean;
  serviceLocation?: string;
  serviceStation?: string;
}

export const WeekSubMenuRecipes: React.FC<IWeekSubMenuRecipes> = (props) => {
  const dataContextValue = useContext(DataContext);

  const structureData = dataContextValue?.getMenuStructureData(props.menuDate, props.parentStructure.menu_structure);
  const menuStructure = dataContextValue?.getMenuStructure(props.parentStructure.menu_structure);
  const parentValid = isValid(structureData);
  const parentConfirmed = parentValid && Boolean(structureData?.is_confirmed);
  const serviceDateTime = new Date(props.menuDate.getTime() + props.startTime);

  const isSubmenuInServiceStation = props.recipesData.map(r => r.submenu === props.subMenuName && r.ServiceStation === props.serviceStation).includes(true);
  return (
    <Stack styles={mergeStyleSets(myStyle, weekViewColumn)}>
      {props.inPeriod && isSubmenuInServiceStation && structureData &&
        (props.isDayAllowed || (!props.isDayAllowed && parentValid && props.recipesData.length > 0)) && (
          <CreateMenuAddRecipeButton
            key={`${props.menuDate.valueOf()}-${props.parentStructure.menu_structure}-CreateAddButton`}
            parentStructure={structureData}
            subMenuName={props.subMenuName}
            serviceLocation={props.serviceLocation || ""}
            serviceStation={props.serviceStation || ""}
            structureName={props.parentStructure.menu_structure}
            menuDate={props.menuDate}
            disabled={parentConfirmed || !props.isDayAllowed || new Date() > serviceDateTime || !isTimeZoneAligned(structureData)} // TODO: disable if the submenu does not have the service station || structureData
            styling={{ root: { padding: "0px 1px" } }}
          />
        )}
      {structureData &&
        props.inPeriod &&
        props.recipesData &&
        props.recipesData.map((dayPlanLine: IDayPlanLineData, i: number) => (
          <WeekRecipe
            key={`WeekRecipe-${dayPlanLine.recid}`}
            recipeId={dayPlanLine.recid}
            recipeName={
              props.displayTechnicalName
                ? dataContextValue!.getTechnicalName(dayPlanLine.recipe_bom_recid)
                : dayPlanLine.recipe_name
            }
            portionFactor={dayPlanLine.portion_factor}
            plannedCost={dayPlanLine.planned_cost}
            targetCost={dayPlanLine.recipe_target_cost}
            plannedQuantity={dayPlanLine.planned_quantity}
            currency={dayPlanLine.currency}
            salesMix={dayPlanLine.sales_mix}
            isSalesMixConfirmed={Boolean(structureData.is_sales_mix_confirmed)}
            isMenuRetail={Boolean(props.parentStructure.is_retail)}
            isMenuConfirmed={parentConfirmed}
            isDayAllowed={props.isDayAllowed}
            recipeWarnings={dayPlanLine.warnings}
            dayPlanGuestCountType={structureData.guest_count_type}
            isRecipeProduced={dayPlanLine.production_id !== ""}
            parentChecked={props.parentChecked}
            disabled={!isTimeZoneAligned(structureData)}
          />
        ))}
    </Stack>
  );
};
