export interface IDayPlanData {
  dayplan_date: string;
  menu_planned_cost: number;
  menu_target_cost: number;
  Target_Day_Avg_Trans_Total: number;//PBI 90342 Retail fields
  Planned_Day_Avg_Trans_Total: number; //PBI 90342 Retail fields
  planned_cost_currency: string;
  is_confirmed: number;
  is_sales_mix_confirmed: number; // specific to retail
  guest_count_number: number;
  guest_count_type: GuestCountType;
  guest_count_variances: Array<number>;
  menu_structure: string; // name of the menu structure
  recid: number; // id of the menu structure for this day
  warnings?: Array<string>;
  // retail menu data
  menu_planned_margin: number;
  menu_planned_margin_amount: number;
  menu_planned_sales: number;
  menu_planned_guest_count: number;
  ServingTimeForm: number; // TODO: convert this number into a string. number represents seconds passed since midnight
  serviceLocation: string;
  UserTimeZone: string;
  CostCenterTimeZone: string;
  DisplayOrderSequence: number;
}

export const isValid = (toCheck?: IDayPlanData): boolean => {
  return toCheck !== undefined && toCheck !== null && toCheck.recid !== 0;
};

export enum GuestCountType {
  Estimated = "Estimated",
  ToProduce = "ToProduce",
  Actual = "Actual",
}

export const getNextGuestCountType = (type: GuestCountType): GuestCountType => {
  switch (type) {
    case GuestCountType.Estimated:
      return GuestCountType.ToProduce;
    case GuestCountType.ToProduce:
      return GuestCountType.Actual;
    default:
      return type;
  }
};

export const getCurrentVariance = (data: IDayPlanData) => {
  switch (data.guest_count_type) {
    case GuestCountType.Estimated:
      return data.guest_count_variances[0];
    case GuestCountType.ToProduce:
      return data.guest_count_variances[1];
    case GuestCountType.Actual:
      return data.guest_count_variances[2];
    default:
      throw new Error(`Unknown guest count type: ${data.guest_count_type}`);
  }
};

export const isTimeZoneAligned = (data?: IDayPlanData): boolean => {
  return data !== undefined && data !== null && data.UserTimeZone === data.CostCenterTimeZone;
};