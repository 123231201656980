import { IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { monthDayLabelStyle, wrong } from "../StylingUtils";
import { MarginPill } from "../SharedComponents/MarginPill";
import { DynLabel } from "../Services/DynService";

const marginStyle = (isWrong: boolean) =>
  ({
    root: [isWrong ? { color: wrong } : {}],
  } as IStackStyles);

interface IMonthDayMargin {
  plannedMargin: number;
  targetMargin: number;
  isWrong?: boolean;
  styling?: IStackStyles;
}

export const MonthDayMargin: React.FC<IMonthDayMargin> = (props) => {
  const isWrong = props.isWrong ?? props.plannedMargin < props.targetMargin;

  return (
    <Stack horizontalAlign="start" tokens={{ childrenGap: 1 }}>
      <span style={monthDayLabelStyle}>{DynLabel("PlannedMargin")}</span>
      <MarginPill
        targetMargin={props.targetMargin}
        plannedMargin={props.plannedMargin}
        isWrong={isWrong}
        styling={marginStyle(isWrong)}
      />
    </Stack>
  );
};
