// Here, we rely on the fact that D365 already provide Globalize and Intl
declare const Globalize: any;
declare const Intl: any;

let daysNames: Array<string>;
let monthsNames: Array<string>;
let monthsAbbr: Array<string>;
let firstDayOfWeek: number;

export const initDaysAndMonthsNames = () => {
  const currentCultureInfo: any = Globalize.cultures[Globalize.cultureSelector];

  daysNames = currentCultureInfo?.calendar?.days?.names;
  monthsNames = currentCultureInfo?.calendar?.months?.names;
  monthsAbbr = currentCultureInfo?.calendar?.months?.namesAbbr;

  if (
    !daysNames ||
    !monthsNames ||
    !monthsAbbr ||
    daysNames.length < 7 ||
    monthsNames.length < 12 ||
    monthsAbbr.length < 12
  ) {
    throw new Error("Issue during retrieval of days and months names from Globalize object.");
  }

  firstDayOfWeek = Number(currentCultureInfo?.calendar?.firstDay);
  if (firstDayOfWeek < 0 || firstDayOfWeek > 6) {
    throw new Error("Issue during retrieval of first day of week from Globalize object.");
  }
};

export const getDayName = (index: number) => {
  return daysNames[index];
};

export const getMonthName = (index: number) => {
  return monthsNames[index];
};

export const getMonthAbbr = (index: number) => {
  return monthsAbbr[index];
};

export const formatCurrencyNumber = (alpha3currency: string, toFormat: number) => {
  // Temp
  if (alpha3currency === "") return toFormat;
  return Intl.NumberFormat(Globalize.cultureSelector, { style: "currency", currency: alpha3currency }).format(toFormat);
};

export const getUserFirstDay = (): number => {
  return firstDayOfWeek;
};

export const getWeekDaysNames = (weekStartsOn: number) => {
  const indexArray = new Array<number>(7);
  let i: number;
  for (i = 0; i < 7; i++) {
    let indexToStore = i + weekStartsOn;
    if (indexToStore > 6) indexToStore = indexToStore - 7;
    indexArray[i] = indexToStore;
  }
  return indexArray.map((index) => getDayName(index));
};
