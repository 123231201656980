import { Stack } from "@fluentui/react";
import React, { useContext, useEffect } from "react";

import { ISelectionContext, useRecipeSelectionReducer } from "../Hooks/SelectionReducer";
import { DataContext } from "../Services/DataService";
import { DayMenu } from "./DayMenu";
import DayViewHeader from "./DayViewHeader";
import { DayMenuHeader } from "./DayMenuHeader";
import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { IDayPlanData } from "../Models/IDayPlanData";
import { useCollapseState } from "../Hooks/ICanCollapse";
import { PeriodContext } from "../Services/PeriodContextService";

interface IDayView {
  currentDay: Date;
  displayTechnicalName: boolean;
  isUserManager: boolean;
}

interface IDayViewContext extends ISelectionContext {
  currentDay: Date;
  isUserManager: boolean;
}

export const DayViewContext = React.createContext<IDayViewContext | null>(null);

const DayView: React.FC<IDayView> = (props) => {
  const currentDataContext = useContext(DataContext);
  const periodContext = useContext(PeriodContext);
  const { isCollapsed, handleCollapse } = useCollapseState();
  const [selectedRecipes, dispatch, checkSelection] = useRecipeSelectionReducer("day", []);

  // Clean selection when user navigates to another day
  useEffect(() => {
    dispatch({ type: "set", ids: [] });
  }, [props.currentDay]);

  useEffect(() => {
    if (currentDataContext !== null) {
      checkSelection(currentDataContext);
    }
  }, [currentDataContext]);

  /**
   *
   * Day menu is rendered with a "row" philosophy
   *
   * To facilitate redesign and for a better management of column widths,
   * it might be interesting to think about a "column-first" rendering.
   *
   * This might imply to rethink data organization as well.
   *
   */

  let menuStructures: IAXMenuStructureData[] | null = null;
  let headerData: IDayPlanData[] = [];
  let costData: IDayPlanData | null = null;
  const menuStructureData: IDayPlanData[] = [];

  if (currentDataContext !== null) {
    menuStructures = currentDataContext.getMenuStructures();
    if (menuStructures !== null && menuStructures.length > 0) {
      costData = menuStructures.reduce((p, c) => {
        const hData = currentDataContext.getMenuStructureData(props.currentDay, c.menu_structure);
        if (!hData) return p;
        headerData.push(hData);
        menuStructureData.push(hData);
        p.menu_planned_cost += hData.menu_planned_cost;
        p.menu_target_cost += hData.menu_target_cost;
        p.guest_count_number += hData.guest_count_number;
        return p;
      }, { menu_planned_cost: 0, menu_target_cost: 0, guest_count_number: 0 } as IDayPlanData)
    }
    menuStructures.sort((a, b) => (headerData.find(h => h.menu_structure === a.menu_structure)?.DisplayOrderSequence || 0) - (headerData.find(h => h.menu_structure === b.menu_structure)?.DisplayOrderSequence || 0));
  }
  return (
    currentDataContext && (
      <Stack>
        <DayViewHeader currentDay={props.currentDay} />
        <Stack tokens={{ childrenGap: 1 }}>
          <DayViewContext.Provider
            value={{
              isUserManager: props.isUserManager,
              currentDay: props.currentDay,
              selectedElements: selectedRecipes,
              dispatchSelectionEvent: dispatch,
            }}>
            {menuStructures && menuStructures.length > 0 && <DayMenuHeader
              key={props.currentDay.valueOf()}
              currentDay={props.currentDay}
              isCollapsed={isCollapsed}
              menuStructureName={(menuStructures[0].menu_structure) || ""}
              menuNameDisplayOverride={periodContext?.getMenuPartName()}
              budgetOverride={costData?.menu_target_cost}
              plannedCostOverride={costData?.menu_planned_cost}
              guestCountOverride={costData?.guest_count_number}
              isStructRetail={menuStructures.some((s) => Boolean(s.is_retail))} // if any part is retail, the whole is
              headerData={headerData[0]}
              isDayAllowed={true}
              onCollaspeClick={handleCollapse}
            />}
            {menuStructures?.filter(ms => menuStructureData.find(msd => ms.menu_structure === msd.menu_structure)).map((structure, index) => {
              return (
                <DayMenu
                  key={structure.menu_structure + "_" + index}
                  currentDay={props.currentDay}
                  menuStructure={structure}
                  displayTechnicalName={props.displayTechnicalName}
                  parentCollapsed={isCollapsed}
                  useServiceStation={periodContext?.serviceStationFlag() || false}
                  // onCollapseClick={onChildCollapseClick}
                />
              );
            })}
          </DayViewContext.Provider>
        </Stack>
      </Stack>
    )
  );
};

export default DayView;
