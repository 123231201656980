import { IStackStyles } from "@fluentui/react";

import { almostFixedWidth } from "../StylingUtils";

export enum DayDisplay {
  None = 0,
  Standard,
  Retail,
  Both,
}

export const nbDayColumns = 17;

export const indexes = {
  idxRecipe: 0,
  idxPortionFactor: 1,
  idxPlannedQty: 2,
  idxPlannedCost: 3,
  idxPrice: 4,
  idxRecipeTargetCost: 5,
  idxProductionOrder: 6,
  idxPlannedMargin: 7,
  idxTargetMargin: 8,
  idxActualSellingPrice: 9,
  idxPlannedSellingPrice: 10,
  idxTime: 11,
  idxDifficulty: 12,
  idxAllergens: 13,
  idxBatch: 14,
  idxSalesMix: 15,
  idxPlannedSales :16
};
export const orderedIndexes: Array<number> = new Array(nbDayColumns);
export const shouldBeDisplay: Array<DayDisplay> = new Array(nbDayColumns);
export const columnStyles: Array<IStackStyles> = new Array(nbDayColumns);

export const initDayViewHelper = () => {
  initOrderedIndexes();
  initStyles();
  initDisplayAccordingToMenu();
};

const initOrderedIndexes = () => {
  // If order of columns change, juste change here, the rest will follow
  orderedIndexes[0] = indexes.idxRecipe;
  orderedIndexes[1] = indexes.idxPortionFactor;
  orderedIndexes[2] = indexes.idxSalesMix;
  orderedIndexes[3] = indexes.idxPlannedQty;
  orderedIndexes[4] = indexes.idxPlannedCost;
  orderedIndexes[5] = indexes.idxPrice;
  orderedIndexes[6] = indexes.idxRecipeTargetCost;
  orderedIndexes[7] = indexes.idxPlannedSellingPrice;
  orderedIndexes[8] = indexes.idxTargetMargin;
  orderedIndexes[9] = indexes.idxActualSellingPrice;
  orderedIndexes[10] = indexes.idxPlannedSales;
  orderedIndexes[11] = indexes.idxPlannedMargin;
  orderedIndexes[12] = indexes.idxProductionOrder;
  orderedIndexes[13] = indexes.idxTime;
  orderedIndexes[14] = indexes.idxDifficulty;
  orderedIndexes[15] = indexes.idxAllergens;
  orderedIndexes[16] = indexes.idxBatch;
};

const initStyles = () => {
  columnStyles[indexes.idxRecipe] = almostFixedWidth("25%");
  columnStyles[indexes.idxPortionFactor] = almostFixedWidth("7%");
  columnStyles[indexes.idxSalesMix] = almostFixedWidth("7%");
  columnStyles[indexes.idxPlannedQty] = almostFixedWidth("7%");
  columnStyles[indexes.idxPlannedCost] = almostFixedWidth("16%");
  columnStyles[indexes.idxPrice] = almostFixedWidth("8%");
  columnStyles[indexes.idxRecipeTargetCost] = almostFixedWidth("8%");
  columnStyles[indexes.idxProductionOrder] = almostFixedWidth("7%");
  columnStyles[indexes.idxPlannedMargin] = almostFixedWidth("8%");
  columnStyles[indexes.idxTargetMargin] = almostFixedWidth("8%");
  columnStyles[indexes.idxActualSellingPrice] = almostFixedWidth("8%");
  columnStyles[indexes.idxPlannedSellingPrice] = almostFixedWidth("8%");
  columnStyles[indexes.idxTime] = almostFixedWidth("5%");
  columnStyles[indexes.idxDifficulty] = almostFixedWidth("5%");
  columnStyles[indexes.idxAllergens] = almostFixedWidth("8%");
  columnStyles[indexes.idxBatch] = almostFixedWidth("4%");
  columnStyles[indexes.idxPlannedSales] = almostFixedWidth("16%");
};

const initDisplayAccordingToMenu = () => {
  shouldBeDisplay[indexes.idxRecipe] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxPortionFactor] = DayDisplay.Standard;
  shouldBeDisplay[indexes.idxSalesMix] = DayDisplay.Retail;
  shouldBeDisplay[indexes.idxPlannedQty] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxPlannedCost] = DayDisplay.Standard;
  shouldBeDisplay[indexes.idxPrice] = DayDisplay.Standard;
  shouldBeDisplay[indexes.idxRecipeTargetCost] = DayDisplay.Standard;
  shouldBeDisplay[indexes.idxProductionOrder] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxPlannedMargin] = DayDisplay.Retail;
  shouldBeDisplay[indexes.idxTargetMargin] = DayDisplay.None;
  shouldBeDisplay[indexes.idxActualSellingPrice] = DayDisplay.None;
  shouldBeDisplay[indexes.idxPlannedSellingPrice] = DayDisplay.Retail;
  shouldBeDisplay[indexes.idxTime] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxDifficulty] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxAllergens] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxBatch] = DayDisplay.Both;
  shouldBeDisplay[indexes.idxPlannedSales] = DayDisplay.Retail;
};
