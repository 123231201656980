import { DirectionalHint, FontWeights, Stack, Text } from "@fluentui/react";
import React, { useContext } from "react";
import { PeriodContext } from "../Services/PeriodContextService";
import { coolBlue, pureWhite } from "../StylingUtils";
import { DataContext, IDataContext } from "../Services/DataService";
import { IAXMenuStructureData } from "../Models/IDataFromAx";

export interface IAvaMenuPlannerTooltip {
  title?: string;
  text?: string;
  RetailTitle?: string; //90342
  RetailText?: string; //90342
  isMenuRetail?: boolean;
}

export const tooltipCalloutProps = {
  gapSpace: 0,
  directionalHint: DirectionalHint.bottomCenter,
  backgroundColor: coolBlue,
  styles: { beakCurtain: { backgroundColor: coolBlue } },
};

export const AvaMenuPlannerTooltip: React.FC<IAvaMenuPlannerTooltip> = (props) => {
  const currentDataContext = useContext(DataContext);

  const isMenuRetailArr = currentDataContext && currentDataContext.getMenuStructures().map((menu) => menu.is_retail);
  const isMenuRetail = isMenuRetailArr && Boolean(Number(isMenuRetailArr[0]));

  if (isMenuRetail) {
    if (props.RetailTitle) {
      return (
        currentDataContext && (
          <Stack
            verticalAlign="stretch"
            verticalFill
            tokens={{ childrenGap: 5, padding: "20px" }}
            styles={{ root: { fontSize: "14px", color: pureWhite } }}>
            <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>{props.RetailTitle}</Text>
            <Text styles={{ root: { fontWeight: FontWeights.semilight } }}>{props.RetailText}</Text>
          </Stack>
        )
      );
    } else {
      return (
        <Stack
          verticalAlign="stretch"
          verticalFill
          tokens={{ childrenGap: 5, padding: "20px" }}
          styles={{ root: { fontSize: "14px", color: pureWhite } }}>
          <Text styles={{ root: { fontWeight: FontWeights.semilight } }}>{props.RetailText}</Text>
        </Stack>
      );
    }
  } else {
    if (props.title) {
      return (
        <Stack
          verticalAlign="stretch"
          verticalFill
          tokens={{ childrenGap: 5, padding: "20px" }}
          styles={{ root: { fontSize: "14px", color: pureWhite } }}>
          <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>{props.title}</Text>
          <Text styles={{ root: { fontWeight: FontWeights.semilight } }}>{props.text}</Text>
        </Stack>
      );
    } else {
      return (
        <Stack
          verticalAlign="stretch"
          verticalFill
          tokens={{ childrenGap: 5, padding: "20px" }}
          styles={{ root: { fontSize: "14px", color: pureWhite } }}>
          <Text styles={{ root: { fontWeight: FontWeights.semilight } }}>{props.text}</Text>
        </Stack>
      );
    }
  }
};
