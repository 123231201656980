import { useId } from "@uifabric/react-hooks";
import { DirectionalHint, FontIcon, TooltipHost } from "@fluentui/react";
import React from "react";

import { formatStringArrayAsString } from "../StringUtils";

interface IReportWarning {
  warnings?: Array<string>;
  cssClasses?: string;
}

export const WarningIcon: React.FC<IReportWarning> = (props) => {
  const tooltipId = useId("tooltip");

  if (props.warnings && props.warnings.length > 0)
    return (
      <TooltipHost
        id={tooltipId}
        content={formatStringArrayAsString(props.warnings)}
        directionalHint={DirectionalHint.bottomCenter}
        styles={{ root: { display: "inline-flex" } }}>
        <FontIcon iconName="Warning" className={`DefaultCursor ${props.cssClasses}`} />
      </TooltipHost>
    );
  else return null;
};
