import { ActionButton, IButtonStyles, IStackStyles, mergeStyleSets } from "@fluentui/react";
import React from "react";

import { IDayPlanData, isValid } from "../Models/IDayPlanData";
import { DynAddMenu, DynAddRecipe, DynLabel } from "../Services/DynService";
import { coolBlue, darkerRed, lightRed } from "../StylingUtils";

const baseButtonStyle: IButtonStyles = {
  root: {
    fontSize: "12px",
    flexGrow: 0,
  },
  rootHovered: {
    cursor: "pointer",
  },
};

const addRecipeButtonStyle: IButtonStyles = {
  root: {
    color: coolBlue,
  },
};

const createMenuButtonStyle: IButtonStyles = {
  root: {
    color: lightRed,
  },
  rootHovered: {
    color: darkerRed,
  },
  iconHovered: {
    color: darkerRed,
  },
};

const disabledGreyStyle: IStackStyles = {
  root: {
    color: "#B69FB6",
  },
};

interface IWeekAddRecipeButton {
  menuDate: Date;
  parentStructure?: IDayPlanData;
  structureName: string;
  subMenuName: string;
  serviceLocation: string;
  serviceStation: string;
  disabled: boolean;
  styling?: IButtonStyles;
}

export const CreateMenuAddRecipeButton: React.FC<IWeekAddRecipeButton> = (props) => {
  const menuCreated = isValid(props.parentStructure);
  const myStyle = menuCreated ? addRecipeButtonStyle : createMenuButtonStyle;
  const disabledGrey = props.disabled ? disabledGreyStyle : null;

  return (
    <ActionButton
      disabled={props.disabled}
      styles={mergeStyleSets(baseButtonStyle, myStyle, props.styling)}
      iconProps={{ iconName: "CirclePlus", styles: mergeStyleSets(myStyle, disabledGrey) }}
      onClick={(ev?) => {
        if (menuCreated) DynAddRecipe(props.parentStructure!.recid, props.subMenuName, props.serviceLocation, props.serviceStation);
        else DynAddMenu(props.menuDate, props.structureName);
      }}>
      {menuCreated ? DynLabel("AddRecipe") : DynLabel("CreateMenu")}
    </ActionButton>
  );
};
