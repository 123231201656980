export const headerDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export function getJsDateFromAXJson(axJsonDate: string | undefined) {
  if (axJsonDate) {
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
    const splittedValues = reMsAjax.exec(axJsonDate);
    if (splittedValues) {
      return cloneUTCDate(new Date(+splittedValues[1]));
    }
  }
}

export function cloneUTCDate(date: Date): Date {
  if (!date) return date;

  const utcDateTime = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return new Date(utcDateTime);
}

export function getNextDate(date: Date, type: "day" | "week" | "month", increment: number): Date {
  if (!date) return date;

  let result = cloneUTCDate(date);

  switch (type) {
    case "day":
      result.setUTCDate(date.getUTCDate() + increment);
      break;
    case "week":
      result.setUTCDate(date.getUTCDate() + 7 * increment);
      break;
    case "month":
      // setUTCMonth takes into account the day of date used:
      // e.g. if date is 03/31, result.setUTCMonth(date.getUTCMonth() + 1) returns 05/01
      // so use first day of month to ensure we really have next month
      result = getMonthFirstDay(result);
      result.setUTCMonth(date.getUTCMonth() + 1 * increment);
      break;
  }
  return result;
}

export function getFirstDayOfWeek(dateInWeek: Date, localFirstDayOfWeek: number) {
  if (!dateInWeek) return dateInWeek;

  const result = cloneUTCDate(dateInWeek);
  result.setUTCHours(0, 0, 0, 0);

  const dateDay = result.getUTCDay();
  if (dateDay === localFirstDayOfWeek) {
    return result;
  }

  let diff: number;
  switch (localFirstDayOfWeek) {
    case 0: // Sunday
      diff = result.getUTCDate() - dateDay;
      result.setUTCDate(diff);
      break;
    case 1: // Monday
      diff = result.getUTCDate() - dateDay + (dateDay === 0 ? -6 : 1); // adjust when day is sunday
      result.setUTCDate(diff);
      break;
    case 6: // Saturday
      diff = result.getUTCDate() - dateDay - 1;
      result.setUTCDate(diff);
      break;
  }

  return result;
}

export function getWeeksLastDay(firstDayOfWeek: Date) {
  if (!firstDayOfWeek) return firstDayOfWeek;

  const result = cloneUTCDate(firstDayOfWeek);
  result.setUTCDate(result.getUTCDate() + 6);
  return result;
}

export function getWeekNumber(d: Date) {
  if (!d) return d;

  // Copy date so don't modify original
  const paramDate = cloneUTCDate(d);
  paramDate.setUTCHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  paramDate.setUTCDate(paramDate.getUTCDate() + 3 - ((paramDate.getUTCDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(paramDate.getUTCFullYear(), 0, 4, 0, 0, 0, 0);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((paramDate.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getUTCDay() + 6) % 7)) / 7);
}

export function getMonthFirstDay(anyDateInMonth: Date): Date {
  if (!anyDateInMonth) return anyDateInMonth;

  return new Date(Date.UTC(anyDateInMonth.getUTCFullYear(), anyDateInMonth.getUTCMonth(), 1, 0, 0, 0, 0));
}

export function getAllDatesInMonth(anyDateInMonth: Date): Date[] {
  if (!anyDateInMonth) return anyDateInMonth;

  let firstDayOfMonth = getMonthFirstDay(anyDateInMonth);
  let result: Date[] = [firstDayOfMonth];

  let nextDay = cloneUTCDate(firstDayOfMonth);
  nextDay.setUTCDate(nextDay.getUTCDate() + 1);

  while (nextDay.getUTCMonth() === firstDayOfMonth.getUTCMonth()) {
    result.push(cloneUTCDate(nextDay));
    nextDay.setUTCDate(nextDay.getUTCDate() + 1);
  }

  return result;
}

export function getAllWeekNumbersForMonth(anyDateInMonth: Date): number[] {
  if (!anyDateInMonth) return [];

  let result: number[] = [];

  let monthDates = getAllDatesInMonth(anyDateInMonth);
  let lastWeekNumber = 0;

  for (let i = 0; i < monthDates.length; i++) {
    let currentWeekNumber = getWeekNumber(monthDates[i]);

    if (currentWeekNumber !== lastWeekNumber) {
      result.push(currentWeekNumber);
    }

    lastWeekNumber = currentWeekNumber;
  }

  return result;
}

export function getWeeksPreviousDays(d: Date): Date[] {
  let result: Date[] = [];
  let numberOfDays = d.getUTCDay() === 0 ? 6 : d.getUTCDay() - 1;

  for (let i = numberOfDays; i >= 1; i--) {
    let date = cloneUTCDate(new Date(new Date(d).setUTCDate(d.getUTCDate() - i)));
    result.push(date);
  }

  return result;
}

export function getWeeksNextDays(d: Date): Date[] {
  let result: Date[] = [];
  let numberOfDays = d.getUTCDay() === 0 ? 0 : 7 - d.getUTCDay();

  for (let i = 1; i <= numberOfDays; i++) {
    let date = cloneUTCDate(new Date(new Date(d).setUTCDate(d.getUTCDate() + i)));
    result.push(date);
  }

  return result;
}

export function getWeekFromFirstDay(firstDay: Date): Array<Date> {
  if (!firstDay) return [];

  const result = [];
  for (let i = 0; i < 7; i++) {
    result.push(getNextDate(firstDay, "day", i));
  }

  return result;
}

export function getWeeksWithDates(d: Date, weekStartsOn: number): Map<number, Date[]> {
  let result: Map<number, Date[]> = new Map();

  if (!d) return result;

  const firstDayOfMonth = getMonthFirstDay(d);
  const firstDayForCalendar = getFirstDayOfWeek(firstDayOfMonth, weekStartsOn);

  let weekFirstDay = firstDayForCalendar;
  let weekNumber: number;
  let weekDates: Array<Date>;
  // let's use a do...while because first day of calendar may not be in the same month
  do {
    weekNumber = getWeekNumber(weekFirstDay);
    weekDates = getWeekFromFirstDay(weekFirstDay);

    result.set(weekNumber, [...weekDates]);

    weekFirstDay = getNextDate(weekDates[weekDates.length - 1], "day", 1);
  } while (weekFirstDay.getUTCMonth() === firstDayOfMonth.getUTCMonth());

  return result;
}

export function arrayFirstLast<T>(array: Array<T>): [T, T] {
  if (array.length < 1) throw new Error("empty array");
  return [array[0], array[array.length - 1]];
}

// create an array of numbers between min and max (included)
export function range(min: number, max: number): Array<number> {
  return Array.from({ length: max - min + 1 }, (_, i) => min + i);
}

export function getTimeFromTimeOfDay(seconds?: number): string {
  if (seconds === undefined) return "";

  const date = new Date(0);
  date.setSeconds(seconds!);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${hours12}:${minutesString} ${ampm}`; 
}

export function getISODate(date: Date): string {
  if (!date) return "";
  return date.toISOString().split("T")[0];
}