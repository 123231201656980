import { Link } from "@fluentui/react";
import React from "react";

import { DynLabel, DynShowDayPlanLineRecipe } from "../Services/DynService";
import { darkestGrey } from "../StylingUtils";

const recipeTitleStyle = (view: "week" | "day", isBlank: boolean): React.CSSProperties => {
  return {
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: darkestGrey,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: !isBlank ? 600 : 300,
    fontStyle: !isBlank ? "normal" : "italic",
    fontSize: view === "week" ? 12 : 14,
    paddingTop: view === "week" ? 2 : 0,
  };
};

interface IRecipeTitle {
  recipeId: number;
  recipeName: string;
  displayStyle: "week" | "day";
}

export const RecipeTitle: React.FC<IRecipeTitle> = (props) => {
  const isBlank = props.recipeName === "";

  return (
    <Link
      disabled={isBlank}
      href={"#"}
      onClick={(ev?) => !isBlank && DynShowDayPlanLineRecipe(props.recipeId)}
      style={{ maxWidth: "100%" }}>
      <div style={recipeTitleStyle(props.displayStyle, isBlank)}>
        {!isBlank ? props.recipeName : DynLabel("EmptyRecipeName")}
      </div>
    </Link>
  );
};
