export function formatStringArrayAsString(array: Array<string>): string {
  let stringValue = "";
  if (array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      stringValue += array[i];
      if (i !== array.length - 1) stringValue += ", ";
    }
  }
  return stringValue;
}

export function imitateAXStrFmt(stringToModify: string, ...args: Array<string>) {
  return stringToModify.replace(/%(\d+)/g, function (match, number) {
    return typeof args[number - 1] != "undefined" ? args[number - 1] : match;
  });
}

export function yesNoToBool(value: string): boolean {
  return value === "Yes";
}