import React, { useContext, useEffect, useState } from 'react';

import { useCollapseState } from '../Hooks/ICanCollapse';
import { IAXMenuStructureData } from '../Models/IDataFromAx';
import { pureWhite } from '../StylingUtils';
import { WeekMenuStructureFooter } from './WeekMenuStructureFooter';
import { WeekMenuStructureHeader } from './WeekMenuStructureHeader';
import { WeekSubMenu } from './WeekSubMenu';
import { DataContext } from '../Services/DataService';
import { WeekViewContext } from './WeekView';

interface IWeekMenuStructure {
  menuStructure: IAXMenuStructureData;
  displayTechnicalName: boolean;
  parentCollapsed: boolean;
  // parentChecked: boolean;
}

export const WeekMenuStructure: React.FC<IWeekMenuStructure> = (props) => {
  const { isCollapsed, handleCollapse } = useCollapseState();
  const { isCollapsed: serviceStationCollapsed, handleCollapse: handleServiceStationCollapse } = useCollapseState();
  const dataContextValue = useContext(DataContext);
  const weekContext = useContext(WeekViewContext);

  useEffect(() => {
    if (isCollapsed !== props.parentCollapsed) {
      handleCollapse();
    }
  }, [props.parentCollapsed]);

  // var serviceStations = Object.groupBy(props.menuStructure.submenus, ({ serviceStation }) => serviceStation) || [];
  // Get the dayplans for every day of the week
  var dayPlanLineData = weekContext?.weekDates.map(date => dataContextValue?.getDayPlanLineData(date, props.menuStructure.menu_structure) ?? []).flat();
  var serviceStations;
  var submenus: any = props.menuStructure.submenus;
  if (dayPlanLineData && dayPlanLineData.length > 0) {
    const serviceStationsMap = dayPlanLineData.map(({ submenu, ServiceStation, ServiceStationSortOrder, SubMenuSortOrder }) => ({ submenu, ServiceStation, ServiceStationSortOrder, SubMenuSortOrder }));
    serviceStations = Object.groupBy(serviceStationsMap, ({ ServiceStation }) => ServiceStation) || [];
    submenus = serviceStationsMap.slice().sort((a, b) => a.SubMenuSortOrder - b.SubMenuSortOrder);
  }
  return (
    <div style={{ color: pureWhite }}>
      <WeekMenuStructureHeader
        key={`${props.menuStructure.menu_structure}-WeekMenuStructureHeader`}
        menuStructureName={props.menuStructure.menu_structure}
        setupDays={props.menuStructure.setupDays}
        isCollapsed={isCollapsed}
        onCollaspeClick={handleCollapse}
        isMenuRetail={Boolean(props.menuStructure.is_retail)}
      />
      <div hidden={isCollapsed}>
        {serviceStations && Object.entries(serviceStations).map((ss) => {
          let header = null;
          if (ss[0] !== 'undefined' && ss[0] !== undefined) {
            header = <WeekMenuStructureHeader
              menuNameDisplayOverride={ss[0]}
              key={`${props.menuStructure.menu_structure}-WeekMenuStationHeader`}
              menuStructureName={props.menuStructure.menu_structure}
              setupDays={props.menuStructure.setupDays}
              isCollapsed={serviceStationCollapsed}
              onCollaspeClick={handleServiceStationCollapse}
              isServiceStation={true}
              isMenuRetail={Boolean(props.menuStructure.is_retail)} />;
          }
          return <div key={ss[0]}>
            {header}
            <div hidden={serviceStationCollapsed}>
              {props.menuStructure.submenus
              .filter(sm => ss[1] && ss[1].map(s => s.submenu).includes(sm.submenu))
              .map((value) => (
              // {submenus.filter(value => value.ServiceStation === ss[0]).map((value) => (
                <WeekSubMenu
                  key={`${props.menuStructure.menu_structure}-${value.submenu}`}
                  serviceStation={ss[0]}
                  parentStructure={props.menuStructure}
                  displayTechnicalName={props.displayTechnicalName}
                  subMenuName={value.submenu}
                />
              ))}
            </div>
          </div>
        })}
      </div>
      {/* Retail menus have no footer for the moment */}
      {!Boolean(props.menuStructure.is_retail) && (
        <div hidden={isCollapsed}>
          <WeekMenuStructureFooter
            key={`${props.menuStructure.menu_structure}-WeekMenuStructureFooter`}
            menuStructure={props.menuStructure}
          />
        </div>
      )}
    </div>
  );
};
