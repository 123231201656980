import { Dropdown, ILabelStyles, IStackStyles, Label, mergeStyleSets, Stack, Toggle } from "@fluentui/react";
import React, { useContext } from "react";

import { DynLabel } from "./Services/DynService";
import { lightestGrey, primaryBlue, pureWhite, strongBlue } from "./StylingUtils";
import { PeriodContext } from "./Services/PeriodContextService";

const myStyle: IStackStyles = {
  root: {
    displayName: "Header",
    minHeight: "53px",
    backgroundColor: pureWhite,
    paddingLeft: 20,
  },
};

const labelColumnText: ILabelStyles = {
  root: {
    displayName: "labelColumnText",
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "14px",
    color: primaryBlue,
    padding: 0,
  },
};

const labelColumnHeader: ILabelStyles = {
  root: {
    displayName: "labelColumnHeader",
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    fontSize: "11px",
    color: primaryBlue,
    padding: 0,
  },
};

const applicableDates: ILabelStyles = {
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "normal",
    color: primaryBlue,
  },
};

const getPlanningFromToLabel = (fromDate: string, toDate: string, compact: boolean = false) => {
  return (!compact ? `${DynLabel("PlanningFrom")} ` : "") + `${fromDate} ${DynLabel("PlanningTo")} ${toDate}`;
};

const viewSelector: IStackStyles = {
  root: {
    displayName: "ViewSelector",
    justifySelf: "stretch",
    fontSize: "12px",
    fontWeight: "normal",
    color: strongBlue,
  },
};

const viewSelectorItem: ILabelStyles = {
  root: {
    //for unknown reason, cursor does not seem to work in D365 if we do it here
    //cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "80px",
    backgroundColor: lightestGrey,
    color: primaryBlue,
  },
};

const viewSelectorSelectedItem: ILabelStyles = {
  root: {
    //for unknown reason, cursor does not seem to work in D365 if we do it here
    //cursor: "default",
    backgroundImage: "none",
    fontWeight: "bold",
    backgroundColor: primaryBlue,
    color: pureWhite,
  },
};

const headerTitleStyles: ILabelStyles = {
  root: {
    fontSize: 20,
    color: primaryBlue,
  },
};

interface ILabelColumn {
  header: string;
  text: string;
}

const LabelColumn: React.FC<ILabelColumn> = (props) => (
  <Stack verticalAlign="center" tokens={{ childrenGap: 2 }}>
    <Label styles={labelColumnHeader}>{`${props.header}`}</Label>
    <Label styles={labelColumnText}>{`${props.text}`}</Label>
  </Stack>
);

interface IHeader {
  selectedView: number;
  onViewChange: (selectedView: number) => void;
  menuCycleName: string;
  warehouseId: string;
  fromDateStr: string;
  toDateStr: string;
  displayTechnicalNames: (v?: boolean) => void;
  headerTitle?: string;
  compactMode?: boolean;
  useServiceLocation: boolean;
}

interface IViewSelector {
  headerProps: IHeader;
  viewNumber: number;
  viewLabel: string;
}

const ViewSelector: React.FC<IViewSelector> = (props) => (
  <Label
    onClick={() => props.headerProps.onViewChange(props.viewNumber)}
    style={props.headerProps.selectedView !== props.viewNumber ? { cursor: "pointer" } : { cursor: "default" }}
    styles={
      props.headerProps.selectedView !== props.viewNumber
        ? viewSelectorItem
        : mergeStyleSets(viewSelectorItem, viewSelectorSelectedItem)
    }>
    {props.viewLabel}
  </Label>
);

const Header: React.FC<IHeader> = (props) => {
  const periodContext = useContext(PeriodContext);
  const locations = periodContext?.getServiceLocationList()?.map((location, index) => ({ key: location, text: location, selected: index === 0 })) ?? [];
  if (locations && locations.length > 0) periodContext?.setServiceLocation(locations[0].text);

  return (
    <Stack horizontal verticalAlign="stretch" tokens={{ childrenGap: 20 }} styles={myStyle}>
      {props.compactMode && props.headerTitle && (
        <Stack verticalAlign="center" tokens={{ childrenGap: 2 }}>
          <Label styles={headerTitleStyles}>{props.headerTitle}</Label>
        </Stack>
      )}
      <LabelColumn header={DynLabel("MenuCycleName")} text={props.menuCycleName} />
      <LabelColumn header={DynLabel("Warehouse")} text={props.warehouseId} />
      {props.useServiceLocation && <Stack verticalAlign="center" tokens={{ childrenGap: 2 }}>
        <Label styles={labelColumnHeader}>{DynLabel("ServiceLocation")}</Label>
        <Dropdown options={locations} onChange={(_e, option) => {
          if (option)
            periodContext?.setServiceLocation(option.text);
        }} />
      </Stack>}
      {props.compactMode ? (
        <React.Fragment>
          <LabelColumn
            header={`${DynLabel("Period")}`}
            text={getPlanningFromToLabel(props.fromDateStr, props.toDateStr, true)}
          />
          <Label styles={applicableDates}>&nbsp;</Label>
        </React.Fragment>
      ) : (
        <Label styles={applicableDates}>{getPlanningFromToLabel(props.fromDateStr, props.toDateStr)}</Label>
      )}
      <Stack horizontal styles={viewSelector} tokens={{ childrenGap: 2 }}>
        <ViewSelector headerProps={props} viewNumber={1} viewLabel={DynLabel("Day")} />
        <ViewSelector headerProps={props} viewNumber={2} viewLabel={DynLabel("Week")} />
        <ViewSelector headerProps={props} viewNumber={3} viewLabel={DynLabel("Month")} />
      </Stack>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Toggle
          onText={DynLabel("TechnicalNames")}
          offText={DynLabel("TechnicalNames")}
          onChange={(ev, value?: boolean) => {
            props.displayTechnicalNames(value);
          }}
        />
      </div>
    </Stack>
  );
};

export default Header;
