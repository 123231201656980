import { FontIcon, IStackStyles, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { DynLabel } from "../Services/DynService";
import { formatCurrencyNumber } from "../Services/GlobalizeService";
import { monthDayLabelStyle, wrong } from "../StylingUtils";

const costStyle = (isWrong: boolean) =>
  ({
    root: [isWrong ? { color: wrong } : {}],
  } as IStackStyles);

interface IMonthDayTransactionValue {
  currency: string;
  transactionValue: number;
  noZero: boolean;
  isWrong: boolean;
  styling?: IStackStyles;
}

export const MonthDayTransactionValue: React.FC<IMonthDayTransactionValue> = (props) => {
  return (
    <Stack tokens={{ childrenGap: 1 }} styles={mergeStyleSets(costStyle(props.isWrong), props.styling)}>
      <span style={monthDayLabelStyle}>{DynLabel("PlannedTransactionValue")}</span>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <FontIcon iconName="Money" />
        <span>
          {props.noZero && props.transactionValue === 0
            ? "--"
            : formatCurrencyNumber(props.currency, props.transactionValue)}
        </span>
      </Stack>
    </Stack>
  );
};
