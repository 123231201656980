import {
  Checkbox,
  FontIcon,
  ICheckbox,
  ICheckboxStyles,
  IStackStyles,
  ITextField,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useRef, useState } from "react";

import { pureWhite } from "../StylingUtils";

enum ValidationState {
  NoChange = 0,
  Success = 1,
  Fail = 2,
}

const checkboxStyle: ICheckboxStyles = {
  root: {
    marginRight: 6,
    padding: 2,
    selectors: {
      ":hover .ms-Checkbox-text": {
        color: pureWhite,
      },
    },
  },
  checkbox: {
    backgroundColor: pureWhite,
  },
  text: {
    fontSize: 12,
    color: pureWhite,
  },
};

interface IEditableField {
  valueToEdit: number | string;
  isDisabled: boolean; // Disabling Editable field simply returns the component
  component: JSX.Element;
  displayEditIcon: boolean;
  updateValue: (value: any, checked?: boolean) => void;
  validateValue?: (value: any) => boolean;
  saveIndicator?: boolean; // display the "modified indicator"(to inform the user of not saved info) or not
  modifiedState?: boolean; // controls the modified state from outside
  withCheckbox?: boolean; // display a checkbox under the textfield
  checkboxLabel?: string;
  id: string;
  styling?: IStackStyles;
  editIconClassname?: string;
}

export const EditableField: React.FC<IEditableField> = (props) => {
  let [displayEditField, setDisplayEditField] = useState(false);
  let [modified, setModified] = useState(props.modifiedState);
  let [timeOutId, setTimeOutId] = useState(undefined as number | undefined);

  const focusableTF: React.RefObject<ITextField> = useRef(null);
  const myCheckbox: React.RefObject<ICheckbox> = useRef(null);

  function onKeyUp(event: React.KeyboardEvent<HTMLElement>) {
    if (event.which === 13 || event.keyCode === 13) {
      if (displayEditField) {
        const validationResult = setNewValue(focusableTF.current?.value, myCheckbox.current?.checked);
        // if success, call update function and close edit field
        // if no change, just close edit field
        // if fail, do nothing (leave textfield open for correction)
        switch (validationResult) {
          case ValidationState.Success:
            props.updateValue(focusableTF.current?.value, myCheckbox.current?.checked);
            setDisplayEditField(false);
            break;
          case ValidationState.NoChange:
            setDisplayEditField(false);
            break;
        }

        // prevent the timer set in onBlur() from firing and performing another validation
        clearTimeout(timeOutId);
      }
    }
    return true;
  }

  function onBlur(event: React.FocusEvent<HTMLElement>) {
    setTimeOutId(
      (timeOutId = setTimeout(
        (() => {
          const validationResult = setNewValue(focusableTF.current?.value, myCheckbox.current?.checked);
          // if success, call update function and close edit field
          // if no change or fail, just close edit field (free the user!)
          switch (validationResult) {
            case ValidationState.Success:
              props.updateValue(focusableTF.current?.value, myCheckbox.current?.checked);
              setDisplayEditField(false);
              break;
            case ValidationState.NoChange:
              setDisplayEditField(false);
              break;
            case ValidationState.Fail:
              setDisplayEditField(false);
              break;
          }
        }) as TimerHandler,
        350 // if we do not get the focus back in the next 350 msec, let's launch validation
      ))
    );
  }

  function onFocus(event: React.FocusEvent<HTMLElement>) {
    clearTimeout(timeOutId);
    if (!displayEditField) {
      setDisplayEditField(true);
    }
  }

  function setNewValue(newValue: any, checked?: boolean) {
    if (!props.validateValue || (props.validateValue && props.validateValue(newValue))) {
      if (props.valueToEdit?.toString().trim() !== newValue.toString().trim() || checked) {
        if (props.saveIndicator) {
          setModified(true);
        }
        return ValidationState.Success;
      }
      return ValidationState.NoChange;
    }
    return ValidationState.Fail;
  }

  return (
    <Stack
      horizontal
      verticalAlign="center"
      onFocus={props.isDisabled ? undefined : onFocus}
      onBlur={props.isDisabled ? undefined : onBlur}
      onKeyUp={props.isDisabled ? undefined : onKeyUp}
      styles={props.styling}>
      <div
        style={{
          display: displayEditField ? "none" : "inherit",
          maxWidth: displayEditField ? "none" : "100%",
        }}
        onClick={() => {
          if (!props.isDisabled) {
            setDisplayEditField(true);
            //delay to let the textbox appear
            setTimeout(() => {
              focusableTF.current?.focus();
            }, 100);
          }
        }}>
        <span
          style={{
            flexBasis: "auto",
            flexGrow: 1,
            flexShrink: 1,
          }}>
          {props.component}
        </span>{" "}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            flexBasis: "auto",
            flexGrow: 0,
            flexShrink: 0,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}>
          {props.displayEditIcon && (
            <FontIcon
              iconName={props.isDisabled ? "Uneditable2" : modified || props.modifiedState ? "Important" : "Edit"}
              className={`EditIconBase 
                ${props.editIconClassname ? props.editIconClassname : ""} 
                ${modified || props.modifiedState ? "Modified" : ""} 
                ${props.isDisabled ? "Disabled" : "PointerCursor"}`}
            />
          )}
        </span>
      </div>

      {displayEditField && (
        <Stack
          horizontalAlign="stretch"
          tokens={{ childrenGap: 2, padding: 2 }}
          style={{ flexBasis: "auto", flexGrow: 1, flexShrink: 1 }}>
          <TextField componentRef={focusableTF} id={props.id} defaultValue={props.valueToEdit?.toString()} />
          {props.withCheckbox && (
            <Checkbox componentRef={myCheckbox} styles={checkboxStyle} label={props.checkboxLabel} />
          )}
        </Stack>
      )}
    </Stack>
  );
};
