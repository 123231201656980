import { IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { HeaderDay } from "../SharedComponents/HeaderDay";
import { dayViewSidebar, mediumGrey, pureWhite, strongBlue } from "../StylingUtils";
import { getWeekNumber } from "../Utils";

const myStyle: IStackStyles = {
  root: {
    displayName: "DayViewHeader",
    width: "100%",
    backgroundColor: strongBlue,
    color: pureWhite,
    borderTop: `1px solid ${mediumGrey}`,
  },
};

interface IDayViewHeader {
  currentDay: Date;
}

const DayViewHeader: React.FC<IDayViewHeader> = (props) => {
  return (
    <Stack horizontal horizontalAlign="stretch" styles={myStyle}>
      <Stack
        horizontal
        horizontalAlign="start"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
        styles={dayViewSidebar}>
        <span>{`W${getWeekNumber(props.currentDay)}`}</span>
      </Stack>
      <HeaderDay key={props.currentDay.valueOf()} date={props.currentDay} type={"day"} />
    </Stack>
  );
};

export default DayViewHeader;
