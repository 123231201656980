import {
  ContextualMenuItemType,
  IButtonStyles,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react";
import React from "react";
import { GuestCountType } from "../Models/IDayPlanData";

import {
  DynChangePortionSize,
  DynDeleteRecipe,
  DynEditDayPlanLine,
  DynLabel,
  DynModifyRecipe,
  DynOpenRecipeChangeLog,
  DynShowRecipeList,
  DynSwapRecipe,
  DynCreateCase,
  DynGetCaseList,
  DynGetDesigner,
} from "../Services/DynService";
import { darkestGrey } from "../StylingUtils";

const myStyle: IButtonStyles = {
  root: {
    height: "auto",
    padding: 0,
    margin: 1,
  },
  menuIcon: {
    fontSize: 18,
    margin: 0,
    color: darkestGrey,
  },
};

const getModifyRecipeItem = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "modifyRecipe",
    text: DynLabel("EditRecipeButton"),
    iconProps: { iconName: "PageHeaderEdit" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynModifyRecipe(recipeId);
    },
  };
};

const getSwapRecipeItem = (
  recipeId: number,
  periodMinDate: Date,
  periodMaxDate: Date,
  isDisabled: boolean,
  disablingCause?: string
): IContextualMenuItem => {
  return {
    key: "swapRecipe",
    text: DynLabel("SwapRecipeButton"),
    iconProps: { iconName: "Sync" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynSwapRecipe(recipeId, periodMinDate, periodMaxDate);
    },
  };
};
// Added code for craetion of Case SubMenu - PBI 52625
const getCreateCase = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "GetCreateCase",
    text: DynLabel("GetCreateCase"),
    iconProps: { iconName: "ProductVariant" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynCreateCase(recipeId);
    },
  };
};

const getCaseList = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "GetCaseList",
    text: DynLabel("GetCaseList"),
    iconProps: { iconName: "ProductList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynGetCaseList(recipeId);
    },
  };
};

const getCaseSubMenu = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "caseSubmenu",
    text: DynLabel("CaseLbl"),
    subMenuProps: {
      items: [
        getCreateCase(recipeId, isDisabled, disablingCause),
        //!isDayAllowed ||
        // isMenuConfirmed
        getCaseList(recipeId, isDisabled, disablingCause), //,!props.isDayAllowed ||
        //props.isMenuConfirmed),
      ],
    },
  };
};
// PBI 52625 changes end here

const getDeleteRecipeItem = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "deleteRecipe",
    text: DynLabel("DeleteRecipeButton"),
    iconProps: { iconName: "Delete" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynDeleteRecipe(recipeId);
    },
  };
};

const getEditDayPlanLineRecipeItem = (
  recipeId: number,
  isDisabled: boolean,
  disablingCause?: string
): IContextualMenuItem => {
  return {
    key: "editDayplanLine",
    text: DynLabel("EditDayplanLineButton"),
    iconProps: { iconName: "PageList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynEditDayPlanLine(recipeId);
    },
  };
};

const getShowRecipeList = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "showRecipeList",
    text: DynLabel("ShowRecipeList"),
    iconProps: { iconName: "Articles" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynShowRecipeList(recipeId);
    },
  };
};

const getOpenChangeLog = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "openChangeLog",
    text: DynLabel("ChangeLogButton"),
    iconProps: { iconName: "ReadingMode" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynOpenRecipeChangeLog(recipeId);
    },
  };
};


const getDesigner = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "Designer",
    text: "Designer",
    iconProps: { iconName: "PageList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynGetDesigner(recipeId);
      console.log("Recipe ID: " && recipeId);
    },
  };
};


const getChangePortionSize = (recipeId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "changePS",
    text: DynLabel("ChangePortionSizeButton"),
    iconProps: { iconName: "SliderHandleSize" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynChangePortionSize(recipeId);
    },
  };
};

const getDivider = (id: number): IContextualMenuItem => {
  return {
    key: `divider-${id}`,
    text: " - ",
    itemType: ContextualMenuItemType.Divider,
  };
};

const getRecipeActionsButtonProps = (
  recipeId: number,
  isMenuConfirmed: boolean,
  isDayAllowed: boolean,
  periodMinDate: Date,
  periodMaxDate: Date,
  dayPlanGuestCountType: GuestCountType,
  isRecipeProduced: boolean,
  servingTimePassed: boolean
) => {
  const menuItems = new Array<IContextualMenuItem>();
  let nbDivider = 0;

  // D365O-like:
  // if action can not be done due to actual state, disable
  // if action is not available due to user's rights, hide it

  menuItems.push(
    getShowRecipeList(recipeId, !isDayAllowed),
    getOpenChangeLog(recipeId, !isDayAllowed),
    getDesigner(recipeId, !isDayAllowed),
    getDivider(nbDivider++),
    getChangePortionSize(
      recipeId,
      !isDayAllowed || !isMenuConfirmed || !isRecipeProduced || dayPlanGuestCountType !== GuestCountType.ToProduce
    ),
    getSwapRecipeItem(recipeId, periodMinDate, periodMaxDate, !isDayAllowed || isMenuConfirmed || servingTimePassed),
    getDeleteRecipeItem(recipeId, isMenuConfirmed || servingTimePassed),
    getModifyRecipeItem(recipeId, !isDayAllowed || !isMenuConfirmed),
    getDivider(nbDivider++),
    getEditDayPlanLineRecipeItem(recipeId, false),
    // PBI 52625, push submenu items code
    getCaseSubMenu(recipeId, false)
    //getCreateCase(recipeId, false),
    //getCaseList(recipeId, false)
  );

  return {
    items: menuItems,
    directionalHintFixed: false,
  } as IContextualMenuProps;
};

interface IRecipeActionsButton {
  recipeId: number;
  isMenuConfirmed: boolean;
  isDayAllowed: boolean;
  periodMin: Date;
  periodMax: Date;
  styling?: React.CSSProperties;
  dayPlanGuestCountType: GuestCountType;
  isRecipeProduced: boolean;
  servingTimePassed: boolean;
  disabled?: boolean;
}

export const RecipeActionsButton: React.FC<IRecipeActionsButton> = (props) => {
  return (
    <IconButton
      styles={myStyle}
      style={props.styling}
      menuProps={getRecipeActionsButtonProps(
        props.recipeId,
        props.isMenuConfirmed,
        props.isDayAllowed,
        props.periodMin,
        props.periodMax,
        props.dayPlanGuestCountType,
        props.isRecipeProduced,
        props.servingTimePassed
      )}
      menuIconProps={{ iconName: "Waffle" }}
      title={DynLabel("RecipeActionsButton")}
      ariaLabel={DynLabel("RecipeActionsButton")}
      disabled={props.disabled}
    />
  );
};
