import { FontIcon, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

interface IMonthGuestCount {
  label: string;
  guestCount: number;
  styling?: IStackStyles;
  labelStyle?: React.CSSProperties;
}

export const MonthGuestCount: React.FC<IMonthGuestCount> = (props) => {
  return (
    <Stack horizontalAlign="start" verticalAlign="start" tokens={{ childrenGap: 1 }} styles={props.styling}>
      <span style={props.labelStyle}>{props.label}</span>
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <FontIcon iconName="EatDrink" />
        <span>{props.guestCount === 0 ? "--" : props.guestCount}</span>
      </Stack>
    </Stack>
  );
};
