import { ISelectionReducerAction } from '../Hooks/SelectionReducer';
import { IContextFromAX } from '../Models/IContextFromAx';
import { IDataFromAX } from '../Models/IDataFromAx';
import { IDayPlanData } from '../Models/IDayPlanData';
import { IDayPlanLineData } from '../Models/IDayPlanLineData';
import { IPlannerControl } from '../Models/IPlannerControl';

export type DynFunctionCallback = (result?: string) => void;

interface IDynUIServices {
  findControl: (controlName: string, rootTypeForm: string) => IPlannerControl;
  RootType: { form: string };
}

interface IDynShellServices {
  blockUserInteraction: (blockingDivDescription: string) => void;
  blockUserInteractionNoWait: (blockingDivDescription: string) => void;
  unBlockUserInteraction: () => void;
}

interface IDynServices {
  label: (key: string) => string;
  observe: (observable: any, observer: (value: any) => void | boolean) => void;
  error: (key: string, control: IPlannerControl) => number;
  callFunction: (func: Function, control: IPlannerControl, args: Object, callback: DynFunctionCallback) => void;
  ui: IDynUIServices;
  shell: IDynShellServices;
}

declare const $dyn: IDynServices;

let AXPlannerControl: IPlannerControl;

const dynCallbackUnblockInteraction: DynFunctionCallback = (_?: string) => {
  $dyn.shell.unBlockUserInteraction();
};

const invokeCallbacks = (callbacks: Array<DynFunctionCallback>): DynFunctionCallback => {
  return (result?: string) => {
    for (const callback of callbacks) {
      callback(result);
    }
  };
};

const dynCallbackTimer = (funcName: string, startTime: Date): DynFunctionCallback => {
  return (_?: string) => {
    const endTime = new Date();
    console.log(`[${funcName}]`, `Elapsed time: ${endTime.getTime() - startTime.getTime()}ms`);
  };
};

const dynLogResult = (funcName: string): DynFunctionCallback => {
  return (result?: string) => {
    console.log(`[${funcName}]`, `Result: ${result}`);
  };
};

// Use without the parameter to get the current instance (may be null)
// Use the parameter container to init the instance
export const DynControlInstance = (container?: HTMLElement) => {
  if (container) {
    const plannerControlName = container.parentElement?.getAttribute("data-dyn-controlname") ?? "";
    AXPlannerControl = $dyn.ui.findControl(plannerControlName, $dyn.ui.RootType.form);

    if (!AXPlannerControl) {
      throw new Error("Planner control " + plannerControlName + " not found!");
    }
  }

  return AXPlannerControl;
};

export const DynLabel = (key: string) => {
  return $dyn.label(`AVAMenuPlanner_${key}`);
};

export const DynError = (key: string) => {
  $dyn.error(`${key}`, AXPlannerControl);
};

export const DynObserve = (observable: IContextFromAX | IDataFromAX, observer: (value: any) => void | boolean) => {
  $dyn.observe(observable, observer);
};

export const DynCallFunction = (functionName: string, args: Object, callback: DynFunctionCallback) => {
  const func = (AXPlannerControl as any)[functionName];
  if (func) {
    $dyn.callFunction(func, AXPlannerControl, args, callback);
  } else {
    throw new Error(`Dynamics function ${functionName} does not exist in the planner control`);
  }
};

export const DynDefaultCallFunction = (functionName: string, args: Object, callback?: DynFunctionCallback) => {
  const callbacks = [
    dynCallbackTimer(functionName, new Date()),
    dynCallbackUnblockInteraction,
    callback || dynLogResult(functionName),
  ];

  $dyn.shell.blockUserInteractionNoWait(functionName);
  return DynCallFunction(functionName, args, invokeCallbacks(callbacks));
};

export const DynAddRecipe = (dayPlanRecId: number, submenu: string, serviceLocation?: string, serviceStation?: string, callback?: DynFunctionCallback) => {
  const args = {
    _dayPlanRecIdStr: `${dayPlanRecId}`,
    _subMenuName: submenu,
    _serviceLocationId: serviceLocation,
    _serviceStationId: serviceStation
  };
  return DynDefaultCallFunction("AddDayPlanLineMenuParts", args, callback);
};

export const DynAddMenu = (dayPlanDate: Date, menu: String, callback?: DynFunctionCallback) => {
  const args = {
    _dpDateStr: dayPlanDate.toUTCString(),
    _menuName: menu,
  };
  return DynDefaultCallFunction("AddDayPlan", args, callback);
};

export const DynUpdateDayPlan = (dayPlanData: IDayPlanData, callback?: DynFunctionCallback) => {
  const args = {
    _dpContractStr: JSON.stringify(dayPlanData),
  };
  return DynDefaultCallFunction("UpdateDayPlan", args, callback);
};

// NB: should always have a callback function
export const DynUpdateDayPlanLine = (dayPlanLineData: IDayPlanLineData, callback: DynFunctionCallback) => {
  const args = {
    _dplContractStr: JSON.stringify(dayPlanLineData),
  };
  return DynDefaultCallFunction("UpdateDayPlanLine", args, callback);
};

export const DynDeleteRecipe = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("DeleteDayPlanLine", args, callback);
};

export const DynShowDayPlanLineRecipe = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("ShowDayPlanLineRecipe", args, callback);
};

export const DynSwapRecipe = (
  dayPlanLineRecId: number,
  periodMinDate: Date,
  periodMaxDate: Date,
  callback?: DynFunctionCallback
) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
    _dateFromStr: periodMinDate.toUTCString(),
    _dateToStr: periodMaxDate.toUTCString(),
  };
  return DynDefaultCallFunction("SwapRecipe", args, callback);
};

export const DynModifyRecipe = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("EditRecipe", args, callback);
};

export const DynShowRecipeList = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("ShowRecipeList", args, callback);
};

export const DynOpenRecipeChangeLog = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("OpenRecipeChangeLog", args, callback);
};

// TODO: connect this service to Dynamics to open designer
export const DynGetDesigner = (recipeId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecId: recipeId.toString(),
  };
  return DynDefaultCallFunction("OpenBOMDesigner", args, callback);
};

export const DynEditDayPlanLine = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("EditDayPlanLine", args, callback);
};

export const DynChangePortionSize = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("ChangePortionSize", args, callback);
};

export const DynConfirmProduction = (
  dayPlanRecId: number,
  periodMinDate: Date,
  periodMaxDate: Date,
  callback?: DynFunctionCallback
) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
    _dateFromStr: periodMinDate.toUTCString(),
    _dateToStr: periodMaxDate.toUTCString(),
  };
  return DynDefaultCallFunction("ConfirmProduction", args, callback);
};

export const DynDeleteAllRecipes = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("DeleteAllRecipes", args, callback);
};

export const DynEditDayPlan = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("EditDayPlan", args, callback);
};

export const DynRecalcDayPlan = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("RecalcDayPlan", args, callback);
};

// Added for 69439 PBI
export const DynAveragePortionFactor = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("AveragePortionFactor", args, callback);
};
//

export const DynEditGuestCountRegister = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("EditGuestCountRegister", args, callback);
};

export const DynCopyMenu = (dayPlanRecId: number, copyFrom: Date, copyTo: Date, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
    _dateFromStr: copyFrom.toUTCString(),
    _dateToStr: copyTo.toUTCString(),
  };
  return DynDefaultCallFunction("CopyMenu", args, callback);
};

export const DynForecastSalesMix = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("ForecastSalesMix", args, callback);
};

export const DynConfirmSalesMix = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("ConfirmSalesMix", args, callback);
};

export const DynRetailIndicators = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("RetailIndicators", args, callback);
};

export const DynShowMenuRecipesList = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("ShowMenuRecipesList", args, callback);
};
// PBI 52625

//Day plan Line level

export const DynCreateCase = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("GetCreateCase", args, callback);
};

export const DynGetCaseList = (dayPlanLineRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dplRecIdStr: dayPlanLineRecId.toString(),
  };
  return DynDefaultCallFunction("GetCaseList", args, callback);
};

//Menu Level

export const DynMenuCreateCase = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("GetMenuCreateCase", args, callback);
};

export const DynMenuCaseList = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("GetMenuCaseList", args, callback);
};
// PBI 52625 chnages ends here
export const DynProductionList = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("ProductionList", args, callback);
};

export const DynUpdateActualProd = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("UpdateActualProd", args, callback);
};

export const DynCreateProdData = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("CreateProdData", args, callback);
};

export const DynGenConsumptionJournal = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("GenConsumptionJournal", args, callback);
};

export const DynGenPostProdWastage = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("GenPostProdWastage", args, callback);
};

export const DynReportAsFinishedDay = (dayPlanRecId: number, callback?: DynFunctionCallback) => {
  const args = {
    _dpRecIdStr: dayPlanRecId.toString(),
  };
  return DynDefaultCallFunction("ReportAsFinishedDay", args, callback);
};

export const DynUpdateRecipeSelection = (
  selectionContext: "day" | "week",
  selectionReducerAction: ISelectionReducerAction,
  callback?: DynFunctionCallback
) => {
  const args = {
    _action: selectionReducerAction.type,
    _selection: JSON.stringify(selectionReducerAction.ids),
  };

  const functionName = "UpdateRecipeSelection";
  const callbacks = [dynCallbackTimer(functionName, new Date()), callback || dynLogResult(functionName)];

  return DynCallFunction(functionName, args, invokeCallbacks(callbacks));
};
