export enum DifficultyEnum {
  None = 0,
  Easy = 1,
  Medium = 2,
  Hard = 3
}

export function getEnumFromDifficultyString(difficultyStr: string): DifficultyEnum {
  switch (difficultyStr) {
    case DifficultyEnum[1]:
      return DifficultyEnum.Easy;
    case DifficultyEnum[2]:
      return DifficultyEnum.Medium;
    case DifficultyEnum[3]:
      return DifficultyEnum.Hard;
    default:
      return DifficultyEnum.None;
  }
}
