import { mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { getDayName, getMonthName } from "../Services/GlobalizeService";
import { recipeGrey, strongBlue, weekViewColumn } from "../StylingUtils";

const myStyle = (props: IHeaderDay) => ({
  root: [
    {
      displayName: "HeaderDay",
      paddingRight: 10,
    },
    props.inPeriod !== undefined && props.inPeriod === false ? { backgroundColor: recipeGrey, color: strongBlue } : {},
  ],
});

interface IHeaderDay {
  date: Date;
  type: "day" | "week";
  inPeriod?: boolean;
  handleRedirectToDailyView?: (d: Date) => void;
}

export const HeaderDay: React.FC<IHeaderDay> = (props) => {
  const handleHeaderClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.type === "week" && props.inPeriod && props.handleRedirectToDailyView)
      props.handleRedirectToDailyView(props.date);
  };

  return (
    <Stack
      horizontal
      horizontalAlign="start"
      verticalAlign="center"
      styles={mergeStyleSets(weekViewColumn, myStyle(props))}
      // cursor should be here and not in myStyle to work in D365 integration
      style={props.type === "week" && props.inPeriod ? { cursor: "pointer" } : { cursor: "default" }}
      onClick={handleHeaderClick}>
      <div style={{ marginLeft: 5, flexGrow: 0, fontSize: 36, fontWeight: "bold" }}>{props.date.getUTCDate()}</div>
      <Stack verticalAlign="start" verticalFill style={{ flexGrow: 1, paddingTop: 10, paddingLeft: 5 }}>
        <div style={{ fontSize: 14, fontWeight: 300, lineHeight: 1.07 }}>{getDayName(props.date.getUTCDay())}</div>
        <div style={{ fontSize: 14, fontWeight: 600, lineHeight: 1.07 }}>{getMonthName(props.date.getUTCMonth())}</div>
      </Stack>
    </Stack>
  );
};
