import React, { useContext, useEffect } from "react";

import { useCollapseState } from "../Hooks/ICanCollapse";
import { IAXMenuStructureData } from "../Models/IDataFromAx";
import { DataContext } from "../Services/DataService";
import { DayMenuHeader } from "./DayMenuHeader";
import { DaySubMenu } from "./DaySubMenu";

interface IDayMenu {
  currentDay: Date;
  menuStructure: IAXMenuStructureData;
  displayTechnicalName: boolean;
  parentCollapsed: boolean;
  useServiceStation: boolean;
}

export const DayMenu: React.FC<IDayMenu> = (props) => {
  const dataContextValue = useContext(DataContext);

  const { isCollapsed, handleCollapse } = useCollapseState();
  const { isCollapsed: serviceStationCollapsed, handleCollapse: handleServiceStationCollapse } = useCollapseState();
  useEffect(() => {
    if (isCollapsed !== props.parentCollapsed) {
      handleCollapse();
    }
  }, [props.parentCollapsed]);

  // service station goes between daypart and submenu
  // var serviceStations = Object.groupBy(props.menuStructure.submenus, ({ serviceStation }) => serviceStation) || [];
  var dayPlanLineData = dataContextValue?.getDayPlanLineData(props.currentDay, props.menuStructure.menu_structure);
  var serviceStations;
  if (dayPlanLineData && dayPlanLineData.length > 0) {
    const serviceStationsMap = dayPlanLineData.map(({ submenu, ServiceStation, SubMenuSortOrder, ServiceStationSortOrder }) => ({ submenu, ServiceStation, SubMenuSortOrder, ServiceStationSortOrder }))
    .sort((a, b) => a.ServiceStationSortOrder - b.ServiceStationSortOrder);
    serviceStations = Object.groupBy(serviceStationsMap, ({ ServiceStation }) => ServiceStation) || [];
  }

  const dayPlanData = dataContextValue?.getMenuStructureData(props.currentDay, props.menuStructure.menu_structure)
  return (
    dataContextValue && (

      <div>
        <DayMenuHeader
          key={props.currentDay.valueOf()}
          currentDay={props.currentDay}
          isCollapsed={isCollapsed}
          menuStructureName={props.menuStructure.menu_structure}
          isStructRetail={Boolean(props.menuStructure.is_retail)}
          headerData={dayPlanData}
          isDayAllowed={Boolean(props.menuStructure.setupDays[props.currentDay.getUTCDay()])}
          onCollaspeClick={handleCollapse}
        />
        <div hidden={isCollapsed}>

          {serviceStations && Object.entries(serviceStations).map(ss => {
            let header = null;
            if (props.useServiceStation && (ss[0] !== "undefined" && ss[0] !== undefined)) {
              header = <DayMenuHeader
                menuStructureName={props.menuStructure.menu_structure}
                menuNameDisplayOverride={ss[0]}
                isDayAllowed={true}
                isStructRetail={Boolean(props.menuStructure.is_retail)}
                currentDay={props.currentDay}
                isCollapsed={serviceStationCollapsed}
                onCollaspeClick={handleServiceStationCollapse}
                headerData={dayPlanData}
                plannedCostOverride={-1}
                // Get the submenus for the service station
                budgetOverride={dayPlanLineData?.filter(d => d.ServiceStation === ss[0]).reduce((p, c) => p + c.planned_cost, 0)}
                servingTimePassed={new Date() > new Date(props.currentDay.getTime() + (dayPlanData?.ServingTimeForm ?? 0) )}
                isServiceStation={true}
              />;
            }
            return <div key={ss[0]}>
              {header}
              <div hidden={serviceStationCollapsed}>
                {props.menuStructure.submenus
                .filter(sm => ss[1] && ss[1].map(s => s.submenu).includes(sm.submenu))
                .map((value, index) => {
                  return (
                    <DaySubMenu
                      key={`${props.menuStructure.menu_structure}-${value.submenu
                        .replace(" ", "")
                        .trim()}-${props.currentDay.valueOf()}`}
                      subMenu={value.submenu}
                      parentStructureName={props.menuStructure.menu_structure}
                      isParentRetail={Boolean(props.menuStructure.is_retail)}
                      parentStructureData={dataContextValue.getMenuStructureData(
                        props.currentDay,
                        props.menuStructure.menu_structure
                      )}
                      currentDay={props.currentDay}
                      displayTechnicalName={props.displayTechnicalName}
                      isDayAllowed={Boolean(props.menuStructure.setupDays[props.currentDay.getUTCDay()])}
                    />
                  );
                })}
              </div>
            </div>;
          })}
        </div>
      </div>
    )
  );
};
