import { mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

import { ISelectionTotalsData } from "../Models/ISelectionTotalsData";
import { SelectionTotalsPill } from "../SharedComponents/SelectionTotalsPill";
import { footerDayStyle, weekViewColumn } from "../StylingUtils";

interface IWeekMenuStructureFooterDay {
  totals: ISelectionTotalsData;
}

export const WeekMenuStructureFooterDay: React.FC<IWeekMenuStructureFooterDay> = (props) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      styles={mergeStyleSets(weekViewColumn, footerDayStyle)}>
      <SelectionTotalsPill totals={props.totals} />
    </Stack>
  );
};
