import React from 'react';
import ReactDOM from 'react-dom';

import App from '../App';
import { DynControlInstance } from './DynService';

export const renderApp = (containerName: string) => {
  const container = document.getElementById(containerName);
  if (container) {
    const myControl = DynControlInstance(container);
    ReactDOM.render(<App {...myControl.config} />, container);
  } else {
    throw new Error("Container element not found!");
  }
};
