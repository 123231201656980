import { getNextGuestCountType, GuestCountType } from '../Models/IDayPlanData';
import { IDayPlanLineData } from '../Models/IDayPlanLineData';
import { IDataContext } from '../Services/DataService';

export const updateGuestCount = (
  dataContext: IDataContext,
  dayPlanRecid: number,
  newGuestCountValue: number,
  actualGuestCountType: GuestCountType,
  changeType?: boolean,
  onUserPrompted?: (result: string) => void,
  onError?: (result: string) => void
) => {
  const newGcType = changeType ? getNextGuestCountType(actualGuestCountType) : actualGuestCountType;

  const updatePromise = dataContext.updateGuestCount(dayPlanRecid, newGuestCountValue, newGcType);
  updatePromise.then(
    (result) => {
      // success here only indicates the user was prompted, they might cancel the update
      if (onUserPrompted) onUserPrompted(result);
    },
    (result) => {
      if (onError) onError(result);
    }
  );
};

export function updatePortionFactor(
  dataContext: IDataContext,
  recipeId: number,
  newPortionFactor: number,
  onSuccess?: (result: IDayPlanLineData) => void,
  onReject?: (result: IDayPlanLineData) => void
) {
  const updatePromise = dataContext.updatePortionFactor(recipeId, newPortionFactor);
  updatePromise.then(
    (result) => {
      if (onSuccess) onSuccess(result);
    },
    (result) => {
      if (onReject) onReject(result);
    }
  );
}

export function updateSalesMix(
  dataContext: IDataContext,
  recipeId: number,
  newSalesMix: number,
  onSuccess?: (result: IDayPlanLineData) => void,
  onReject?: (result: IDayPlanLineData) => void
) {
  const updatePromise = dataContext.updateSalesMix(recipeId, newSalesMix);
  updatePromise.then(
    (result) => {
      if (onSuccess) onSuccess(result);
    },
    (result) => {
      if (onReject) onReject(result);
    }
  );
}

export function updatePlannedQuantity(
  dataContext: IDataContext,
  recipeId: number,
  newPlannedQuantity: number,
  onSuccess?: (result: IDayPlanLineData) => void,
  onReject?: (result: IDayPlanLineData) => void
) {
  const updatePromise = dataContext.updatePlannedQuantity(recipeId, newPlannedQuantity);
  updatePromise.then(
    (result) => {
      if (onSuccess) onSuccess(result);
    },
    (result) => {
      if (onReject) onReject(result);
    }
  );
}
