import { Dropdown, FontWeights, IDropdownOption, IStackStyles, Stack } from "@fluentui/react";
import React from "react";

import { getWeekDaysNames } from "../Services/GlobalizeService";
import { mediumGrey, monthViewSidebar, pureWhite, strongBlue } from "../StylingUtils";
import { MonthViewHeaderDay } from "./MonthViewHeaderDay";

const myStyle: IStackStyles = {
  root: {
    displayName: "MonthViewHeader",
    width: "100%",
    backgroundColor: strongBlue,
    color: pureWhite,
    borderTop: `1px solid ${mediumGrey}`,
    fontSize: 14,
    fontWeight: FontWeights.regular,
  },
};

interface IMonthViewHeader {
  currentMonth: Date;
  weekStartsOn: number;
  menuStructuresNames: Array<string>;
  selected: string | number | undefined;
  setSelected: (selection: string | number | undefined) => void;
}

export const MonthViewHeader: React.FC<IMonthViewHeader> = (props) => {
  const menuStructuresOptions: Array<IDropdownOption> = props.menuStructuresNames.map((menuName) => {
    return { key: menuName, text: menuName };
  });

  return (
    <Stack horizontal horizontalAlign="stretch" styles={myStyle}>
      <Stack
        horizontalAlign="stretch"
        tokens={{ childrenGap: 3, padding: 5 }}
        styles={monthViewSidebar}
        style={{ borderBottom: "none" }}>
        <span style={{ fontSize: 14, fontWeight: "normal" }}>Menu</span>
        <Dropdown
          options={menuStructuresOptions}
          selectedKey={props.selected}
          onChange={(_, item) => {
            if (item) props.setSelected(item.key);
          }}
        />
      </Stack>
      {getWeekDaysNames(props.weekStartsOn).map((day, i) => (
        <MonthViewHeaderDay key={`${day}-${i}`} day={day} />
      ))}
    </Stack>
  );
};
