import {
  ContextualMenuItemType,
  IButtonStyles,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react";
import React from "react";

import {
  DynConfirmProduction,
  DynConfirmSalesMix,
  DynCopyMenu,
  DynCreateProdData,
  DynDeleteAllRecipes,
  DynEditDayPlan,
  DynEditGuestCountRegister,
  DynForecastSalesMix,
  DynGenConsumptionJournal,
  DynGenPostProdWastage,
  DynLabel,
  DynProductionList,
  DynRecalcDayPlan,
  DynReportAsFinishedDay,
  DynRetailIndicators,
  DynShowMenuRecipesList,
  DynUpdateActualProd,
  DynAveragePortionFactor,
  DynMenuCreateCase,
  DynMenuCaseList,
} from "../Services/DynService";
import { pureWhite } from "../StylingUtils";

const myStyle: IButtonStyles = {
  root: {
    height: "auto",
    padding: 0,
    margin: 1,
    backgroundColor: "transparent",
    color: pureWhite,
  },
  rootHovered: {
    backgroundColor: "transparent",
    color: pureWhite,
  },
  rootExpanded: {
    backgroundColor: "transparent",
    color: pureWhite,
  },
  rootFocused: {
    backgroundColor: "transparent",
    color: pureWhite,
  },
  rootPressed: {
    backgroundColor: "transparent",
    color: pureWhite,
  },
  menuIcon: {
    fontSize: 18,
    margin: 0,
    color: pureWhite,
  },
};

const getConfirmProduction = (
  menuId: number,
  periodMinDate: Date,
  periodMaxDate: Date,
  isDisabled: boolean,
  disablingCause?: string
): IContextualMenuItem => {
  return {
    key: "confirmProd",
    text: DynLabel("ConfirmProdButton"),
    iconProps: { iconName: "Product" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynConfirmProduction(menuId, periodMinDate, periodMaxDate);
    },
  };
};

const getRemoveAllRecipes = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "deleteRecipes",
    text: DynLabel("DeleteRecipesButton"),
    iconProps: { iconName: "Delete" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynDeleteAllRecipes(menuId);
    },
  };
};

const getEditDayPlan = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "editDayPlan",
    text: DynLabel("EditDayPlanButton"),
    iconProps: { iconName: "PageList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynEditDayPlan(menuId);
    },
  };
};

const getRecalcDayPlan = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "recalcDayPlan",
    text: DynLabel("RecalcDayPlanButton"),
    iconProps: { iconName: "Calculator" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynRecalcDayPlan(menuId);
    },
  };
};

// Added for 69439 PBI
const getAveragePortionFactor = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "recalcDayPlan",
    text: DynLabel("AveragePortionFactor"),
    iconProps: { iconName: "Calculator" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynAveragePortionFactor(menuId);
    },
  };
};
//

const getCreateCase = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "GetMenuCreateCase",
    text: DynLabel("GetMenuCreateCase"),
    iconProps: { iconName: "ProductVariant" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynMenuCreateCase(menuId);
    },
  };
};

const getCaseList = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "GetMenuCaseList",
    text: DynLabel("GetMenuCaseList"),
    iconProps: { iconName: "ProductList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynMenuCaseList(menuId);
    },
  };
};

const getCaseSubMenu = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "caseSubmenu",
    text: DynLabel("MenuCaseLbl"),
    subMenuProps: {
      items: [
        getCreateCase(menuId, isDisabled, disablingCause),
        //!isDayAllowed ||
        // isMenuConfirmed
        getCaseList(menuId, isDisabled, disablingCause), //,!props.isDayAllowed ||
        //props.isMenuConfirmed),
      ],
    },
  };
};
const getEditGuestCountRegister = (
  menuId: number,
  isDisabled: boolean,
  disablingCause?: string
): IContextualMenuItem => {
  return {
    key: "editGuestCountRegister",
    text: DynLabel("EditGuestCountRegister"),
    iconProps: { iconName: "Trackers" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynEditGuestCountRegister(menuId);
    },
  };
};

const getForecastSalesMix = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "forecastSalesMix",
    text: DynLabel("ForecastSalesMixButton"),
    iconProps: { iconName: "PieSingleSolid" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynForecastSalesMix(menuId);
    },
  };
};

const getConfirmSalesMix = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "confirmSalesMix",
    text: DynLabel("ConfirmSalesMixButton"),
    iconProps: { iconName: "LockSolid" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynConfirmSalesMix(menuId);
    },
  };
};

const getRetailIndicators = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "retailIndicators",
    text: DynLabel("RetailIndicatorsButton"),
    iconProps: { iconName: "Coupon" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynRetailIndicators(menuId);
    },
  };
};

const getCopyMenu = (
  menuId: number,
  dateFrom: Date,
  dateTo: Date,
  isDisabled: boolean,
  disablingCause?: string
): IContextualMenuItem => {
  return {
    key: "copyMenu",
    text: DynLabel("CopyMenuButton"),
    iconProps: { iconName: "Copy" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynCopyMenu(menuId, dateFrom, dateTo);
    },
  };
};

const getShowMenuRecipesList = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "showMenuRecipesList",
    text: DynLabel("ShowMenuRecipesList"),
    iconProps: { iconName: "Articles" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynShowMenuRecipesList(menuId);
    },
  };
};

const getProductionList = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "prodList",
    text: DynLabel("ProductionListButton"),
    iconProps: { iconName: "ProductList" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynProductionList(menuId);
    },
  };
};

const getUpdateActualProd = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "updateActualProd",
    text: DynLabel("UpdateActualProdButton"),
    iconProps: { iconName: "ProductRelease" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynUpdateActualProd(menuId);
    },
  };
};

const getCreateProdData = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "createProdData",
    text: DynLabel("CreateProdDataButton"),
    iconProps: { iconName: "ProductVariant" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynCreateProdData(menuId);
    },
  };
};

const getConsumptionJournal = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "getConsumptionJournal",
    text: DynLabel("GenConsumptionJournalButton"),
    iconProps: { iconName: "ProductCatalog" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynGenConsumptionJournal(menuId);
    },
  };
};

const getPostProdWastage = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "genPostProdWastage",
    text: DynLabel("GenPostProdWastageButton"),
    iconProps: { iconName: "RecycleBin" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynGenPostProdWastage(menuId);
    },
  };
};

const getReportFinishedDay = (menuId: number, isDisabled: boolean, disablingCause?: string): IContextualMenuItem => {
  return {
    key: "reportFinishDay",
    text: DynLabel("ReportFinishedDayButton"),
    iconProps: { iconName: "PrimaryCalendar" },
    disabled: isDisabled,
    title: isDisabled && disablingCause ? disablingCause : "",
    onClick: () => {
      DynReportAsFinishedDay(menuId);
    },
  };
};

const getRetailSubmenu = (props: IMenuStructureActionsButton) => {
  return {
    key: "retailSubmenu",
    text: DynLabel("RetailLbl"),
    subMenuProps: {
      items: [
        getRetailIndicators(props.menuId, !props.isDayAllowed),
        // planner managers are authorized to edit salesMix, even after confirmation, so they can forecast
        getForecastSalesMix(
          props.menuId,
          !props.isDayAllowed || ((props.isSalesMixConfirmed || props.isMenuConfirmed) && !props.userManager)
        ),
        getConfirmSalesMix(props.menuId, !props.isDayAllowed || props.isSalesMixConfirmed || props.isMenuConfirmed),
      ],
    },
  };
};

const getProdSubmenu = (props: IMenuStructureActionsButton) => {
  return {
    key: "prodSubmenu",
    text: DynLabel("ProdLbl"),
    subMenuProps: {
      items: [
        getConfirmProduction(
          props.menuId,
          props.periodMin,
          props.periodMax,
          !props.isDayAllowed || props.isMenuConfirmed
        ),
        getProductionList(props.menuId, !props.isDayAllowed),
      ],
    },
  };
};

const getPostProdSubmenu = (props: IMenuStructureActionsButton) => {
  return {
    key: "postProdSubmenu",
    text: DynLabel("PostProdLbl"),
    subMenuProps: {
      items: [
        getUpdateActualProd(props.menuId, !props.isDayAllowed),
        getPostProdWastage(props.menuId, !props.isDayAllowed || !props.isMenuConfirmed),
        getCreateProdData(props.menuId, !props.isDayAllowed),
        getConsumptionJournal(props.menuId, !props.isDayAllowed),
        getReportFinishedDay(props.menuId, !props.isDayAllowed),
      ],
    },
  };
};

const getEditSubmenu = (props: IMenuStructureActionsButton) => {
  return {
    key: "editSubmenu",
    text: DynLabel("EditLbl"),
    subMenuProps: {
      items: [getEditGuestCountRegister(props.menuId, false), getEditDayPlan(props.menuId, false)],
    },
  };
};

const getDivider = (id: number): IContextualMenuItem => {
  return {
    key: `divider-${id}`,
    text: " - ",
    itemType: ContextualMenuItemType.Divider,
  };
};

const getMenuStructureActionsButtonProps = (props: IMenuStructureActionsButton) => {
  const menuItems = new Array<IContextualMenuItem>();
  let nbDivider = 0;

  // D365O-like:
  // if action can not be done due to actual state, disable
  // if action is not available due to user's rights, hide it

  menuItems.push(
    getCopyMenu(props.menuId, props.periodMin, props.periodMax, !props.isDayAllowed),
    getShowMenuRecipesList(props.menuId, !props.isDayAllowed),
    getDivider(nbDivider++),
    getRecalcDayPlan(props.menuId, !props.isDayAllowed),
    //Added for 69439 PBI
    getAveragePortionFactor(props.menuId, props.isMenuConfirmed || props.isMenuRetail),
    //
    getRemoveAllRecipes(props.menuId, props.isMenuConfirmed)
  );

  // If menu is not retail, these actions make no sense, don't show them
  if (props.isMenuRetail) {
    menuItems.push(getRetailSubmenu(props));
  }

  menuItems.push(getDivider(nbDivider++));

  menuItems.push(
    getProdSubmenu(props),
    getDivider(nbDivider++),
    getPostProdSubmenu(props),
    getDivider(nbDivider++),
    getEditSubmenu(props),
    getDivider(nbDivider++),
    getCaseSubMenu(props.menuId, false)
  );

  return {
    items: menuItems,
    directionalHintFixed: false,
  } as IContextualMenuProps;
};

interface IMenuStructureActionsButton {
  menuId: number;
  isMenuConfirmed: boolean;
  isMenuRetail: boolean;
  isSalesMixConfirmed: boolean;
  isDayAllowed: boolean;
  periodMin: Date;
  periodMax: Date;
  userManager: boolean;
  disabled?: boolean;
}

export const MenuStructureActionsButton: React.FC<IMenuStructureActionsButton> = (props) => {
  return (
    <IconButton
      styles={myStyle}
      menuProps={getMenuStructureActionsButtonProps(props)}
      menuIconProps={props.isMenuConfirmed ? { iconName: "Product" } : { iconName: "Waffle" }}
      title={DynLabel("MenuStructureActionsButton")}
      ariaLabel={DynLabel("MenuStructureActionsButton")}
      disabled={props.disabled}
    />
  );
};
